import { filter } from "lodash";

// ----------------------------------------------------------------------
export const APPROVED_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  // { id: 'aveRating', label: 'Rating', alignRight: false },
  // { id: 'reviews', label: 'Reviews', alignRight: false },
  {
    id: "yearsOfMedicalExperience",
    label: "Experience (yrs)",
    alignRight: false,
  },
  { id: "areaOfSpecialization", label: "Specialization", alignRight: false },
  { id: "ccrep", label: "ccrep", alignRight: false },
  { id: "" },
];

export const REJECTED_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "mdcnFolioNo", label: "Mdcn Folio-No", alignRight: false },
  { id: "consultationPrice", label: "Consultation fee", alignRight: false },
  {
    id: "yearsOfMedicalExperience",
    label: "Experience (yrs)",
    alignRight: false,
  },
  { id: "areaOfSpecialization", label: "Specialization", alignRight: false },
  // { id: 'gender', label: 'Gender', alignRight: false },
  // { id: 'state', label: 'State', alignRight: false },
  // { id: 'status', label: 'Marital status', alignRight: false },
  { id: "" },
];

export const UNAPPROVED_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "mdcnFolioNo", label: "Mdcn Folio-No", alignRight: false },
  { id: "consultationPrice", label: "Consultation fee", alignRight: false },
  {
    id: "yearsOfMedicalExperience",
    label: "Experience (yrs)",
    alignRight: false,
  },
  { id: "areaOfSpecialization", label: "Specialization", alignRight: false },
  // { id: 'gender', label: 'Gender', alignRight: false },
  // { id: 'state', label: 'State', alignRight: false },
  // { id: 'status', label: 'Marital status', alignRight: false },
  { id: "" },
];

export const ACTIVE_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phoneNumber", label: "Phone Number", alignRight: false },
  { id: "" },
];

export const DEACTIVE_TABLE_HEAD = [
  { id: "userName", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phoneNumber", label: "Phone Number", alignRight: false },
  { id: "" },
];

export const SCORE_TABLE_HEAD = [
  { id: "userName", label: "Name", alignRight: false },
  // { id: 'aveRating', label: 'Rating', alignRight: false },
  // { id: 'reviews', label: 'Reviews', alignRight: false },
  {
    id: "yearsOfMedicalExperience",
    label: "Experience (yrs)",
    alignRight: false,
  },
  { id: "areaOfSpecialization", label: "Specialization", alignRight: false },
  { id: "id", label: "CCL ID", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export const normalisedArrayFunction = (array) => {
  const normalisedArray = array.map((el, index) => {
    let newObj = {};
    for (const [key, value] of Object.entries(el)) {
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          newObj[key] = value;
        } else {
          if (!value) {
            continue;
          } else {
            for (const innerKey of Object.keys(value)) {
              if (innerKey == "state") {
                newObj[innerKey + key] = value[innerKey];
              } else newObj[innerKey] = value[innerKey];
            }
          }
        }
      } else {
        newObj[key] = value;
      }
    }
    newObj.id = el?.id;
    // newObj.name =`${newObj?.firstName} ${newObj?.lastName}` || `${newObj?.userName}`;
    newObj.name =
      `${newObj?.firstName || ""} ${newObj?.lastName || ""}`.trim() ||
      newObj?.userName ||
      "";

    newObj.state =
      newObj?.statecontactInformation || newObj?.stateuserRegistration || "";
    return newObj;
  });
  return normalisedArray;
};

export function applySortFilter(normalisedArray, comparator, query, category) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  // if (query) {
  //   return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  // }
  // if (category) {
  //   if (category === 'All') return array
  //   return filter(array, (_user) => _user.areaOfSpecialization.toLowerCase().indexOf(category.toLowerCase()) !== -1);
  // }
  // if (query && category !== 'All'){
  //   // if (category === 'All') return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  //   return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 && _user.areaOfSpecialization.toLowerCase().indexOf(category.toLowerCase()) !== -1);
  // }
  if ((category === "All" || !category) && query) {
    return filter(result, (_user) => {
      return _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        // ||
        // _user?.userName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      
      
    });
  }
  if (category && !query) {
    if (category === "All") return result;
    return filter(
      result,
      (_user) =>
        _user?.areaOfSpecialization
          ?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
    );
  }

  // if (category === "All")
  //   return filter(
  //     result,
  //     (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  //   );
  if (category !== "All" && query) {
    return filter(result, (_user) => {
      return (
        _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
        _user?.areaOfSpecialization
          ?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
      );
    });
  }
  // console.log('3', stabilizedThis.map((el) => el[0]))
  // return stabilizedThis.map((el) => el[0]);
  return result;
}

// export function applySortFilter(normalisedArray, comparator, query, category) {
//   const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
//   const result = stabilizedThis.map((el) => el[0]);

//   if ((category === "All" || !category) && query) {
//     return filter(result, (_user) => {
//       return (
//         _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1 ||
//         _user?.userName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
//       );
//     });
//   }

//   if (category && !query) {
//     if (category === "All") return result;
//     return filter(result, (_user) => {
//       return (
//         _user?.areaOfSpecialization
//           ?.toLowerCase()
//           .indexOf(category.toLowerCase()) !== -1
//       );
//     });
//   }

//   if (category !== "All" && query) {
//     return filter(result, (_user) => {
//       return (
//         _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
//         _user?.areaOfSpecialization
//           ?.toLowerCase()
//           .indexOf(category.toLowerCase()) !== -1

//       );
//     });
//   }

//   return result;
// }
