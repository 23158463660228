import React, { useState } from "react";

import CustomisableModal from "./customisableModal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import Iconify from "../iconify";

// import './ErrorModal.css'

const DeactivateModal = ({
  onClose,
  open,
  reason,
  setReason,
  onDeactivateClick,
  deleteIcon,
  deactivateLmdMutationError,
  deactivateLmdLoading,
  text,

  onReactivateClick,
}) => {
  return (
    <CustomisableModal handleClose={onClose} open={!!open}>
      <Box
        sx={{
          display: "flex",
          justifyContent: deleteIcon ? "space-between" : "flex-start",
        }}
      >
        {deleteIcon && (
          <Iconify
            icon="jam:triangle-danger-f"
            sx={{
              color: "#D3617A",
              width: "4.2rem",
              height: "4.2rem",
            }}
          />
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: "700",
            }}
          >
            {text ? "REACTIVATE DOCTOR" : "DEACTIVATE DOCTOR"}
          </Typography>
          {deleteIcon && (
            <Typography id="modal-modal-description" sx={{ mt: "0.3rem" }}>
              This action cannot be undone
            </Typography>
          )}
          <TextField
            id="outlined-controlled"
            label="Reason"
            value={reason}
            onChange={(event) => {
              setReason(event.target.value);
            }}
            fullWidth
            sx={{
              my: "1rem",
              width: "100%",
            }}
            multiline
            maxRows={3}
          />
        </Box>
      </Box>
      {deleteIcon && <Divider sx={{ my: "1rem" }} />}
      {deactivateLmdMutationError && (
        <Typography
          id="modal-modal-description"
          sx={{ mt: "0.3rem" }}
          color="error"
        >
          {deactivateLmdMutationError?.response?.data?.message ||
            deactivateLmdMutationError?.message}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={onClose}
          sx={{
            mt: "0.65rem",
            backgroundColor: "#D9D9D9 !important",
            px: { xs: "2.0rem", sm: "2.65rem" },
            fontWeight: "700",
            fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.1rem" },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          // onClick={onClose}
          onClick={text ? onReactivateClick : onDeactivateClick}
          // bgColor='error'
          sx={{
            mt: "0.65rem",
            ml: "0.7rem",
            backgroundColor: "#D3617A !important",
            px: { xs: "2.0rem", sm: "2.65rem" },
            fontWeight: "700",
            fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1.1rem" },
            color: "#ffffff",
          }}
          disabled={deactivateLmdLoading}
        >
          {deactivateLmdLoading && (
            <Iconify
              icon={"line-md:loading-twotone-loop"}
              sx={{
                mr: 2,
                color: "primary.main",
              }}
            />
          )}

          {text ? "REACTIVATE" : "DEACTIVATE"}
        </Button>
      </Box>
    </CustomisableModal>
  );
};

export default DeactivateModal;
