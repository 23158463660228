import React, { useReducer, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { useField, useFormikContext } from 'formik';
import './MySelect.css';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MySelect = ({
  name,
  label,
  options,
  ...otherProps
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = evt => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: "true",
    // multiple: true,
    // defaultValue: "",
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    // MenuProps: MenuProps,
    sx: {
      // width: { xs: '100%', sm: '15rem' },
      width: '100%',
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `#dcdcdc !important`,
      },
    }
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    // configSelect.helperText = meta.error;
    configSelect.helpertext = meta.error;
  }

  return (
    <>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        {/* <Select
          select="true"
          multiple={true}
          defaultValue=""
          // helperText="Please select your currency"
          sx={{
            // width: { xs: '100%', sm: '15rem' },
            width: '100%',
            '& fieldset': {
              borderWidth: `1px !important`,
              borderColor: `#dcdcdc !important`,
            },
          }}
          error={props.error}
          id={props.id}
          name={props.name}
          type={props.type}
          label={props.label}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          // helperText={props.helperText}
          {...props}
        >
          {
            !!data && data.map((option, index) => (
              <MenuItem
                key={option.value + index + option.label}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))
          }
        </Select> */}
        <Select {...configSelect}>
          {!!options && options?.map((option, index) => {
            return (
              <MenuItem
                key={option.value + index + option.label}
                value={option.value}
              >
                {
                  otherProps.multiple && <Checkbox
                    checked={values[name].indexOf(option.value) > -1}
                  />
                }
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default MySelect;
