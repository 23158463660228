import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useHttpClient } from "../../../hooks/http-hook";
import {
  applySortFilter,
  normalisedArrayFunction,
} from "./DoctorHelperFunctions";
import { axiosClient, baseUrl, getComparator } from "../../../utils/helper";
import {
  fetchApprovedDoctors,
  fetchUnApprovedDoctors,
  fetchRejectedDoctors,
  fetchDoctorSpecializations,
  updateApprovalRequest,
  updateRejectRequest,
  fetchActiveDoctors,
  deactivateLmdRequest,
  deactivateLmd,
  fetchDeactivedDoctors,
  fetchScoreAppDoctors,
  addScoreAppDoctor,
  removeScoreAppDoctor,
} from "../../../fetch/doctor";

function useDoctor(token) {
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState({});
  // const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoading, sendRequest } = useHttpClient();
  const [error, setError] = useState();
  const [text, setText] = useState(false);
  // console.log('baseUrl', baseUrl)

  const clearError = () => {
    setError(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  // useEffect(() => {
  //   const fetchUnApprovedDoctors = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${baseUrl}/Doctor/OnBoarding`,
  //         undefined,
  //         undefined,
  //         {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + token,
  //           'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
  //         }
  //       );
  //       console.log('UnApprovedDoctors', responseData.data.data)
  //       const docs = normalisedArrayFunction(responseData?.data.data)
  //       setLoadedUnApprovedDoctors(docs);
  //     } catch (err) { }
  //   };
  //   fetchUnApprovedDoctors();
  // }, [sendRequest, token]);

  const {
    data: loadedUnApprovedDoctors,
    isLoading: unApprovedLoading,
    error: unApprovedError,
    isError: unApprovedIsError,
  } = useQuery({
    queryKey: ["unApprovedDoctors"],
    queryFn: ({ signal }) => fetchUnApprovedDoctors(token, signal),
    select: normalisedArrayFunction,
  });

  const {
    data: loadedRejectedDoctors,
    isLoading: rejectedLoading,
    error: rejectedError,
    isError: rejectedIsError,
    refetch: refetchLoadedRejectedDoctors,
  } = useQuery({
    queryKey: ["rejectedDoctors"],
    queryFn: ({ signal }) => fetchRejectedDoctors(token, signal),
    select: normalisedArrayFunction,
  });

  const {
    data: scoreAppDoctors,
    isLoading: scoreAppLoading,
    error: scoreAppError,
    isError: scoreAppIsError,
    refetch: refetchScoreAppDoctors,
  } = useQuery({
    queryKey: ["scoreApp"],
    queryFn: ({ signal }) => fetchScoreAppDoctors(token, signal),
    select: normalisedArrayFunction,
    // select: useCallback((data) => {
    //   const docs = data.map((doc) => ({
    //     ...doc,
    //     name: doc.doctorName,
    //     id: doc.doctorId,
    //   }));
    //   return docs;
    // }, []),
  });

  // useEffect(() => {
  //   const fetchApprovedDoctors = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${baseUrl}/report/api/Doctor/Doctors?pageNumber=1&pageSize=50`,
  //         undefined,
  //         undefined,
  //         {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + token,
  //           'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
  //         }
  //       );
  //       const docs = normalisedArrayFunction(responseData?.data.data)
  //       const docsWithNames = docs.map((doc) => ({
  //         ...doc,
  //         name: doc.userName
  //       }))
  //       setApprovedDoctors(docsWithNames);
  //     } catch (err) { }
  //   };
  //   fetchApprovedDoctors();
  // }, [sendRequest, token]);

  const {
    data: approvedDoctors,
    isLoading: approvedLoading,
    error: approvedError,
    isError: approvedIsError,
  } = useQuery({
    queryKey: ["approvedDoctors"],
    queryFn: ({ signal }) => fetchApprovedDoctors(token, signal),
    select: useCallback((data) => {
      const docs = normalisedArrayFunction(data);
      const docsWithNames = docs.map((doc) => ({
        ...doc,
        name: doc.userName,
        ccrep: doc.ccrep.toString(),
      }));
      return docsWithNames;
    }, []),
  });
  // console.log('first approvedError', approvedError?.message)

  // useEffect(() => {
  //   const fetchDoctorSpecializationxs = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${baseUrl}/Doctor/api/Doctors/specs`,
  //         undefined,
  //         undefined,
  //         {
  //           // 'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + token,
  //           'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
  //         }
  //       );
  //       // console.log('responseData 2', responseData.data)
  //       setSpecializations(responseData.data);
  //     } catch (err) { }
  //   };
  //   fetchDoctorSpecializationxs();
  // }, [sendRequest]);

  const {
    data: activeDoctors,
    isLoading: activeLoading,
    error: activeError,
    isError: activeIsError,
  } = useQuery({
    queryKey: ["activeDoctors"],
    queryFn: ({ signal }) => fetchActiveDoctors(token, signal),
    select: useCallback((data) => {
      const docs = data.map((doc) => ({
        ...doc,
        name: doc.doctorName,
        id: doc.doctorId,
      }));
      return docs;
    }, []),
  });

  const {
    data: deactivatedDoctors,
    isLoading: deactiveLoading,
    error: deactiveError,
    isError: deactiveIsError,
  } = useQuery({
    queryKey: ["deactiveDoctors"],
    queryFn: ({ signal }) => fetchDeactivedDoctors(token, signal),
    select: normalisedArrayFunction
    //   useCallback((data) => {
    //   const docs = data.map((doc) => ({
    //     ...doc,
    //     name: doc.doctorName,
    //     id: doc.doctorId,
    //   }));
    //   return docs;
    // }, []),
  });

  const { data: specializations } = useQuery({
    queryKey: ["specializations"],
    queryFn: ({ signal }) => fetchDoctorSpecializations(token, signal),
    staleTime: Infinity,
    select: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
  });

  const RejectLmdMutation = useMutation({
    mutationKey: "rejectLmdMutation",
    mutationFn: updateRejectRequest,
    onSuccess: (rejectedDoctorData, inputs) => {
      const responseData = rejectedDoctorData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(0);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      return queryClient.invalidateQueries({
        queryKey: ["rejectedDoctors", "unApprovedDoctors"],
      });
    },
    onError: (error) => {
      // console.log('error', error);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });
  const ApproveLmdMutation = useMutation({
    mutationKey: "approveLmdMutation",
    mutationFn: updateApprovalRequest,
    onSuccess: (approvedDoctorData, inputs) => {
      const responseData = approvedDoctorData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(0);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      return queryClient.invalidateQueries({
        queryKey: ["approvedDoctors", "unApprovedDoctors"],
      });
    },
    onError: (error) => {
      // console.log('error', error);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const seedScoreAppDoctorMutation = useMutation({
    mutationKey: "scoreAppMutation",
    mutationFn: addScoreAppDoctor,
    onSuccess: (approvedScoreDoctorData, inputs) => {
      const responseData = approvedScoreDoctorData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(4);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      return queryClient.invalidateQueries({
        queryKey: ["scoreApp"],
      });
    },
    onError: (error) => {
      // console.log('error', error);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const removeScoreAppDoctorMutation = useMutation({
    mutationKey: "removeScoreDoctorMutation",
    mutationFn: removeScoreAppDoctor,
    onSuccess: (removedScoreDoctorData, inputs) => {
      const responseData = removedScoreDoctorData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(0);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      refetchScoreAppDoctors();
      return queryClient.invalidateQueries({
        queryKey: ["approved"],
      });
    },
    onError: (error) => {
      // console.log('error', error);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const rejectRequestHandler = async (id, tableId) => {
    const data = {
      applicationId: id,
    };

    RejectLmdMutation.mutate({
      data,
      token,
      tableId,
    });
    RejectLmdMutation.isSuccess && refetchLoadedRejectedDoctors();
  };

  const AddScoreAppHandler = async (id, tableId) => {
    const data = {
      doctorId: id,
      scoreAppDocTorId: null,
    };

    seedScoreAppDoctorMutation.mutate({
      data,
      token,
      tableId,
    });
    seedScoreAppDoctorMutation.isSuccess && refetchScoreAppDoctors();
  };

  const removeScoreDoctorHandler = async (id, tableId) => {
    const data = {
      doctorId: id,
      scoreAppDocTorId: null,
    };

    removeScoreAppDoctorMutation.mutate({
      data,
      token,
      tableId,
    });
    removeScoreAppDoctorMutation.isSuccess && refetchScoreAppDoctors();
  };

  const approvalRequestHandler = async (id, tableId) => {
    const data = {
      applicationId: id,
    };

    ApproveLmdMutation.mutate({
      data,
      token,
      tableId,
    });

    // try {
    //   const responseData = await sendRequest(
    //     `${baseUrl}/admin/api/AdminAPI/ApproveLmd`,
    //     'POST',
    //     JSON.stringify(data),
    //     {
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + token,
    //       'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
    //     }
    //   );
    //   // console.log('responseData', responseData)
    //   setSuccess({
    //     value: responseData?.isSuccessful,
    //     message: responseData?.message
    //   })
    // } catch (err) { }
  };

  const deactivateLmdMutation = useMutation({
    mutationKey: "deactivateLmdMutation",
    mutationFn: deactivateLmd,
    onSuccess: (deactivateDoctorData, inputs) => {
      // console.log('deactivateDoctorData', deactivateDoctorData);
      const responseData = deactivateDoctorData?.data;
      // console.log('deactivateLmdMutation', inputs);
      // console.log('first', inputs.handleDeactivateClose);
      inputs.setReason("");
      inputs.handleDeactivateClose();
      handleCloseMenu(inputs?.tableId);
      // setTabsValue(2);
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      if (text) {
        setTabsValue(0);
      } else {
        setTabsValue(2);
      }

      return queryClient.invalidateQueries([
        tabsValue === 0 ? "approvedDoctors" : "deactiveDoctors",
      ]);
    },
    onError: (error) => {
      // console.log('error', error);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const deactivateRequestHandler = async (
    id,
    reason,
    tableId,
    handleDeactivateClose,
    setReason
  ) => {
    const data = {
      entityId: id,
      action: 5,
      reason,
    };
    // console.log('data', data)

    deactivateLmdMutation.mutate({
      data,
      token,
      tableId,
      handleDeactivateClose,
      setReason,
    });
  };

  const reactivateRequestHandler = async (
    id,
    reason,
    tableId,
    handleDeactivateClose,
    setReason
  ) => {
    const data = {
      entityId: id,
      action: 4,
      reason,
    };
    // console.log('data', data)

    deactivateLmdMutation.mutate({
      data,
      token,
      tableId,
      handleDeactivateClose,
      setReason,
    });
  };

  const [open, setOpen] = useState({
    approved: null,
    unApprovedDoctors: null,
    active: null,
    rejected: null,
    deActive: null,
    scoreApp: null,
  });

  const [page, setPage] = useState({
    approved: 0,
    unApprovedDoctors: 0,
    active: 0,
    rejected: 0,
    deActive: 0,
    scoreApp: 0,
  });

  const [order, setOrder] = useState({
    approved: "asc",
    unApprovedDoctors: "asc",
    active: "asc",
    rejected: "asc",
    deActive: "asc",
    scoreApp: "asc",
  });

  const [selected, setSelected] = useState({
    approved: [],
    unApprovedDoctors: [],
    active: [],
    rejected: [],
    deActive: [],
    scoreApp: [],
  });

  const [orderBy, setOrderBy] = useState({
    approved: "name",
    unApprovedDoctors: "name",
    active: "name",
    rejected: "name",
    deActive: "name",
    scoreApp: "name",
  });

  const [filterName, setFilterName] = useState({
    approved: "",
    unApprovedDoctors: "",
    active: "",
    rejected: "",
    deActive: "",
    scoreApp: "",
  });

  const [filterRole, setFilterRole] = useState({
    approved: "",
    unApprovedDoctors: "",
    active: "",
    rejected: "",
    deActive: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState({
    approved: 5,
    unApprovedDoctors: 5,
    active: 5,
    rejected: 5,
    deActive: 5,
    scoreApp: 5,
  });

  const [tabsValue, setTabsValue] = useState(0);

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleOpenMenu = (event, id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleCloseMenu = (id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleRequestSort = (event, property, id) => {
    const isAsc = orderBy[id] === property && order[id] === "asc";
    setOrder((prev) => ({
      ...prev,
      [id]: isAsc ? "desc" : "asc",
    }));
    setOrderBy((prev) => ({
      ...prev,
      [id]: property,
    }));
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected[id].indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected[id], [name]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected[id].slice(1));
    } else if (selectedIndex === selected[id].length - 1) {
      newSelected = newSelected.concat(selected[id].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected[id].slice(0, selectedIndex),
        selected[id].slice(selectedIndex + 1)
      );
    }
    setSelected((prev) => ({
      ...prev,
      [id]: newSelected,
    }));
  };

  const handleChangePage = (event, newPage, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setRowsPerPage((prev) => ({
      ...prev,
      [id]: parseInt(event.target.value, 10),
    }));
  };

  const handleFilterByName = (event, id) => {
    console.log(id,"id before")
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterName((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
     console.log(id, "id after");
  };

  console.log(filterName,"filter name")

  const handleFilterByRole = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterRole((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const emptyRowsApproved =
    page.approved > 0
      ? Math.max(
          0,
          (1 + page.approved) * rowsPerPage.approved - approvedDoctors.length
        )
      : 0;
  const emptyRowsUnApproved =
    page.unApprovedDoctors > 0
      ? Math.max(
          0,
          (1 + page.unApproved) * rowsPerPage.unApprovedDoctors -
            loadedUnApprovedDoctors.length
        )
      : 0;
  const emptyRowsActive =
    page.active > 0
      ? Math.max(
          0,
          (1 + page.active) * rowsPerPage.active - activeDoctors.length
        )
      : 0;

  const emptyRowsDeactive =
    page.active > 0
      ? Math.max(
          0,
          (1 + page.active) * rowsPerPage.active - deactivatedDoctors.length
        )
      : 0;

  const emptyRowsRejected =
    page.rejected > 0
      ? Math.max(
          0,
          (1 + page.rejected) * rowsPerPage.rejected -
            loadedRejectedDoctors.length
        )
      : 0;

  const emptyRowsScoreApp =
    page.rejected > 0
      ? Math.max(
          0,
          (1 + page.rejected) * rowsPerPage.rejected - scoreAppDoctors?.length
        )
      : 0;

  const filteredApproved = useMemo(
    () =>
      !!approvedDoctors &&
      applySortFilter(
        approvedDoctors,
        getComparator(order.approved, orderBy.approved),
        filterName.approved,
        filterRole.approved
      ),
    [
      approvedDoctors,
      order.approved,
      orderBy.approved,
      filterName.approved,
      filterRole.approved,
    ]
  );

  const filteredUnApproved = useMemo(
    () =>
      !!loadedUnApprovedDoctors &&
      applySortFilter(
        loadedUnApprovedDoctors,
        getComparator(order.unApprovedDoctors, orderBy.unApprovedDoctors),
        filterName.unApprovedDoctors,
        filterRole.unApprovedDoctors,
        "name"

      ),
    [
      loadedUnApprovedDoctors,
      order.unApprovedDoctors,
      orderBy.unApprovedDoctors,
      filterName.unApprovedDoctors,
      filterRole.unApprovedDoctors,
    ]
  );

  const filteredActive = useMemo(
    () =>
      !!activeDoctors &&
      applySortFilter(
        activeDoctors,
        getComparator(order.active, orderBy.active),
        filterName.active,
        filterRole.active
      ),
    [
      activeDoctors,
      order.active,
      orderBy.active,
      filterName.active,
      filterRole.active,
    ]
  );

  const filteredUnActive = useMemo(
    () =>
      !!deactivatedDoctors &&
      applySortFilter(
        deactivatedDoctors,
        getComparator(order.deActive, orderBy.deActive),
        filterName.deActive,
        filterRole.deActive
      ),
    [
      deactivatedDoctors,
      order.deActive,
      orderBy.deActive,
      filterName.deActive,
      filterRole.deActive,
    ]
  );

  const filteredRejected = useMemo(
    () =>
      !!loadedRejectedDoctors &&
      applySortFilter(
        loadedRejectedDoctors,
        getComparator(order.rejected, orderBy.rejected),
        filterName.rejected,
        filterRole.rejected
      ),
    [
      loadedRejectedDoctors,
      order.rejected,
      orderBy.rejected,
      filterName.rejected,
      filterRole.rejected,
    ]
  );

  const filteredScoreApp = useMemo(
    () =>
      !!scoreAppDoctors &&
      applySortFilter(
        scoreAppDoctors,
        getComparator(order.scoreApp, orderBy.scoreApp),
        filterName.scoreApp,
        filterRole.scoreApp
      ),
    [
      scoreAppDoctors,
      order.scoreApp,
      orderBy.scoreApp,
      filterName.scoreApp,
      filterRole.scoreApp,
    ]
  );

  const approvedIsNotFound = !filteredApproved.length && !!filterName.approved;
  const unApprovedIsNotFound =
    !filteredUnApproved.length && !!filterName.unApprovedDoctors;
  const activeIsNotFound = !filteredActive.length && !!filterName.active;
  const deActiveIsNotFound = !filteredUnActive.length && !!filterName.deActive;
  const rejectedIsNotFound = !filteredRejected.length && !!filterName.rejected;
  const scoreAppIsNotFound = !filteredScoreApp.length && !!filterName.rejected;

  const handleSelectAllClick = (event, id) => {
    let docList;
    if (id === "approved") {
      docList = approvedDoctors;
      // docList = filteredApproved
    } else if (id === "unApproved") {
      docList = loadedUnApprovedDoctors;
      // docList = filteredUnApproved
    } else if (id === "rejected") {
      docList = loadedRejectedDoctors;
    } else if (id === "scoreApp") {
      docList = scoreAppDoctors;
    } else {
      // docList = activeDoctors;
      docList = deactivatedDoctors;
    }
    if (event.target.checked) {
      const newSelecteds = docList.map((n) => n.name);
      setSelected((prev) => ({
        ...prev,
        [id]: newSelecteds,
      }));
      return;
    }
    setSelected({
      approved: [],
      unApprovedDoctors: [],
      active: [],
      rejected: [],
      deActive: [],
      scoreApp: [],
    });
  };

  return {
    approvedDoctors,
    loadedUnApprovedDoctors,
    activeDoctors,
    loadedRejectedDoctors,
    refetchLoadedRejectedDoctors,
    isLoading,
    unApprovedLoading,
    unApprovedError,
    unApprovedIsError,

    deactivatedDoctors,
    deactiveError,
    deActiveIsNotFound,
    deactiveIsError,
    deactiveLoading,
    emptyRowsDeactive,
    filteredUnActive,

    scoreAppDoctors,
    scoreAppLoading,
    scoreAppError,
    scoreAppIsError,
    refetchScoreAppDoctors,
    emptyRowsScoreApp,
    scoreAppIsNotFound,
    filteredScoreApp,

    rejectedLoading,
    rejectedError,
    rejectedIsError,

    approvedLoading,
    approvedError,
    approvedIsError,
    activeLoading,
    activeError,
    activeIsError,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    approvedIsNotFound,
    unApprovedIsNotFound,
    rejectedIsNotFound,
    activeIsNotFound,
    handleSelectAllClick,
    emptyRowsApproved,
    emptyRowsUnApproved,
    emptyRowsRejected,
    emptyRowsActive,
    filteredApproved,
    filteredUnApproved,
    filteredRejected,
    filteredActive,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    approvalRequestHandler,
    rejectRequestHandler,
    deactivateRequestHandler,
    reactivateRequestHandler,
    AddScoreAppHandler,
    removeScoreDoctorHandler,
    success,
    handleSnackbarClose,
    specializations,
    text,
    setText,
    RejectLmdLoading: RejectLmdMutation.isLoading,
    ApproveLmdLoading: ApproveLmdMutation.isLoading,
    deactivateLmdLoading: deactivateLmdMutation.isLoading,
    deactivateLmdMutationError: deactivateLmdMutation.error,
    ScoreDoctorLoading: seedScoreAppDoctorMutation.isLoading,
    removeScoreDoctorLoading: removeScoreAppDoctorMutation.isLoading,
  };
}

export default useDoctor;
