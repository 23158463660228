import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./ConsultationsHelperFunctions";
import { baseUrl, getComparator } from "../../../utils/helper";
import { convertToCSV } from "../../../utils/csv";
import { reactAppSubscriptionKey } from "../../../http/common";

function useConsultation(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const ConsultationPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);

  const [consultationHistory, setConsultationHistory] = useState();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  //manual csv handling
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(consultationHistory);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    const fetchConsultationHistory = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetAllConsultationsHistory`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        // console.log('approved consultations', responseData.data)
        setConsultationHistory(responseData?.data.data);
      } catch (err) {}
    };
    fetchConsultationHistory();
  }, [sendRequest, token, success.value]);

  const consultationCreationHandler = async (values, onSubmitProps) => {
    console.log("values", values);

    try {
      const formData = new FormData();
      formData.append("Email", values.Email);
      formData.append("BusinessName", values.BusinessName);
      formData.append("PhoneNumber", values.PhoneNumber);
      formData.append("Address", values.Address);
      formData.append("Logo", values.Logo);

      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/CreateConsultation`,
        "POST",
        formData,
        {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData)
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      ConsultationPageSwitch();
      setTabsValue(1);
    } catch (err) {}
  };

  const approvalConsultationRequestHandler = async (id) => {
    const data = {
      entityId: id,
    };
    try {
      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/ApproveConsultationsPending`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData)
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      setTabsValue(0);
      // history.push('/');
    } catch (err) {}
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("patientName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = consultationHistory.map((n) => n.patientName);
      const newSelecteds = consultationHistory.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - consultationHistory.length)
      : 0;

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const filteredConsultations = useMemo(
    () =>
      !!consultationHistory &&
      applySortFilter(
        consultationHistory,
        getComparator(order, orderBy),
        filterName,
        filterRole
      ),
    [consultationHistory, order, orderBy, filterName, filterRole]
  );

  const isNotFound = !filteredConsultations.length && !!filterName;

  // CSV
  const headers = [
    {
      label: "Patient",
      key: "patientName",
    },
    {
      label: "Doctor",
      key: "doctorName",
    },
    {
      label: "Category",
      key: "category",
    },
    {
      label: "Start Date",
      key: "startDate",
    },
    {
      label: "End Date",
      key: "endDate",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const csvLink = {
    filename: "Consultations.csv",
    headers: headers,
    data: consultationHistory,
  };

  const handleExportToCSV = () => {
    const arr = filterDataByDateRange(consultationHistory, startDate, endDate);
    const csvRows = [];
    // Add header row
    const headers = Object.keys(arr?.[0]);
    csvRows.push(headers.join(","));
    // Add data rows
    for (const row of arr) {
      const values = headers.map((header) => {
        let value = row[header];
        // Check if the header is 'startDate' or 'endDate' and format accordingly
        if (header === "startDate" || header === "endDate") {
          const datePickerDate = new Date(value);
          const formattedDate = datePickerDate.toISOString().substring(0, 10); // Convert to "yyyy-mm-dd" format
          value = formattedDate;
        }
        if (header === "startTime" || header === "endTime") {
          const timeOnly = value.split("T")[1];
          const [hours, minutes] = timeOnly.split(":");
          const formattedTime = `${parseInt(hours, 10) % 12}:${minutes} ${
            parseInt(hours, 10) < 12 ? "a.m." : "p.m."
          }`;
          value = formattedTime;
        }
        return value;
      });
      csvRows.push(values.join(","));
    }
    // Create a Blob containing the CSV data
    const csvData = csvRows.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    // Create a URL for the Blob and trigger a download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Consultation_Data.csv";
    a.click();
    window.URL.revokeObjectURL(url);

    setStartDate("");
    setEndDate("");
  };

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;

    if (type === "start") {
      setStartDate(selectedDate);
    } else if (type === "end") {
      setEndDate(selectedDate);
    }
  };

  const filterDataByDateRange = (data, startDate, endDate) => {
    if (startDate && endDate) {
      const filtered = data.filter((item) => {
        const itemDate = new Date(item.startDate); // Use the correct property name
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      });
      return filtered;
    } else {
      return data;
    }
  };

  return {
    consultationHistory,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredConsultations,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    consultationCreationHandler,
    success,
    handleSnackbarClose,
    openCreatePage,
    ConsultationPageSwitch,
    approvalConsultationRequestHandler,
    // csv
    csvLink,

    //custom CSV
    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
  };
}

export default useConsultation;
