import { filter } from 'lodash';

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: 'businessName', label: 'Business Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'created', label: 'Created', alignRight: false },
  // { id: 'Action', label: 'Action', alignRight: false },
  { id: '' },
];

export const myPartners = [{
  id: '123-345-766',
  businessName: 'Abel Main',
  email: 'olaba@gh.com',
  phoneNumber: '081019383221',
  address: '12, Daud Afolabi str, lagos',
  logoUrl: 'http://t1.gstatic.com/licensed-image?q=tbn:ANd9GcQ8QoQ3COhWo5Y6xjUCLvOGa1gT2_FxmvwI5rvzV17N--9sjsV9CjJP6N1IZ220zkaKakyzTnG6F3Rv65Q',
  created: '11/12/2002',
}]

// ----------------------------------------------------------------------

export function applySortFilter(list, comparator, query, category) {
  const stabilizedThis = list.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if ( query) {
    return filter(result, (_user) => {
      return _user?.businessName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    });
  }
  return result
}

export const PARTNER_UPLOAD_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  // "application/pdf",
  // "jpg",
  // "jpeg",
  // "png",
];