// import { Suspense } from 'react';
import ThemeProvider from "./theme";
import Router from "./routes";
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import { useAuth } from "./hooks/auth-hook";
import { AuthContext } from "./context/auth-context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoadingBackdrop from './components/loader';

// ----------------------------------------------------------------------

export default function App() {
  const { token, login, logout, userId, permissions } = useAuth();

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
          permissions: permissions,
        }}
      >
        {/* <Suspense
          fallback={
            <div>
              <LoadingBackdrop open={true}/>
            </div>
          }
        > */}
        <Router token={token} permissions={permissions} />
        <ToastContainer />
        {/* </Suspense> */}
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
