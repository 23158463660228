import { useContext } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// component
import { TABLE_HEAD } from "../sections/@dashboard/consultation/ConsultationsHelperFunctions";
import { AuthContext } from "../context/auth-context";
import ErrorModal from "../components/Modal/ErrorModal";
import useConsultation from "../sections/@dashboard/consultation/consultationHook";
import ConsultationPageTable from "../sections/@dashboard/consultation/ConsultationPageTable";
import TransitionsSnackbar from "../components/snackBar";
import LoadingBackdrop from "../components/loader";
import { a11yProps, TabPanel } from "../utils/helper";
import Empty from "../components/commonElements/empty/empty";

export default function ConsultationPage() {
  const auth = useContext(AuthContext);
  const {
    consultationHistory,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredConsultations,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    csvLink,
    //custom csv
    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
  } = useConsultation(auth);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading}
        styles={{
          marginTop: "12rem",
          mx: "1.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <Container
          sx={{
            mt: 2,
          }}
        >
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Consultations
            </Typography>
          </Stack> */}

          {
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="Consultation tabs"
                >
                  <Tab label="Lists" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {consultationHistory?.length < 1 && (
                  <Empty
                    altText="Consultations list is empty"
                    title="No approved consultations yet."
                  />
                )}
                {consultationHistory?.length > 0 && (
                  <ConsultationPageTable
                    // id='approved'
                    selected={selected}
                    filterName={filterName}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole}
                    handleFilterByRole={handleFilterByRole}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredConsultations}
                    mainList={consultationHistory}
                    rowsPerPage={rowsPerPage}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRows}
                    isNotFound={isNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    open={open}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    csvLink={csvLink}
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleExportToCSV={handleExportToCSV}
                  />
                )}
              </TabPanel>
            </Card>
          }
        </Container>
      </LoadingBackdrop>
    </>
  );
}
