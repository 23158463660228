// @mui
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// mock
// import TransitionsModal from './AdminModal';
import AdminListToolbar from './AdminListToolbar';
import AdminListHead from './AdminListHead';

// ----------------------------------------------------------------------

export default function AdminPageTable({
  id: tableId,
  selected,
  filterName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  tabsValue,
  isLoading
}) {

  return (
    <>
      <Box sx={{}}>
        <AdminListToolbar
          numSelected={selected.length}
          userSpecializations={[]}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          filterRole={filterRole}
          onFilterRole={(event) => handleFilterByRole(event, tableId)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <AdminListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) => handleRequestSort(event, property, tableId)}
                onSelectAllClick={(event) => handleSelectAllClick(event, tableId)}
              />
              <TableBody>
                {!!filteredUsers.length && filteredUsers.slice(
                  page * rowsPerPage, page * rowsPerPage + rowsPerPage
                ).map((row, index) => {
                  const { firstName, lastName, phoneNumber, email } = row;

                  const selectedUser = !!selected && selected.indexOf(email) !== -1;

                  return (
                    <TableRow
                      hover
                      key={email}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) => handleClick(event, email, tableId)}
                        />
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ pl: 2 }}
                          >
                            {firstName}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell
                        align="left"
                      >
                        {lastName}
                      </TableCell>

                      <TableCell
                        align="left"
                      >
                        {email}
                      </TableCell>

                      <TableCell
                        align="left"
                      >
                        {phoneNumber}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(event) => {
                            handleOpenMenu(event, tableId);
                          }}
                        >
                          <Iconify
                            icon={'eva:more-vertical-fill'}
                            sx={{
                              color: 'primary.main'
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(event, newPage, tableId)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, tableId)}
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {/* <TransitionsModal
        selectedAdmin={selectedAdmin}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        approvalRequestHandler={approvalRequestHandler}
        isLoading={isLoading}
      /> */}
    </>
  );
}
