import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./TransactionsHelperFunctions";
import { baseUrl, getComparator } from "../../../utils/helper";
import { reactAppSubscriptionKey } from "../../../http/common";

// process.env.REACT_APP_BACKEND_URL !== 'production

function useTransaction(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const TransactionPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);

  const [transactionHistory, setTransactionHistory] = useState();
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetAllTransactionHistory`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        setTransactionHistory(responseData?.data.data);
      } catch (err) {}
    };
    fetchTransactionHistory();
  }, [sendRequest, token, success.value]);

  const approvalTransactionRequestHandler = async (id) => {
    const data = {
      entityId: id,
    };
    try {
      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/ApproveTransactionsPending`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );

      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      setTabsValue(0);
      // history.push('/');
    } catch (err) {}
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("patientName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = transactionHistory.map((n) => n.patientName);
      const newSelecteds = transactionHistory.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - transactionHistory.length)
      : 0;

  // const filteredTransactions = applySortFilter(transactionHistory, getComparator(order, orderBy), filterName);

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  // const filteredTransactions = useMemo(
  //   () =>
  //     !!transactionHistory &&
  //     transactionHistory?.filter(
  //       (transaction) => transaction.orderType === filterRole
  //     ),
  //   applySortFilter(
  //     transactionHistory,
  //     getComparator(order, orderBy),
  //     filterName,
  //     filterRole
  //   )
  //   [transactionHistory, order, orderBy, filterName, filterRole]
  // );

  // useEffect(() => {
  //   if (transactionHistory?.length > 0) {
  //     setFilteredTransactions(transactionHistory);
  //   }
  // }, []);

  useEffect(() => {
    if (transactionHistory?.length > 0) {
      const arr = transactionHistory?.filter(
        (transaction) =>
          transaction?.orderType?.includes(filterRole) &&
          (transaction?.patientName?.toLowerCase() ===
            filterName?.toLowerCase() ||
            transaction?.patientName
              ?.toLowerCase()
              .includes(filterName?.toLowerCase()))
      );
      setFilteredTransactions(arr);
    }
  }, [transactionHistory, filterName, filterRole]);

  const isNotFound = !filteredTransactions.length && !!filterName;

  return {
    transactionHistory,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredTransactions,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    TransactionPageSwitch,
    approvalTransactionRequestHandler,
  };
}

export default useTransaction;
