import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { Controller, FormProvider, useFormContext } from "react-hook-form";

import { cn } from "../../../shadcn/utils";
import { Label } from "./label";
import { cva } from "class-variance-authority";

const Form = FormProvider;

const FormFieldContext = React.createContext({});

const FormField = ({ ...props }) => {
   return (
      <FormFieldContext.Provider value={{ name: props.name }}>
         <Controller {...props} />
      </FormFieldContext.Provider>
   );
};

const useFormField = () => {
   const fieldContext = React.useContext(FormFieldContext);
   const itemContext = React.useContext(FormItemContext);
   const { getFieldState, formState } = useFormContext();

   const fieldState = getFieldState(fieldContext.name, formState);

   if (!fieldContext) {
      throw new Error("useFormField should be used within <FormField>");
   }

   const { id } = itemContext;

   return {
      id,
      name: fieldContext.name,
      formItemId: `${id}-form-item`,
      formDescriptionId: `${id}-form-item-description`,
      formMessageId: `${id}-form-item-message`,
      ...fieldState,
   };
};

const FormItemContext = React.createContext({});

const formItemVariants = cva("space-y-0", {
   variants: {
      variant: {
         default: "space-y-2",
         customOutlined:
            "space-y-1 relative [&_label]:absolute [&_label]:rounded-t-md [&_label]:border [&_label]:border-shadcn-input [&_label]:border-b-transparent [&_label]:w-full [&_label]:py-2 [&_label]:px-3 [&_label]:bg-shadcn-background  [&_input]:h-fit [&_input]:pt-7 [&_input]:px-3 [&_input]:pb-2  [&_textarea]:pt-7 [&_textarea]:px-3 [&_textarea]:pb-2  [&_.form-element]:h-fit [&_.form-element]:pt-7 [&_.form-element]:px-3 [&_.form-element]:pb-2  [&:has(.form-select)_label]:bg-transparent [&:has(.form-select)_label]:-z-[1] [&:has(.form-select)_.form-select]:border-t-0 [&:has(.form-select)_.form-select]:bg-transparent",
      },
   },
});

const FormItem = React.forwardRef(({ variant, className, ...props }, ref) => {
   const id = React.useId();

   return (
      <FormItemContext.Provider value={{ id }}>
         <div
            id="form-item"
            ref={ref}
            className={formItemVariants({ variant, className })}
            {...props}
         />
      </FormItemContext.Provider>
   );
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef(({ className, ...props }, ref) => {
   const { error, formItemId } = useFormField();

   return (
      <Label
         ref={ref}
         className={cn(error && "text-destructive", className)}
         htmlFor={formItemId}
         {...props}
      />
   );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef(({ ...props }, ref) => {
   const { error, formItemId, formDescriptionId, formMessageId } =
      useFormField();

   return (
      <Slot
         ref={ref}
         id={formItemId}
         aria-describedby={
            !error
               ? `${formDescriptionId}`
               : `${formDescriptionId} ${formMessageId}`
         }
         aria-invalid={!!error}
         {...props}
      />
   );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef(({ className, ...props }, ref) => {
   const { formDescriptionId } = useFormField();

   return (
      <p
         ref={ref}
         id={formDescriptionId}
         className={cn("text-sm text-shadcn-muted-foreground", className)}
         {...props}
      />
   );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef(
   ({ className, children, ...props }, ref) => {
      const { error, formMessageId } = useFormField();
      const body = error ? String(error?.message) : children;

      if (!body) {
         return null;
      }

      return (
         <p
            ref={ref}
            id={formMessageId}
            className={cn(
               "text-sm font-medium text-shadcn-destructive",
               className
            )}
            {...props}
         >
            {body}
         </p>
      );
   }
);
FormMessage.displayName = "FormMessage";

export {
   useFormField,
   Form,
   FormItem,
   FormLabel,
   FormControl,
   FormDescription,
   FormMessage,
   FormField,
};
