import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/shadch/ui/ui/card";
import img from "../../../components/icons/cardIcon.svg";

const PlanCard = ({ title, desc, content, footer }) => {
  return (
    <div>
      <Card className=" text-center ">
        <CardHeader>
          <CardTitle className="flex justify-center mt-3">
            <div className="rounded-full bg-[#2CBCEF1D] w-14 h-14 flex justify-center items-center p-2">
              <img src={img} alt="Card Icon" />
            </div>
          </CardTitle>
          <CardDescription>{desc}</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-gray-500">{content}</p>
          <p className="text-sm" >{footer}</p>
        </CardContent>
        <CardFooter>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PlanCard;
