// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import useApp from '../sections/@dashboard/app/appHook';
import { AuthContext } from '../context/auth-context';
import { useContext } from 'react';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const auth = useContext(AuthContext);
  const theme = useTheme();
  const {
    doctorTotalNo,
    patientTotalNo,
    pharmaciesTotalNo,
    labsTotalNo,
    consultationsTotalNo
  } = useApp(auth)

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Patients"
              total={patientTotalNo}
              color="prev_primary"
              icon={'mdi:patient'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Doctors"
              total={doctorTotalNo}
              color="info"
              icon={'fontisto:doctor'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Consultations"
              total={consultationsTotalNo}
              color="success"
              icon={'icon-park-solid:appointment'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Pharmacies"
              total={pharmaciesTotalNo}
              color="warning"
              icon={'mdi:local-pharmacy'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Laboratories"
              total={labsTotalNo}
              color="error"
              icon={'healthicons:biochemistry-laboratory'}
            />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
