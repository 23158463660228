import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Iconify from "../../../components/iconify";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import InputField from "../../../components/InputField";
import Scrollbar from "../../../components/scrollbar";
import { useHttpClient } from "../../../hooks/http-hook";
import { baseUrl, getComparator } from "../../../utils/helper";
import { useAuth } from "../../../hooks/auth-hook";
import { toast } from "react-toastify";
import { useState } from "react";
import { reactAppSubscriptionKey } from "../../../http/common";

// ----------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 700, xs: 350, md: 700 },
  height: "auto",
  bgcolor: "background.paper",
  // border: '1px solid #000',
  borderRadius: "0.4rem",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function UserDetailModal({
  selectedConsultation,
  openModal,
  handleModalOpen,
  selectedPatient,
  showEdit,
  handleModalClose,
  fetchTemporaryUsers,
}) {
  const {
    id,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    transactionId,
    religion,
    gender,
    price,
  } = !!selectedPatient && selectedPatient;
  const { sendRequest } = useHttpClient();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPatient) {
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("gender", gender);
      setValue("religion", religion);
      setValue("emailAddress", emailAddress);
      setValue("phoneNumber", phoneNumber);
      setValue("price", price);
    }
  }, [selectedPatient]);

  const { register, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      religion: "",
      emailAddress: "",
      phoneNumber: "",
      price: "",
      startTime: "",
      endTime: "",
    },
    mode: "all",
  });
  const { errors } = formState;

  const editTemporaryUserDetails = async (data) => {
    const payload = {
      id: id,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      religion: data.religion,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      price: selectedPatient.price,
      startTime: selectedPatient.startTime,
      endTime: selectedPatient.endTime,
      doctorId: selectedPatient.doctorsId,
    };
    setLoading(true);
    try {
      const responseData = await sendRequest(
        `${baseUrl}/patient/api/Admin/EditUser`,
        "POST",
        JSON.stringify(payload),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      setLoading(false);
      console.log("responseData", responseData);
      if (responseData.statusCode === 200) {
        toast.success(responseData?.message, {
          position: "top-center",
        });
        fetchTemporaryUsers();
        handleModalClose();
      } else {
        setLoading(false);
        toast.error("Error editing user", {
          position: "top-center",
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}></div>

              <Typography
                sx={{
                  // display: "flex",
                  // alignSelf: "end",
                  cursor: "pointer",
                }}
                onClick={handleModalClose}
              >
                <Iconify icon={"mdi:close"} width={30} />
              </Typography>
            </Box>

            <Typography
              variant="h6"
              noWrap
              textTransform={"capitalize"}
              sx={{ color: "#343673", marginY: "1rem" }}
            >
              {showEdit ? "Edit Patient's detail" : "Patient's Detail"}
            </Typography>
            {showEdit ? (
              <>
                <Scrollbar
                  sx={
                    {
                      // height:"100",
                      // border: "2px solid red",
                      // height: { xs: 340, sm: "auto" },
                    }
                  }
                >
                  <div
                    style={{
                      height: 500,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="First Name"
                        id="firstName"
                        name="firstName"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter first name"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.firstName?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Last Name"
                        id="lastName"
                        name="lastName"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter last name"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.lastName?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Email Address"
                        id="emailAddress"
                        name="emailAddress"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter email address"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.emailAddress?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Phone Number"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter Phone Number"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.phoneNumber?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Gender"
                        id="gender"
                        name="gender"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter gender"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.gender?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Religion"
                        id="religion"
                        name="religion"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter religion"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.religion?.message}
                      </small>
                    </div>
                    <div style={{ width: "100%" }}>
                      <InputField
                        label="Consultation Price"
                        id="price"
                        name="price"
                        type="text"
                        boldLabel="yes"
                        required={true}
                        asteriks
                        placeholder="Enter Consultation Price"
                        register={register}
                      />
                      <small
                        style={{
                          color: "red",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        {errors.price?.message}
                      </small>
                    </div>
                  </div>
                </Scrollbar>
                <button
                  type="button"
                  onClick={handleSubmit(editTemporaryUserDetails)}
                  style={{
                    backgroundColor: "#2CBCEF",
                    color: "white",
                    border: "none",
                    borderRadius: "10px",
                    padding: "1rem",
                    fontWeight: "600",
                    cursor: "pointer",
                    transition: "background-color 0.3s, transform 0.3s",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(0.98)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#2CBCEF";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  {loading ? "Submitting ..." : "Submit"}
                </button>
              </>
            ) : (
              <>
                {" "}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Name</p>

                    <p>{`${firstName} ${lastName}`}</p>
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Email Address</p>

                    <p>{emailAddress}</p>
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Phone No.</p>

                    <p>{phoneNumber}</p>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginY: "2rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Transaction ID</p>

                    <p>{transactionId}</p>
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Religion</p>

                    <p>{religion}</p>
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>Gender</p>

                    <p>{gender}</p>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
