import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import MetricCard from "../components/ContractCard/MetricCard";
import EmptyContractPage from "../components/ContractCard/EmptyContractPage";
import useContract from "../sections/@dashboard/contract/ContractHook";
import ContractDetailTable from "../sections/@dashboard/contract/ContractDetailTable";
import { CONTRACT_TABLE_HEAD } from "../sections/@dashboard/contract/ContractHelperFunction";
import { useContext } from "react";
import { AuthContext } from "../context/auth-context";

const ContractDetails = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const {
    contractDoctors,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    FilteredDoctors,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    contractPageSwitch,
    approvalTransactionRequestHandler,
  } = useContract(auth);
  return (
    <>
      <Box
        sx={{
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {/* reeplace with name of contract */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#2CBCEF", fontWeight: "500", fontSize: "20px" }}
          >
            CCL contract 1
          </Typography>
          <Typography
            sx={{ fontSize: "25px", cursor: "pointer" }}
            onClick={() => navigate("/contract")}
          >
            <AiOutlineClose />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <MetricCard />
          <MetricCard />
          <MetricCard />
          <MetricCard />
          <MetricCard />
          <MetricCard />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Availability period (in a day)
              </p>
              <ul
                style={{
                  color: "#444448",
                  fontWeight: "500",
                  marginLeft: "2rem",
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <li>Monday</li>
                <li>Tuesday</li>
                <li>Wednesday</li>
              </ul>
              <p style={{ color: "#444448", fontWeight: "500" }}>8am - 6pm</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Notice period (in days)
              </p>
              <p style={{ color: "#444448", fontWeight: "500" }}>20</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Consultation Fee
              </p>
              <p style={{ color: "#444448", fontWeight: "500" }}>20</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Area of specialization covered
              </p>
              <p style={{ color: "#444448", fontWeight: "500" }}>
                General Practicioner
              </p>
            </div>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Consultation duration (in minutes)
              </p>
              <p style={{ color: "#444448", fontWeight: "500" }}>20</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>Pay per hour</p>
              <p style={{ color: "#444448", fontWeight: "500" }}>20</p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1px" }}
            >
              <p style={{ color: "#808080", fontSize: "14px" }}>
                Number of instances
              </p>
              <p style={{ color: "#444448", fontWeight: "500" }}>20</p>
            </div>
          </Box>
        </Box>
        <Typography
          sx={{ color: "#2CBCEF", fontWeight: "500", fontSize: "20px" }}
        >
          Doctors under this contract type
        </Typography>
        {/* if there are no doctors here
        <Box>
          <EmptyContractPage />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Typography sx={{ color: "black", textDecoration: "underline" }}>
              Cancel
            </Typography>
            <Button
              style={{
                backgroundColor: "#EB5757",
                color: "white",
                borderRadius: "4px",
                padding: "8px 12px",
                fontSize: "12px",
                border: "none",
                cursor: "pointer",
              }}
              type="button"
            >
              Delete contract type
            </Button>
          </Box>
        </Box> */}
        {/* Table will be here */}
        <Card>
          {contractDoctors.length > 0 && (
            <ContractDetailTable
              TABLE_HEAD={CONTRACT_TABLE_HEAD}
              selected={selected}
              filterName={filterName}
              handleFilterByName={handleFilterByName}
              filterRole={filterRole}
              handleFilterByRole={handleFilterByRole}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
              filteredUsers={FilteredDoctors}
              mainList={contractDoctors}
              rowsPerPage={rowsPerPage}
              handleClick={handleClick}
              handleOpenMenu={handleOpenMenu}
              emptyRows={emptyRows}
              isNotFound={isNotFound}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              open={open}
              handleCloseMenu={handleCloseMenu}
              tabsValue={tabsValue}
              isLoading={isLoading}
            />
          )}
        </Card>
      </Box>
    </>
  );
};

export default ContractDetails;
