import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useField } from "formik";
import "./ImageUpload.css";

const ImageUpload = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const { setValue, setTouched, setError } = helpers;

  const [file, setFile] = useState();
  // const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      props.setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    // let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      // console.log('pickedFile', pickedFile)
      if (!props.supportedFormat.includes(pickedFile?.type)) {
        setError("Wrong file format");
        alert("Wrong file format");
        return;
      }
      setFile(pickedFile);
      // setIsValid(true);
      // fileIsValid = true;
      setError(undefined, false);
      setValue(pickedFile, true);
      // console.log('pickedFile', pickedFile)
    } else {
      // setIsValid(false);
      // fileIsValid = false;
      setError("Please provide an image.");
    }
    setTouched(true, false);
    // props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="form-control">
      <input
        id={props.id}
        name={props.name}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        // accept=".jpg,.png,"
        // {...field}
        onChange={pickedHandler}
      />
      <div className={`image-upload ${props.center && "center"}`}>
        <div className="image-upload__preview">
          {props.previewUrl && props.previewUrl.includes("pdf") ? (
            <iframe
              src={`${props?.previewUrl}#page=1`}
              width="100%"
              height="100%"
              style={{ border: "none", overflow: "hidden" }}
            ></iframe>
          ) : (
            props.previewUrl && <img src={props?.previewUrl} alt="Preview" />
          )}
          {!props.previewUrl && <p>Please pick a file.</p>}
        </div>
        <Button
          variant="outlined"
          sx={{
            mt: "2rem",
          }}
          // type="button"
          onClick={pickImageHandler}
        >
          {props.label}
        </Button>
      </div>
      {meta.error && meta.touched && <div>{meta.error}</div>}
      {/* <div>{meta.error}</div> */}
    </div>
  );
};

export default ImageUpload;
