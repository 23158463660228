import { filter } from "lodash";
import * as Yup from "yup";

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  // "jpg",
  // "jpeg",
  // "png",
];

// ----------------------------------------------------------------------
export const APPROVED_PHARMACY_TABLE_HEAD = [
  { id: "pharmacyName", label: "Name", alignRight: false },
  { id: "licenseNo", label: "License No", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "contactPhone", label: "Phone Number", alignRight: false },
  { id: "pharmacyAddress", label: "Address", alignRight: false },
  { id: "" },
];

export const UNAPPROVED_PHARMACY_TABLE_HEAD = [
  { id: "pharmacyName", label: "Name", alignRight: false },
  { id: "licenseNo", label: "License No", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "contactPhone", label: "Phone Number", alignRight: false },
  { id: "pharmacyAddress", label: "Address", alignRight: false },
  { id: "" },
];
export const APPROVED_LAB_TABLE_HEAD = [
  { id: "labName", label: "Name", alignRight: false },
  { id: "labEmail", label: "Email", alignRight: false },
  { id: "labContactPhone", label: "Phone Number", alignRight: false },
  { id: "labAddress", label: "Address", alignRight: false },
  { id: "" },
];

export const UNAPPROVED_LAB_TABLE_HEAD = [
  { id: "labName", label: "Name", alignRight: false },
  { id: "labLicenseNo", label: "License No", alignRight: false },
  { id: "labEmail", label: "Email", alignRight: false },
  { id: "labContactPhone", label: "Phone Number", alignRight: false },
  { id: "labAddress", label: "Address", alignRight: false },
  { id: "" },
];
export const UNAPPROVED_HOSPITAL_TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "licenseNo", label: "License No", alignRight: false },

  { id: "email", label: "Email", alignRight: false },
  { id: "contactPhone", label: "Phone Number", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "" },
];

export const APPROVED_HOSPITAL_TABLE_HEAD = [
  { id: "hospitalName", label: "Name", alignRight: false },
  { id: "licenseNo", label: "License No", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "contactPhone", label: "Phone Number", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "" },
];

export const FacilityTypes = [
  {
    // value: "pharmacy",
    value: 1,
    label: "Pharmacy",
  },
  {
    // value: "lab",
    value: 0,
    label: "Laboratory",
  },
  {
    // value: "hospital",
    value: 2,
    label: "Hospital",
  },
];

// ----------------------------------------------------------------------

export function applySortFilter(
  normalisedArray,
  comparator,
  query,
  category,
  queryParameters
) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if (query) {
    return filter(result, (_user) => {
      return (
        _user[queryParameters]?.toLowerCase().indexOf(query?.toLowerCase()) !==
        -1
      );
    });
  }
  return result;
}

export const INITIAL_FORM_STATE = {
  email: "",
  // facilityType: "pharmacy",
  facilityType: 1,
  pharmacyName: "",
  contactPhone: "",
  pharmacyAddress: "",
  licenseNo: "",
  state: "",
  city: "",
  lga: "",
  country: "Nigeria",
  pharmacyRebate: "",
  licenseUrl: "",
  labName: "",
  labContactPhone: "",
  labEmail: "",
  labRebate: "",
  labAddress: "",
  labLicenseNo: "",
  labLicenseUrl: "",
  labState: "",
  labCity: "",
  labLga: "",
  labCountry: "Nigeria",
};

export const INITIAL_FORM_STATES = {
  email: "",
  // facilityType: "pharmacy",
  facilityType: 1,
  facilityName: "",
  contactPhone: "",
  facilityAddress: "",
  licenseNo: "",
  state: "",
  city: "",
  lga: "",
  country: "Nigeria",
  rebate: "",
  licenseUrl: "",
};


// export const FORM_VALIDATION = Yup.object().shape({
//   // website: Yup.string().url().nullable(),
//   // partnerId: Yup.string()
//   //   .required('Required'),
//   partnerId: Yup.object().required("Required"),
//   facilityType: Yup.string().required("Required"),
//   email: Yup.string()
//     .trim()
//     .email("Invalid email.")
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   labEmail: Yup.string()
//     .trim()
//     .email("Invalid email.")
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   pharmacyName: Yup.string()
//     .trim()
//     .min(2, "Too Short!")
//     .max(70, "Too Long!")
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   labName: Yup.string()
//     .trim()
//     .min(2, "Too Short!")
//     .max(70, "Too Long!")
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   contactPhone: Yup.string()
//     .trim()
//     .matches(
//       /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}$/,
//       "Invalid phone number"
//     )
//     .typeError("Please enter a valid phone number")
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   labContactPhone: Yup.string()
//     .trim()
//     .typeError("Please enter a valid phone number")
//     .matches(
//       /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}$/,
//       "Invalid phone number"
//     )
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   pharmacyAddress: Yup.string()
//     .trim()
//     .min(2, "Too Short!")
//     .max(70, "Too Long!")
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   pharmacyRebate: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   city: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   // lga: Yup.string()
//   //   .trim()
//   //   .when("facilityType", {
//   //     is: (value) => value === "pharmacy",
//   //     then: Yup.string().required(),
//   //   }),
//   // state: Yup.string()
//   //   .trim()
//   //   .when("facilityType", {
//   //     is: (value) => value === "pharmacy",
//   //     then: Yup.string().required(),
//   //   }),
//   country: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   labAddress: Yup.string()
//     .trim()
//     .min(2, "Too Short!")
//     .max(70, "Too Long!")
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   labRebate: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   licenseNo: Yup.string()
//     .typeError("Please enter a valid license")
//     .when("facilityType", {
//       is: (value) => value === "pharmacy",
//       then: Yup.string().required(),
//     }),
//   labLicenseNo: Yup.string()
//     .typeError("Please enter a valid license")
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   labCity: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   // labLga: Yup.string()
//   //   .trim()
//   //   .when("facilityType", {
//   //     is: (value) => value === "lab",
//   //     then: Yup.string().required(),
//   //   }),
//   // labState: Yup.string()
//   //   .trim()
//   //   .when("facilityType", {
//   //     is: (value) => value === "lab",
//   //     then: Yup.string().required(),
//   //   }),
//   labCountry: Yup.string()
//     .trim()
//     .when("facilityType", {
//       is: (value) => value === "lab",
//       then: Yup.string().required(),
//     }),
//   licenseUrl: Yup.mixed().when("facilityType", {
//     is: (value) => value === "pharmacy",
//     then: Yup.string()
//       // .test(
//       //   "fileFormat",
//       //   "Unsupported Format",
//       //   value => SUPPORTED_FORMATS.includes(value.type)
//       // )
//       .required("A file is required"),
//   }),
//   labLicenseUrl: Yup.mixed().when("facilityType", {
//     is: (value) => value === "lab",
//     then: Yup.string()
//       // .test(
//       //   "fileFormat",
//       //   "Unsupported Format",
//       //   value => {
//       //     console.log('SUPPORTED_FORMATS', value.type);
//       //     // return value && SUPPORTED_FORMATS.includes(value.type)
//       //     return SUPPORTED_FORMATS.includes(value.type)
//       //   }
//       // )
//       .required("A file is required"),
//   }),
// });

export const FORM_VALIDATION = Yup.object().shape({
  partnerId: Yup.object().required("Required"),
  facilityType: Yup.number().required("Required"),
  email: Yup.string().trim().email("Invalid email.").required(),
  facilityName: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required(),
  // .when("facilityType", {
  //   is: (value) => value === "pharmacy",
  //   then: Yup.string().required(),
  // }),
  // contactPhone: Yup.string()
  //   .trim()
  //   .matches(
  //     /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}$/,
  //     "Invalid phone number"
  //   )
  //   .typeError("Please enter a valid phone number")
  //   .required(),

  facilityAddress: Yup.string()
    .trim()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required(),
  rebate: Yup.string().trim().required(),
  city: Yup.string().trim().required(),
  country: Yup.string().trim().required(),
  // contactPhone:Yup.string().trim().required(),
  licenseNo: Yup.string().typeError("Please enter a valid license").required(),
  licenseUrl: Yup.mixed().required("A file is required"),

  // labLicenseUrl: Yup.mixed().when("facilityType", {
  //   is: (value) => value === "lab",
  //   then: Yup.string().required("A file is required"),
  // }),
});

export const states = [
  {
    label: "FCT",
    value: "Abuja",
  },
  {
    label: "Abia",
    value: "Abia",
  },
  {
    label: "Adamawa",
    value: "Adamawa",
  },
  {
    label: "Akwa Ibom",
    value: "Akwa Ibom",
  },
  {
    label: "Anambra",
    value: "Anambra",
  },
  {
    label: "Bauchi",
    value: "Bauchi",
  },
  {
    label: "Bayelsa",
    value: "Bayelsa",
  },
  {
    label: "Benue",
    value: "Benue",
  },
  {
    label: "Borno",
    value: "Borno",
  },
  {
    label: "Cross River",
    value: "Cross River",
  },
  {
    label: "Delta",
    value: "Delta",
  },
  {
    label: "Ebonyi",
    value: "Ebonyi",
  },
  {
    label: "Edo",
    value: "Edo",
  },
  {
    label: "Ekiti",
    value: "Ekiti",
  },
  {
    label: "Enugu",
    value: "Enugu",
  },
  {
    label: "Gombe",
    value: "Gombe",
  },
  {
    label: "Imo",
    value: "Imo",
  },
  {
    label: "Jigawa",
    value: "Jigawa",
  },
  {
    label: "Kaduna",
    value: "Kaduna",
  },
  {
    label: "Kano",
    value: "Kano",
  },
  {
    label: "Katsina",
    value: "Katsina",
  },
  {
    label: "Kebbi",
    value: "Kebbi",
  },
  {
    label: "Kogi",
    value: "Kogi",
  },
  {
    label: "Kwara",
    value: "Kwara",
  },
  {
    label: "Lagos",
    value: "Lagos",
  },
  {
    label: "Nasarawa",
    value: "Nasarawa",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Ogun",
    value: "Ogun",
  },
  {
    label: "Ondo",
    value: "Ondo",
  },
  {
    label: "Osun",
    value: "Osun",
  },
  {
    label: "Oyo",
    value: "Oyo",
  },
  {
    label: "Plateau",
    value: "Plateau",
  },
  {
    label: "Rivers",
    value: "Rivers",
  },
  {
    label: "Sokoto",
    value: "Sokoto",
  },
  {
    label: "Taraba",
    value: "Taraba",
  },
  {
    label: "Yobe",
    value: "Yobe",
  },
  {
    label: "Zamfara",
    value: "Zamfara",
  },
];
