import React, { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormikContext } from "formik";
import MyInput from "../../../components/commonElements/input/MyInput";
import ImageUpload from "../../../components/commonElements/image/ImageUpload";
import { states, SUPPORTED_FORMATS } from "./FacilityHelperFunctions";
import MySelect from "../../../components/commonElements/select/MySelect";
import MyAutocomplete from "../../../components/commonElements/Autocomplete/MyAutocomplete";
import stateLgaList from "../../../utils/countriesList";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Box } from "@mui/system";

function FacilityFormFields({
  pharmacyPreviewUrl,
  setPharmacyPreviewUrl,
  phone,
  setPhone,
  labPreviewUrl,
  setLabPreviewUrl,
}) {
  const [lgaArr, setLgaArr] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { values } = useFormikContext();
  const { facilityType } = values;
  const [valid, setValid] = useState(true);

  const stateList = stateLgaList?.map((state) => {
    return {
      label: state?.state,
      value: state?.state,
      lgas: state?.lgas,
    };
  });

  useEffect(() => {
    if (values?.state?.value) {
      const arr = stateList
        ?.find((state) => state.value === values?.state?.value)
        ?.lgas?.map((item) => {
          return { label: item, value: item };
        });

      setLgaArr(arr);
    }
  }, [values?.state?.value]);

  useEffect(() => {
    if (values?.labState?.value) {
      const arr = stateList
        ?.find((state) => state.value === values?.labState?.value)
        ?.lgas?.map((item) => {
          return { label: item, value: item };
        });

      setLgaArr(arr);
    }
  }, [values?.labState?.value]);

  const handlePhoneChange = (value) => {
    setPhone(`+${value}`);
    setValid(validatePhone(`+${value}`));
  };

  const validatePhone = (phoneNumber) => {
    const phone = parsePhoneNumberFromString(String(phoneNumber));
    return phone ? phone.isValid() : false;
  };


  return (
    <>
      <MyInput
        id="facilityName"
        name="facilityName"
        type="text"
        label={`${
          facilityType === 1
            ? "Pharmacy Name"
            : facilityType === 0
            ? "Laboratory Name"
            : facilityType === 2
            ? "Hospital Name"
            : ""
        }`}
      />
      <MyInput id="email" type="email" name="email" label="E-mail" />
      {/* <MyInput
        id="contactPhone"
        name="contactPhone"
        type="text"
        label="Contact Phone Number"
      />
   */}
      <label>
        Contact Phone Number
        <PhoneInput
          id="contactPhone"
          country={"ng"}
          value={phone}
          onChange={handlePhoneChange}
          enableSearch={true}
          inputStyle={{ width: "100%" }}
          inputProps={{
            required: true,
          }}
        />
      </label>
      {!valid && (
        <p style={{ color: "red", marginTop: "-10px", fontSize: "12px" }}>
          Please enter a valid phone number.
        </p>
      )}

      <MyInput
        id="facilityAddress"
        name="facilityAddress"
        type="text"
        label="Facility Address"
      />
      <MyInput
        id="rebate"
        name="rebate"
        label="Rebate"
        type="text"
        // InputProps={{
        //   endAdornment: <InputAdornment position="start">%</InputAdornment>,
        // }}
      />
      <MyInput
        id="licenseNo"
        name="licenseNo"
        type="text"
        label="License Number"
      />
      <MyInput id="city" name="city" type="text" label="City" />

      <MyAutocomplete
        id="state"
        name="state"
        label="State"
        options={stateList}
      />
      <MyAutocomplete id="lga" name="lga" label="LGA" options={lgaArr} />
      <ImageUpload
        id="licenseUrl"
        name="licenseUrl"
        label="UPLOAD YOUR LICENCE"
        previewUrl={labPreviewUrl}
        setPreviewUrl={setLabPreviewUrl}
        supportedFormat={SUPPORTED_FORMATS}
      />
    </>
  );
}
export default FacilityFormFields;
