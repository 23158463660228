import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { baseUrl, getComparator } from "../../../utils/helper";

// process.env.REACT_APP_BACKEND_URL !== 'production

function useContract(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const contractPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);

  const [contractDoctors, setContractDoctors] = useState([
    {
      id: "1",
      doctorName: "Tony lanez",
      dateCreated: "2023-08-02T04:16:10.931699",
      cclId: "20223",
      areaOfSpecialization: "Behavioural Neurology",
      logoUrl:
        "https://testingupload1.blob.core.windows.net/publicuploads/jpyCXC48_App%20icon%20-%20inverted.jpg",
    },
    {
      id: "2",
      doctorName: "Tony lanez",
      dateCreated: "2023-08-02T04:16:10.931699",
      cclId: "20223",
      areaOfSpecialization: "Behavioural Neurology",
      logoUrl:
        "https://testingupload1.blob.core.windows.net/publicuploads/jpyCXC48_App%20icon%20-%20inverted.jpg",
    },
    {
      id: "3",
      doctorName: "Tony lanez",
      dateCreated: "2023-08-02T04:16:10.931699",
      cclId: "20223",
      areaOfSpecialization: "Behavioural Neurology",
      logoUrl:
        "https://testingupload1.blob.core.windows.net/publicuploads/jpyCXC48_App%20icon%20-%20inverted.jpg",
    },
  ]);
  const [FilteredDoctors, setFilteredDoctors] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  //   useEffect(() => {
  //     const fetchTransactionHistory = async () => {
  //       try {
  //         const responseData = await sendRequest(
  //           `${baseUrl}/report/api/AdminReport/GetAllTransactionHistory`,
  //           undefined,
  //           undefined,
  //           {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + token,
  //             "Ocp-Apim-Subscription-Key": "ad907e9b3ab74347aafb8a12f8ac5d28",
  //           }
  //         );
  //         setTransactionHistory(responseData?.data.data);
  //       } catch (err) {}
  //     };
  //     fetchTransactionHistory();
  //   }, [sendRequest, token, success.value]);

  const approvalTransactionRequestHandler = async (id) => {
    // const data = {
    //   entityId: id,
    // };
    // try {
    //   const responseData = await sendRequest(
    //     `${baseUrl}/admin/api/AdminAPI/ApproveTransactionsPending`,
    //     "POST",
    //     JSON.stringify(data),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //       "Ocp-Apim-Subscription-Key": "ad907e9b3ab74347aafb8a12f8ac5d28",
    //     }
    //   );
    //   setSuccess({
    //     value: responseData?.isSuccessful,
    //     message: responseData?.message,
    //   });
    //   setTabsValue(0);
    // } catch (err) {}
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("patientName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = transactionHistory.map((n) => n.patientName);
      const newSelecteds = contractDoctors.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - contractDoctors.length)
      : 0;

  // const filteredTransactions = applySortFilter(transactionHistory, getComparator(order, orderBy), filterName);

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  // const filteredTransactions = useMemo(
  //   () =>
  //     !!transactionHistory &&
  //     transactionHistory?.filter(
  //       (transaction) => transaction.orderType === filterRole
  //     ),
  //   applySortFilter(
  //     transactionHistory,
  //     getComparator(order, orderBy),
  //     filterName,
  //     filterRole
  //   )
  //   [transactionHistory, order, orderBy, filterName, filterRole]
  // );

  // useEffect(() => {
  //   if (transactionHistory?.length > 0) {
  //     setFilteredTransactions(transactionHistory);
  //   }
  // }, []);

  useEffect(() => {
    if (contractDoctors?.length > 0) {
      const arr = contractDoctors?.filter(
        (transaction) =>
          transaction?.doctorName?.toLowerCase() ===
            filterName?.toLowerCase() ||
          transaction?.doctorName
            ?.toLowerCase()
            .includes(filterName?.toLowerCase())
      );
      setFilteredDoctors(arr);
    }
  }, [contractDoctors, filterName, filterRole]);

  const isNotFound = !FilteredDoctors.length && !!filterName;

  return {
    contractDoctors,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    FilteredDoctors,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    contractPageSwitch,
    approvalTransactionRequestHandler,
  };
}

export default useContract;
