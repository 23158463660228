import React from "react";
import { Box } from "@mui/material";

function CustomTab({ text, isSelected, tabNumber, setTab }) {
  const baseStyle = {
    borderBottom: "0.1px solid #808080",
    cursor: "pointer",
    ...(isSelected
      ? {
          color: "#2CBCEF",
          fontWeight: "500",
        }
      : {}),
  };
  return (
    <Box
      onClick={() => {
        setTab(tabNumber);
      }}
      style={baseStyle}
    >
      <span
        style={
          isSelected && {
            borderBottom: "2px solid #2CBCEF",
            paddingBottom: "2px",
          }
        }
      >
        {text}
      </span>
    </Box>
  );
}

export default CustomTab;
