import { useContext } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// component
import {
  TABLE_HEAD,
  USER_TABLE_HEAD,
} from "../sections/@dashboard/patient/PatientsHelperFunctions";
import { AuthContext } from "../context/auth-context";
import ErrorModal from "../components/Modal/ErrorModal";
import usePatient from "../sections/@dashboard/patient/patientHook";
import PatientPageTable from "../sections/@dashboard/patient/PatientPageTable";
import TransitionsSnackbar from "../components/snackBar";
import LoadingBackdrop from "../components/loader";
import { a11yProps, TabPanel } from "../utils/helper";
import Empty from "../components/commonElements/empty/empty";
import TemporaryUsersTable from "../sections/@dashboard/patient/TemporaryUsersTable";

export default function PatientPage() {
  const auth = useContext(AuthContext);
  const {
    patientHistory,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredPatients,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    // NEW ONE
    temporaryUsers,
    filteredUsers,
    userSelected,
    userOrder,
    userOrderBy,
    handleUserRequestSort,
    handleUserSelectAllClick,
    handleUserClick,
    userIsNotFound,
    createTemporaryUserRequest,
    fetchTemporaryUsers,

    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
  } = usePatient(auth);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading}
        styles={{
          marginTop: "12rem",
          mx: "1.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <Container
          sx={{
            mt: 2,
          }}
        >
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Patients
            </Typography>
          </Stack> */}

          {
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="Patient tabs"
                >
                  <Tab label="Lists" {...a11yProps(0)} />
                  <Tab label="Temporary Users" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {patientHistory?.length < 1 && (
                  <Empty
                    altText="Patients list is empty"
                    title="No patient onboarded yet."
                  />
                )}
                {patientHistory?.length > 0 && (
                  <PatientPageTable
                    // id='approved'
                    selected={selected}
                    filterName={filterName}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole}
                    handleFilterByRole={handleFilterByRole}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredPatients}
                    mainList={patientHistory}
                    rowsPerPage={rowsPerPage}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRows}
                    isNotFound={isNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    open={open}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleExportToCSV={handleExportToCSV}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabsValue} index={1}>
                {temporaryUsers?.length < 1 && (
                  <Empty
                    altText="Temporary users list is empty"
                    title="No temporary users available yet."
                  />
                )}
                {temporaryUsers?.length > 0 && (
                  <TemporaryUsersTable
                    // id='approved'
                    selected={userSelected}
                    filterName={filterName}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole}
                    handleFilterByRole={handleFilterByRole}
                    order={userOrder}
                    orderBy={userOrderBy}
                    handleRequestSort={handleUserRequestSort}
                    handleSelectAllClick={handleUserSelectAllClick}
                    filteredUsers={filteredUsers}
                    mainList={temporaryUsers}
                    rowsPerPage={rowsPerPage}
                    handleClick={handleUserClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRows}
                    isNotFound={userIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    open={open}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={USER_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    createTemporaryUserRequest={createTemporaryUserRequest}
                    fetchTemporaryUsers={fetchTemporaryUsers}
                  />
                )}
              </TabPanel>
            </Card>
          }
        </Container>
      </LoadingBackdrop>
    </>
  );
}
