// import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// sections
import { DoctorListHead, DoctorListToolbar } from "./index";
// mock
// import USERLIST from '../../../_mock/user';
import TransitionsModal from "./DoctorModal";
import { useNavigate } from "react-router-dom";
// import DeactivateModal from "../../../components/Modal/Deactivate";
import DeactivateModal from "../../../components/Modal/Deactivate";

// ----------------------------------------------------------------------

export default function DoctorPageTable({
  id: tableId,
  selected,
  filterName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  rejectRequestHandler,
  deactivateRequestHandler,
  isLoading,
  tabsValue,
  specializations,
  ApproveLmdLoading,
  RejectLmdLoading,
  deactivateLmdMutationError,
  deactivateLmdLoading,
  reactivateRequestHandler,
  text,
  setText,
  refetchLoadedRejectedDoctors,
  ScoreDoctorLoading,
  AddScoreAppHandler,
  removeScoreDoctorLoading,
  removeScoreDoctorHandler,
}) {
  const [docId, setDocId] = useState(null);
  const [docUserid, setDocUserid] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [reason, setReason] = useState("");

  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const handleDeactivateOpen = () => {
    setOpenDeactivateModal(true);
    if (tabsValue === 2) {
      setText(true);
    } else {
      setText(false);
    }
  };

  const handleDeactivateClose = () => {
    setOpenDeactivateModal(false);
    setReason("");
  };

  const handleModalOpen = (doc) => {
    setSelectedDoctor(doc);
    setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);

  const specialization = mainList.map((n) => n?.areaOfSpecialization);
  const specializationsToEvaluate = [...new Set(specialization)];
  let userSpecializations = specializationsToEvaluate.map((sp) => ({
    id: sp,
    name: sp,
  }));
  let navigate = useNavigate();
  // console.log('filteredUsers', filteredUsers)

  const onDeactivateClick = () => {
    deactivateRequestHandler(
      // docId,
      selectedDoctor?.userId,
      reason,
      tableId,
      handleDeactivateClose,
      setReason
    );
  };

  const onReactivateClick = () => {
    reactivateRequestHandler(
      selectedDoctor?.userId,
      reason,
      tableId,
      handleDeactivateClose,
      setReason
    );
  };

  return (
    <>
      <Box sx={{}}>
        <DoctorListToolbar
          numSelected={selected.length}
          userSpecializations={specializations}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          filterRole={filterRole}
          onFilterRole={(event) => handleFilterByRole(event, tableId)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <DoctorListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) =>
                  handleRequestSort(event, property, tableId)
                }
                onSelectAllClick={(event) =>
                  handleSelectAllClick(event, tableId)
                }
                tabsValue={tabsValue}
              />
              <TableBody>
                {!!filteredUsers.length &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        imageUrl,
                        mdcnFolioNo,
                        yearsOfMedicalExperience,
                        areaOfSpecialization,
                        name,
                        phoneNumber,
                        userName,
                        maritalStatus,
                        stateuserRegistration,
                        statecontactInformation,
                        consultationPrice,
                        email,
                        ccrep,
                        reviews,
                        userId,
                      } = row;

                      const selectedUser =
                        !!selected && selected.indexOf(name) !== -1;
                      // console.log('row', row)
                      // console.log('tabsValue', tabsValue)

                      return (
                        <TableRow
                          hover
                          key={id}
                          // key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          // onClick={(event) => handleModalOpen(row)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) =>
                                handleClick(event, name, tableId)
                              }
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={(event) =>
                              navigate(`/doctors/${id}`, { state: row })
                            }
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={tabsValue ? name : userName}
                                src={imageUrl}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {tabsValue !== 4 ? name : userName}
                              </Typography>
                              {tabsValue === 2 && (
                                <Typography variant="subtitle2" noWrap>
                                  {userName}
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          {tabsValue === 1 ||
                            (tabsValue === 3 && (
                              <TableCell
                                align="left"
                                onClick={(event) => handleModalOpen(row)}
                              >
                                {mdcnFolioNo}
                              </TableCell>
                            ))}

                          {tabsValue === 1 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {mdcnFolioNo}
                            </TableCell>
                          )}

                          {tabsValue === 2 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {email}
                            </TableCell>
                          )}
                          {tabsValue === 1 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {consultationPrice}
                            </TableCell>
                          )}
                          {tabsValue === 3 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {consultationPrice}
                            </TableCell>
                          )}
                          {/* {
                        !tabsValue && <TableCell
                          align="left"
                          onClick={(event) => handleModalOpen(row)}
                        >
                          {reviews}
                        </TableCell>
                      } */}
                          {tabsValue !== 2 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {yearsOfMedicalExperience}
                            </TableCell>
                          )}
                          {tabsValue !== 2 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {areaOfSpecialization}
                            </TableCell>
                          )}
                          {tabsValue === 2 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {phoneNumber}
                            </TableCell>
                          )}
                          {tabsValue === 0 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {ccrep.toString()}
                            </TableCell>
                          )}
                          {tabsValue === 4 && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {id.toString()}
                            </TableCell>
                          )}
                          {/* {
                        !!tabsValue && <TableCell
                          align="left"
                          onClick={(event) => handleModalOpen(row)}
                        >
                          {statecontactInformation || stateuserRegistration}
                        </TableCell>
                      } */}
                          {/* {
                        !!tabsValue && <TableCell
                          align="left"
                          onClick={(event) => handleModalOpen(row)}
                        >
                          <Label
                            color={
                              maritalStatus === 'divorce' ? 'error' : maritalStatus === 'single' ? 'warning' : 'success'
                            }
                          >
                            {maritalStatus}
                          </Label>
                        </TableCell>
                      } */}
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                setDocId(id);
                                setDocUserid(userId);
                                setSelectedDoctor(row);
                                handleOpenMenu(event, tableId);
                              }}
                            >
                              <Iconify
                                icon={"eva:more-vertical-fill"}
                                sx={{
                                  color: "primary.main",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //IMPORTANT // count={mainList.length} //DO NOT REMOVE
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) =>
            handleChangePage(event, newPage, tableId)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, tableId)
          }
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 150,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {tabsValue === 1 && (
          <MenuItem
            onClick={(event) => {
              approvalRequestHandler(docId, tableId);
            }}
            disabled={ApproveLmdLoading}
          >
            {ApproveLmdLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  mr: 2,
                  color: "primary.main",
                }}
              />
            ) : (
              <Iconify
                icon={"eva:compass-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Approve
          </MenuItem>
        )}

        {tabsValue === 1 && (
          <MenuItem
            onClick={(event) => {
              rejectRequestHandler(docId, tableId);
            }}
            disabled={RejectLmdLoading}
          >
            {RejectLmdLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  mr: 2,
                  color: "primary.main",
                }}
              />
            ) : (
              <Iconify
                icon={"eva:close-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Reject
          </MenuItem>
        )}
        {tabsValue === 2 && (
          <MenuItem
            onClick={(event) => {
              handleDeactivateOpen();
            }}
            disabled={deactivateLmdLoading}
            sx={{
              color: "#2CBCEF",
            }}
          >
            <Iconify
              icon={"fluent-mdl2:deactivate-orders"}
              sx={{
                mr: 2,
              }}
            />
            Reactivate
          </MenuItem>
        )}
        {tabsValue !== 2 && (
          <MenuItem
            onClick={(event) => {
              navigate(`/doctors/${selectedDoctor.id}`, {
                state: selectedDoctor,
              });
            }}
            // sx={{ color: 'error.main' }}
          >
            <Iconify
              icon={"material-symbols:grid-view-rounded"}
              sx={{ mr: 2 }}
            />
            View
          </MenuItem>
        )}
        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        {tabsValue === 0 && (
          <MenuItem
            // sx={{ color: "error.main" }}
            onClick={(event) => {
              handleDeactivateOpen();
            }}
            disabled={deactivateLmdLoading}
          >
            <Iconify icon={"fluent-mdl2:deactivate-orders"} sx={{ mr: 2 }} />
            Deactivate
          </MenuItem>
        )}
        {tabsValue === 0 && (
          <MenuItem
            // sx={{ color: "#2CBCEF", fontWeight: 700}}
            onClick={(event) => {
              AddScoreAppHandler(docUserid, tableId);
            }}
            disabled={ScoreDoctorLoading}
          >
            {ScoreDoctorLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  color: "primary.main",
                  mr: 1,
                }}
              />
            ) : (
              <Iconify
                icon={"eva:compass-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Score-Doctor
          </MenuItem>
        )}
        {tabsValue === 4 && (
          <MenuItem
            // sx={{ color: "#2CBCEF", fontWeight: 700}}
            onClick={(event) => {
              removeScoreDoctorHandler(docUserid, tableId);
            }}
            disabled={removeScoreDoctorLoading}
          >
            {ScoreDoctorLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  color: "primary.main",
                  mr: 1,
                }}
              />
            ) : (
              <Iconify
                icon={"eva:compass-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Remove
          </MenuItem>
        )}
      </Popover>
      <TransitionsModal
        selectedDoctor={selectedDoctor}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        approvalRequestHandler={approvalRequestHandler}
        isLoading={ApproveLmdLoading}
        tabsValue={tabsValue}
        tableId={tableId}
      />
      <DeactivateModal
        open={openDeactivateModal}
        onClose={handleDeactivateClose}
        setReason={setReason}
        reason={reason}
        onDeactivateClick={onDeactivateClick}
        deactivateLmdMutationError={deactivateLmdMutationError}
        deactivateLmdLoading={deactivateLmdLoading}
        text={text}
        onReactivateClick={onReactivateClick}
      />
    </>
  );
}
