import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// utils
import { fCurrency } from "../../../utils/formatNumber";
// components
import Label from "../../../components/label";
import { ColorPreview } from "../../../components/color-utils";
import Iconify from "../../../components/iconify";
import { BsPlusLg } from "react-icons/bs";

// ----------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '1px solid #000',
  borderRadius: "0.4rem",
  boxShadow: 24,
  p: 4,
};

export default function TransitionssModal({
  selectedCoverage,
  openModal,
  handleModalOpen,
  handleModalClose,
}) {
  const { id } = !!selectedCoverage && selectedCoverage;
  console.log(id, "id");

  return (
    <div>
      <Modal
        aria-labelledby="canned-modal-title"
        aria-describedby="canned-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Card>
              <Stack spacing={2} sx={{ p: 3 }}>
                <Typography
                  sx={{
                    color: "#343673",
                    fontSize: "18PX",
                    fontWeight: "bold",
                  }}
                >
                  Add canned coverage
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
                >
                  <Typography sx={{ color: "#808080", fontSize: "14px" }}>
                    Title of canned coverage
                  </Typography>
                  <input
                    id="name"
                    name="cannedCoverName"
                    type="text"
                    style={{
                      width: "100%",
                      border: "1px solid grey",
                      padding: "16px 8px",
                      borderRadius: "8px",
                      outline: "none",
                    }}
                    // label="License Number"
                  />
                </Box>
                <Box
                  sx={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1)",
                  }}
                  className="canBtn"
                  onClick={() => console.log("add image")}
                >
                  {" "}
                  <BsPlusLg
                    style={{ color: "#2CBCEF", fontWeight: "bold" }}
                    size="50px"
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#4A4A4A",
                    fontWeight: "500",
                    alignSelf: "center",
                  }}
                >
                  Add Image
                </Typography>

                <button
                  style={{
                    color: "white",
                    width: "100%",
                    padding: "12px",
                    borderRadius: "8px",
                    backgroundColor: "#2CBCEF",
                    margin: "10px 0",
                    border: "none",
                    cursor: "pointer",
                  }}
                  className="canBtn"
                  onClick={() => console.log("save image")}
                >
                  Save
                </button>
              </Stack>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
