import React from "react";
import searchIcon from "../icons/searchIcon.svg";

function FilterInput({ handleSearch, filter, placeholder, wFull }) {
  return (
    <div
      className={`flex justify-start items-center border border-secondaryLight gap-2 py-2 px-6 my-4 bg-white rounded-[8px] ${
        wFull ? "w-[90%] md:w-4/5" : "w-[80%] md:w-1/2"
      }`}
    >
      <img src={searchIcon} alt="search" />
      <input
        type="search"
        id="search"
        value={filter}
        onChange={handleSearch}
        placeholder={placeholder}
        className="outline-none w-full"
      />
    </div>
  );
}

export default FilterInput;
