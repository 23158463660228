import * as React from "react"

import { cn } from "src/components/shadcn/utils"

const Input = React.forwardRef(({ className, type, isNumeric, ...props }, ref) => {
  const handleKeyPress = (event) => {
    // Prevent non-numeric input
    if (isNumeric && !/[0-9.,]/.test(event.key)) {
       event.preventDefault();
    }
 };
  return (
    <input
    type={type}
    className={cn(
      "flex h-10 w-full rounded-md border border-shadcn-input bg-shadcn-background px-3 py-2 text-sm ring-offset-shadcn-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-shadcn-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-shadcn-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
       className
    )}
    ref={ref}
    onKeyPress={handleKeyPress}
    {...props}
 />
  );
})
Input.displayName = "Input"

export { Input }
