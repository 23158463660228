import React from "react";
import { useState, useEffect } from "react";
import apiClient from "../http/common";

const useSpecialization = () => {
  useEffect(() => {
    getSpecializations();
  }, []);

  const [specializations, setSpecializations] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSpecializations = () => {
    setLoading(true);
    apiClient
      .get("/Doctor/api/Doctors/specs")
      .then((res) => {
        setLoading(false);
        setSpecializations(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    specializations,
  };
};

export default useSpecialization;
