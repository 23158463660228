import { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../components/InputField";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../components/AutoComplete";
import WeekdaysCheckbox from "../components/commonElements/WeekdayCheck";

const EditContract = () => {
  const [days, setDays] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { register, formState, watch, setValue } = useForm({
    defaultValues: {
      contractName: "",
      availabilityPeriod: "",
      availabilityTime: "",
      consultationDuration: "",
      noticePeriod: "",
      payPerHour: "",
      consultationFee: "",
      numberOfInstances: "",
      startDate: "",
      endDate: "",
      areaOfSpecialization: [],
    },
    mode: "all",
  });
  const { errors } = formState;
  const navigate = useNavigate();

  const handleMultipleSelect = (e) => {
    const {
      target: { value },
    } = e;
    setDays(typeof value === "string" ? value.split(",") : value);
  };
  return (
    <>
      <Box
        sx={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "50%" }}>
          <div style={{ marginBottom: "0.8rem" }}>
            <p
              style={{ color: "#4A4A4A", fontWeight: "700", fontSize: "18px" }}
            >
              New Contract Type
            </p>
            <p style={{ color: "#808080", fontSize: "14px" }}>
              Setup for Contract Doctors
            </p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            <div style={{ width: "100%" }}>
              <InputField
                label="Contract Name"
                id="contractName"
                name="contractName"
                type="text"
                boldLabel="yes"
                asteriks
                placeholder="Enter contract name"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.contractName?.message}
              </small>
            </div>
            <WeekdaysCheckbox />
            {/* <MultipleSelectCheckmarks
              onChange={handleMultipleSelect}
              value={days}
              asteriks={true}
            /> */}
            <div style={{ width: "100%", marginTop: "1rem" }}>
              <InputField
                label="Available Time"
                id="availabilityTime"
                name="availabilityTime"
                type="text"
                boldLabel="yes"
                asteriks
                placeholder="Enter availability time"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.availabilityTime?.message}
              </small>
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Consultationn duration (in minutes)"
                id="consultationDuration"
                name="consultationDuration"
                type="text"
                boldLabel="yes"
                asteriks
                placeholder="Enter consultation duration"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.consultationDuration?.message}
              </small>
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Notice period (in days)"
                id="noticePeriod"
                name="noticePeriod"
                type="text"
                boldLabel="yes"
                asteriks
                placeholder="Enter Notice Period"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.noticePeriod?.message}
              </small>
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Pay per hour"
                id="payPerHour"
                name="payPerHour"
                type="text"
                asteriks
                boldLabel="yes"
                placeholder="Enter pay per hour"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.payPerHour?.message}
              </small>
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Consultation Fee"
                id="consultationFee"
                name="consultationFee"
                asteriks
                type="text"
                boldLabel="yes"
                placeholder="Enter consultation fee"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.consultationFee?.message}
              </small>
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Number of Instances"
                id="numberOfInstances"
                name="numberOfInstances"
                asteriks
                type="text"
                boldLabel="yes"
                placeholder="Enter number of instances"
                register={register}
              />
              <small
                style={{ color: "red", position: "relative", top: "-1rem" }}
              >
                {errors.numberOfInstances?.message}
              </small>
            </div>

            <p style={{ paddingLeft: "2px", marginBottom: "-6px" }}>Duration</p>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "2rem",
              }}
            >
              <div style={{ width: "100%" }}>
                <InputField
                  label="Start Date"
                  id="startDate"
                  name="startDate"
                  type="date"
                  boldLabel="yes"
                  asteriks
                  register={register}
                />
                <small
                  style={{ color: "red", position: "relative", top: "-1rem" }}
                >
                  {errors.startDate?.message}
                </small>
              </div>
              <div style={{ width: "100%" }}>
                <InputField
                  label="End Date"
                  id="endDate"
                  name="endDate"
                  type="date"
                  boldLabel="yes"
                  asteriks
                  register={register}
                />
                <small
                  style={{ color: "red", position: "relative", top: "-1rem" }}
                >
                  {errors.endDate?.message}
                </small>
              </div>
            </div>
            <AutoComplete
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "2rem 0",
              }}
            >
              <p
                style={{
                  fontweight: "500",
                  color: "black",
                  textDecoration: "underline",
                  paddingBottom: "2px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/contract")}
              >
                Cancel
              </p>
              <button
                style={{
                  backgroundColor: "#2CBCEF",
                  color: "white",
                  borderRadius: "4px",
                  padding: "14px 12px",
                  fontSize: "12px",
                  width: "193px",
                  border: "none",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default EditContract;
