import { Box, Typography } from "@mui/material";
import React from "react";

const MetricCard = () => {
  return (
    <>
      <Box
        sx={{
          width: "190px",
          height: "100px",
          textAlign: "center",
          padding: "16px 8px",
          backgroundColor: "rgba(44, 188, 239, 0.05)",
          borderRadius: "8px",
          boxShadow: "0 2px 12px rgba(192, 192, 192, 0.25)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ color: "#808080", fontSize: "14px" }}>
          Total available hours
        </Typography>
        <Typography>15000</Typography>
      </Box>
    </>
  );
};

export default MetricCard;
