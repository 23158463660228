import { filter } from "lodash";

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: "sponsorName", label: "Name", alignRight: false },
  { id: "createdAt", label: "Date of Reg.", alignRight: false },
  { id: "pheneNumber", label: "Phone", alignRight: false },
  { id: "email", label: "Email Address", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export function applySortFilter(list, comparator, query, category) {
  const stabilizedThis = list.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if (query) {
    return filter(result, (_user) => {
      return (
        _user.sponsorName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      );
    });
  }
  return result;
}
