import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import FormikSubmitButton from "../../../components/buttons/FormikSubmitButton";
import ResetButton from "../../../components/buttons/ResetButton";
import MySelect from "../../../components/commonElements/select/MySelect";
import {
  FacilityTypes,
  FORM_VALIDATION,
  INITIAL_FORM_STATE,
  INITIAL_FORM_STATES,
} from "./FacilityHelperFunctions";
import FacilityFormFields from "./FacilityFormFields";
import MyAutocomplete from "../../../components/commonElements/Autocomplete/MyAutocomplete";

function FacilityForm({
  facilityCreationHandler,
  approvedPartners,
  createFacilityLoading,
  contactPhone,
  setContactPhone,
}) {
  let location = useLocation();
  const [labPreviewUrl, setLabPreviewUrl] = useState();
  const [pharmacyPreviewUrl, setPharmacyPreviewUrl] = useState();


  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 3 }}>
        <Container maxWidth="sm">
          <Typography variant="h4" gutterBottom>
            Create Facility
          </Typography>

          <Formik
            initialValues={{
              ...INITIAL_FORM_STATES,
              // partnerId: location?.state?.id ? location?.state?.id : '',
              partnerId: location?.state?.id
                ? {
                    label: location?.state?.businessName,
                    value: location?.state?.id,
                  }
                : ``,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={facilityCreationHandler}
          >
            <Form>
              <Stack spacing={3} sx={{ pt: 3, pb: 8 }}>
                <MyAutocomplete
                  id="partnerId"
                  name="partnerId"
                  label="Select Partner"
                  options={approvedPartners}
                />
                <MySelect
                  id="facilityType"
                  name="facilityType"
                  label="Facility Type"
                  options={FacilityTypes}
                  defaultValue={1}
                  // defaultValue="lab"
                />
                {/* <MyAutocomplete
                  id="country"
                  name="country"
                  label="Country"
                  options={countryList}
                /> */}
                <FacilityFormFields
                  pharmacyPreviewUrl={pharmacyPreviewUrl}
                  setPharmacyPreviewUrl={setPharmacyPreviewUrl}
                  labPreviewUrl={labPreviewUrl}
                  setLabPreviewUrl={setLabPreviewUrl}
                  phone={contactPhone}
                  setPhone={setContactPhone}
                />

                <ResetButton
                  onClick={(e) => {
                    setLabPreviewUrl("");
                    setPharmacyPreviewUrl("");
                  }}
                />
                <FormikSubmitButton
                  queryisloading={createFacilityLoading.toString()}
                  sx={{ mt: 8 }}
                >
                  Create Facility
                </FormikSubmitButton>
              </Stack>
            </Form>
          </Formik>
        </Container>
      </Box>
    </div>
  );
}

export default FacilityForm;
