import { useContext } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// component
import {
  APPOINTMENT_TABLE_HEAD,
  CONSULTATION_TABLE_HEAD,
} from "../sections/@dashboard/appointment/AppointmentHelperFunction";
import { AuthContext } from "../context/auth-context";
import ErrorModal from "../components/Modal/ErrorModal";
import useAppointment from "../sections/@dashboard/appointment/AppointmentHook";
import AppointmentTable from "../sections/@dashboard/appointment/AppointmentTable";
import TransitionsSnackbar from "../components/snackBar";
import LoadingBackdrop, { NewLoadingBackdrop } from "../components/loader";
import { a11yProps, TabPanel } from "../utils/helper";
import Empty from "../components/commonElements/empty/empty";

const Appointment = () => {
  const auth = useContext(AuthContext);
  const {
    appointments,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isLoading,
    error,
    clearError,
    open,
    setOpen,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredAppointments,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    csvLink,
    //custom csv
    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
    openCsv,
    setOpenCsv,

    //////

    unsuccessfulAppointments,
    unsuccessfulConsultations,
    unsuccessfulAppointmentsError,
    unsuccessfulAppointmentsIsError,
    unsuccessfulAppointmentsLoading,
    unsuccessfulConsultationsError,
    unsuccessfulConsultationsIsError,
    unsuccessfulConsultationsLoading,
    appointmentsisNotFound,
    consultationsisNotFound,
    emptyRowsUnsuccessfulAppointment,
    emptyRowsUnsuccessfulConsultations,
    // filteredAppointments,
    filteredConsultations,
  } = useAppointment(auth);
  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading}
        styles={{
          marginTop: "12rem",
          mx: "1.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <Container
          sx={{
            mt: 2,
          }}
        >
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Consultations
            </Typography>
          </Stack> */}

          {
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="Appointment tabs"
                >
                  <Tab label="Unsuccessful Appointments" {...a11yProps(0)} w />
                  <Tab label="Unsuccessful Consultations" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {unsuccessfulAppointments?.length < 1 && (
                  <Empty
                    altText="Appointments list is empty"
                    title="No unsuccessful appointments yet."
                  />
                )}
                {unsuccessfulAppointments?.length > 0 && (
                  <AppointmentTable
                    id="unsuccessfulAppointments"
                    facilityName="unsuccessfulAppointments"
                    // id='approved'
                    selected={selected.unsuccessfulAppointments}
                    filterName={filterName.unsuccessfulAppointments}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.unsuccessfulAppointments}
                    handleFilterByRole={handleFilterByRole}
                    order={order.unsuccessfulAppointments}
                    orderBy={orderBy.unsuccessfulAppointments}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredAppointments}
                    mainList={unsuccessfulAppointments}
                    rowsPerPage={rowsPerPage.unsuccessfulAppointments}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsUnsuccessfulAppointment}
                    isNotFound={appointmentsisNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.unsuccessfulAppointments}
                    open={open.unsuccessfulAppointments}
                    openCsv={openCsv}
                    setOpenCsv={setOpenCsv}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={APPOINTMENT_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    csvLink={csvLink}
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleExportToCSV={handleExportToCSV}
                  />
                )}
                {unsuccessfulAppointmentsIsError ? (
                  <div>
                    An error occurred: {unsuccessfulAppointments.message}
                  </div>
                ) : null}
                <NewLoadingBackdrop
                  open={unsuccessfulAppointmentsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={1}>
                {unsuccessfulConsultations?.length < 1 && (
                  <Empty
                    altText="Consultations list is empty"
                    title="No unsuccesful consultations yet."
                  />
                )}
                {unsuccessfulConsultations?.length > 0 && (
                  <AppointmentTable
                    // id='approved'
                    id="unsuccessfulConsultations"
                    facilityName="unsuccessfulConsultations"
                    selected={selected.unsuccessfulConsultations}
                    filterName={filterName.unsuccessfulConsultations}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.unsuccessfulConsultations}
                    handleFilterByRole={handleFilterByRole}
                    order={order.unsuccessfulConsultations}
                    orderBy={orderBy.unsuccessfulConsultations}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredConsultations}
                    mainList={unsuccessfulConsultations}
                    rowsPerPage={rowsPerPage.unsuccessfulConsultations}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRows}
                    isNotFound={isNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.unsuccessfulConsultations}
                    open={open.unsuccessfulConsultations}
                    openCsv={openCsv}
                    setOpenCsv={setOpenCsv}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={CONSULTATION_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    csvLink={csvLink}
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleExportToCSV={handleExportToCSV}
                  />
                )}
                {unsuccessfulConsultationsIsError ? (
                  <div>
                    An error occurred: {unsuccessfulConsultationsError.message}
                  </div>
                ) : null}
                <NewLoadingBackdrop
                  open={unsuccessfulConsultationsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
            </Card>
          }
        </Container>
      </LoadingBackdrop>
    </>
  );
};

export default Appointment;
