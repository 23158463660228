// @mui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import Iconify from '../components/iconify';
// sections
import ErrorModal from '../components/Modal/ErrorModal';
import { a11yProps, TabPanel } from '../utils/helper';
import AdminForm from '../sections/@dashboard/admin/AdminForm';
import useAdmin from '../sections/@dashboard/admin/AdminHook';
import AdminPageTable from '../sections/@dashboard/admin/AdminPageTable';
import LoadingBackdrop from '../components/loader';
import TransitionsSnackbar from '../components/snackBar';
import Empty from '../components/commonElements/empty/empty';
import { TABLE_HEAD } from '../sections/@dashboard/admin/AdminHelperFunctions';
import { AuthContext } from '../context/auth-context';
import { useContext } from 'react';
import BackButton from '../layouts/backButton';

export default function AdminUserPage() {
  const auth = useContext(AuthContext);
  const {
    admins, loadedPermissions,
    isLoading, error, clearError,
    open, page, order,
    selected,
    orderBy, filterName, filterRole, rowsPerPage, tabsValue,
    handleTabsValueChange, handleOpenMenu, handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredAdmins,
    handleFilterByRole, handleFilterByName, handleChangeRowsPerPage,
    handleChangePage, handleClick, handleRequestSort,
    success, handleSnackbarClose,
    switchList, switchForm,
    openCreatePage, AdminPageSwitch, adminCreationHandler
  } = useAdmin(auth)

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading && !openCreatePage}
        styles={{
          marginTop: '12rem',
          mx: '1.5rem',
          borderRadius: '0.75rem',
        }}
      >
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={openCreatePage ? "space-between" : "flex-end"}
            mb={3}
            mt={2}
          >
            {/* <Typography variant="h4" gutterBottom>
              Admin User
            </Typography> */}
            {
              openCreatePage && <BackButton onClick={switchList} />
            }
            {
              !openCreatePage && <Button
                disabled={!!isLoading}
                variant="contained"
                sx={{
                  color: '#FFFFFF'
                }}
                startIcon={
                  <Iconify
                    icon="eva:plus-fill"
                  />
                }
                // onClick={AdminPageSwitch}
                onClick={switchForm}
              >
                {/* {openCreatePage ? 'Admin User List' : 'Create Admin User'} */}
                Create Admin User
              </Button>
            }
          </Stack>

          {
            !openCreatePage && <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Admin" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {
                  admins?.length < 1 && <Empty
                    altText='Admin list is empty'
                    title='No admin created yet.'
                  />
                }
                {
                  admins?.length > 0 && <AdminPageTable
                    selected={selected}
                    filterName={filterName}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole}
                    handleFilterByRole={handleFilterByRole}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredAdmins}
                    mainList={admins}
                    rowsPerPage={rowsPerPage}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRows}
                    isNotFound={isNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    open={open}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                  />
                }
              </TabPanel>
            </Card>
          }
          {
            openCreatePage && <Card>
              <AdminForm
                loadedPermissions={loadedPermissions}
                adminCreationHandler={adminCreationHandler}
              />
            </Card>
          }
        </Container>
      </LoadingBackdrop>
    </>
  );
}
