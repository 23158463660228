import { filter } from "lodash";

// ----------------------------------------------------------------------
export const APPOINTMENT_TABLE_HEAD = [
  { id: "patientName", label: "Patient", alignRight: false },
  { id: "doctorName", label: "Doctor", alignRight: false },
  { id: "patientPhone", label: "Patient Phone", alignRight: false },
  { id: "price", label: "Consultation Price", alignRight: false },
  { id: "appointmentDate", label: "Appointment Date", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
  // { id: 'Action', label: 'Action', alignRight: false },
  { id: "" },
];

export const CONSULTATION_TABLE_HEAD = [
  { id: "patientName", label: "Patient", alignRight: false },
  { id: "doctorName", label: "Doctor", alignRight: false },
  { id: "patientPhone", label: "Patient Phone", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "data", label: "Date", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
  // { id: 'Action', label: 'Action', alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export function kklapplySortFilter(
  normalisedArray,
  comparator,
  query,
  category
) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  // if ( query) {
  //   return filter(result, (_user) => {
  //     return _user.patientName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
  //   });
  // }
  if ((category === "All" || !category) && query) {
    return filter(result, (_user) => {
      return _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1;
    });
  }
  if (category && !query) {
    if (category === "All") return result;
    return filter(
      result,
      (_user) =>
        _user?.areaOfSpecialization?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
    );
  }
  if (category !== "All" && query) {
    return filter(result, (_user) => {
      return (
        _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
        _user?.areaOfSpecialization?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
      );
    });
  }
  return result;
}

export function applySortFilter(normalisedArray, comparator, query, category) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if (query) {
    return filter(result, (_user) => {
      return (
        _user?.patientName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1 ||
        _user?.doctorName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      );
    });
  }
  return result;
}
