import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import {
  applySortFilter,
  userApplySortFilter,
} from "./PatientsHelperFunctions";
import { baseUrl, getComparator } from "../../../utils/helper";
import { toast } from "react-toastify";
import { reactAppSubscriptionKey } from "../../../http/common";

function usePatient(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const PatientPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);

  const [patientHistory, setPatientHistory] = useState();
  const [temporaryUsers, setTemporaryUsers] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    const fetchPatientHistory = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetAllPatientsDetails`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        // console.log('approved patients', responseData.data)
        setPatientHistory(responseData?.data.data);
      } catch (err) {}
    };
    fetchPatientHistory();
    fetchTemporaryUsers();
  }, [sendRequest, token, success.value]);

  const fetchTemporaryUsers = async () => {
    try {
      const responseData = await sendRequest(
        `${baseUrl}/patient/api/Admin/GetTemporaryUsers`,
        undefined,
        undefined,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      setTemporaryUsers(responseData?.data);
    } catch (err) {}
  };

  const patientCreationHandler = async (values, onSubmitProps) => {
    console.log("values", values);

    try {
      const formData = new FormData();
      formData.append("Email", values.Email);
      formData.append("BusinessName", values.BusinessName);
      formData.append("PhoneNumber", values.PhoneNumber);
      formData.append("Address", values.Address);
      formData.append("Logo", values.Logo);

      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/CreatePatient`,
        "POST",
        formData,
        {
          // 'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData)
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      PatientPageSwitch();
      setTabsValue(1);
    } catch (err) {}
  };

  const approvalPatientRequestHandler = async (id) => {
    const data = {
      entityId: id,
    };
    try {
      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/ApprovePatientsPending`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData)
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      setTabsValue(0);
      // history.push('/');
    } catch (err) {}
  };

  const createTemporaryUserRequest = async (id) => {
    try {
      const responseData = await sendRequest(
        `${baseUrl}/patient/api/Admin/CreateUserInAuthService?userId=${id}`,
        "POST",
        undefined,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      if (responseData.status === 201) {
        toast.success("User created successfully", {
          position: "top-center",
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("patientName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  //////New

  const [userOrder, setuserOrder] = useState("asc");

  const [userSelected, setUserSelected] = useState([]);

  const [userOrderBy, setUserOrderBy] = useState("firstName");

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = patientHistory.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - patientHistory.length)
      : 0;

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const filteredPatients = useMemo(
    () =>
      !!patientHistory &&
      applySortFilter(
        patientHistory,
        getComparator(order, orderBy),
        filterName,
        filterRole
      ),
    [patientHistory, order, orderBy, filterName, filterRole]
  );

  const filteredUsers = useMemo(
    () =>
      !!temporaryUsers &&
      userApplySortFilter(
        temporaryUsers,
        getComparator(userOrder, userOrderBy),
        filterName
      ),
    [temporaryUsers, userOrder, userOrderBy, filterName]
  );

  const handleUserRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setuserOrder(isAsc ? "desc" : "asc");
    setUserOrderBy(property);
  };

  const handleUserSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = temporaryUsers.map((n) => n.id);
      setUserSelected(newSelecteds);
      return;
    }
    setUserSelected([]);
  };

  const handleUserClick = (event, name) => {
    const selectedIndex = userSelected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(userSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(userSelected.slice(1));
    } else if (selectedIndex === userSelected.length - 1) {
      newSelected = newSelected.concat(userSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        userSelected.slice(0, selectedIndex),
        userSelected.slice(selectedIndex + 1)
      );
    }
    setUserSelected(newSelected);
  };

  const isNotFound = !filteredPatients.length && !!filterName;

  const userIsNotFound = !filteredUsers.length && !!filterName;


// CSV EXPORT
  const handleExportToCSV = () => {
    const arr = filterDataByDateRange(patientHistory, startDate, endDate);
    const csvRows = [];
    // Add header row
    const headers = Object.keys(arr?.[0]);
    csvRows.push(headers.join(","));
    // Add data rows
    for (const row of arr) {
      const values = headers.map((header) => {
        let value = row[header];
        // Check if the header is 'startDate' or 'endDate' and format accordingly
        if (header === "startDate" || header === "endDate") {
          const datePickerDate = new Date(value);
          const formattedDate = datePickerDate.toISOString().substring(0, 10); // Convert to "yyyy-mm-dd" format
          value = formattedDate;
        }
        if (header === "startTime" || header === "endTime") {
          const timeOnly = value.split("T")[1];
          const [hours, minutes] = timeOnly.split(":");
          const formattedTime = `${parseInt(hours, 10) % 12}:${minutes} ${
            parseInt(hours, 10) < 12 ? "a.m." : "p.m."
          }`;
          value = formattedTime;
        }
        return value;
      });
      csvRows.push(values.join(","));
    }
    // Create a Blob containing the CSV data
    const csvData = csvRows.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    // Create a URL for the Blob and trigger a download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Patient_Data.csv";
    a.click();
    window.URL.revokeObjectURL(url);

    setStartDate("");
    setEndDate("");
  };

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;

    if (type === "start") {
      setStartDate(selectedDate);
    } else if (type === "end") {
      setEndDate(selectedDate);
    }
  };

  const filterDataByDateRange = (data, startDate, endDate) => {
    if (startDate && endDate) {
      const filtered = data.filter((item) => {
        const itemDate = new Date(item.startDate); // Use the correct property name
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      });
      return filtered;
    } else {
      return data;
    }
  };

  return {
    patientHistory,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredPatients,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    patientCreationHandler,
    success,
    handleSnackbarClose,
    openCreatePage,
    PatientPageSwitch,
    approvalPatientRequestHandler,
    //////////
    temporaryUsers,
    filteredUsers,
    userOrder,
    userOrderBy,
    handleUserRequestSort,
    handleUserSelectAllClick,
    handleUserClick,
    userSelected,
    userIsNotFound,
    createTemporaryUserRequest,
    fetchTemporaryUsers,

    //custom CSV
    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
  };
}

export default usePatient;
