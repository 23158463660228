import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../../components/shadch/ui/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/shadch/ui/ui/dialog";
import { Button } from "../../../components/shadch/ui/ui/button";
import { Plus } from "lucide-react";
import { ScrollArea } from "../../../components/shadch/ui/ui/scroll-area";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/shadch/ui/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/shadch/ui/ui/select";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import apiClient from "../../../http/common";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useQuery } from "react-query";

const formSchema = z.object({
  planName: z.string().min(1, { message: "Plan name is required" }),
});

const AddNewHealthPlan = () => {
  const [productData, setProductData] = useState([]);
  // const fileInputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const form = useForm({
    formSchema: zodResolver(formSchema),
    defaultValues: {
      planName: "",
      productId: "",
    },
  });

  useEffect(() => {
    apiClient
      .get(`/sponsor/GetHeirsProduct`)
      .then((response) => {
        setProductData(response?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    const payload = {
      planName: data.planName,
      sponsorId: companyId,
      productId: data.productId,
    };

    apiClient
      .post(`/sponsor/CreateCustomPlan`, payload)
      .then((response) => {
        setIsLoading(false);
        toast.info(response.data.message, {
          position: "top-center",
          hideProgressBar: false,
          autoClose: 2000,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog
        className="bg-white"
        isOpen={open}
        onDismiss={() => setOpen(false)}
      >
        <DialogTrigger asChild>
          <Button className="bg-sky-500 text-white h-12 px-10">
            {" "}
            <Plus className="mr-2" /> Add Plan
          </Button>
        </DialogTrigger>

        <DialogContent className="max-w-2xl">
          <Form {...form}>
            <form
              className="[&_label]:font-semibold"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <DialogHeader>
                <DialogTitle className="text-left px-7 text-2xl text-blue-900">
                  Add Plan
                </DialogTitle>
              </DialogHeader>
              <ScrollArea className="h-auto">
                <div id="form-fields" className="grid gap-3 px-3 mt-5">
                  <FormField
                    control={form.control}
                    name="planName"
                    render={({ field }) => (
                      <FormItem variant="customOutlined">
                        <FormLabel>Plan Name</FormLabel>
                        <FormControl>
                          <Select
                            defaultValue={field.value}
                            value={field.value.productId}
                            onValueChange={(value) => {
                              const selectedProduct = productData.find((item) => item.productId === value);
                                form.setValue("planName", selectedProduct.productName);
                                form.setValue("productId", selectedProduct.productId);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select Plan" />
                            </SelectTrigger>
                            <SelectContent>
                              {productData.map((data) => (
                                <SelectItem
                                  key={data.productId}
                                  value={data.productId}
                                  className="grid grid-col"
                                >
                                  <p>{data.productName}</p>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="productId"
                    render={({ field }) => (
                      <FormItem variant="customOutlined">
                        <FormLabel>Plan ID</FormLabel>
                        <FormControl>
                          <Input
                          disabled={true}
                          className="font-semibold"
                            placeholder="e.g heirs surgery care plan"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </ScrollArea>
              <div className="text mt-2 px-5">
                <button
                  disabled={isLoading}
                  type="submit"
                  className={`text-white ${
                    isLoading ? "bg-[#76d7fa]" : "bg-[#2CBCEF]"
                  } w-full py-3 rounded`}
                >
                  {isLoading ? (
                    <div className=" flex items-center justify-center">
                      {" "}
                      <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                      Submitting...
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNewHealthPlan;
