import { useState, useCallback, useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { baseUrl } from '../utils/helper';
import { useHttpClient } from './http-hook';
import { reactAppSubscriptionKey } from '../http/common';

export const useUser = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [userDetails, setUserDetails] = useState();
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  useEffect(() => {
    const fetchAdminDetails = async () => {
      const data = {
        apiKey: 'ccl_33QWERTYUIOP&ASDFGHJKL&ZXCVBNM',
      };
      try {
        const responseData = await sendRequest(
          `${baseUrl}/authentication/api/Users/GetUserDetails`,
          'POST',
          JSON.stringify(data),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('fetchAdminDetails', responseData?.data?.firstName)
        // const docs = normalisedArrayFunction(responseData?.data)
        setUserDetails(responseData?.data);
        localStorage.setItem(
          'cloudClinicAdminUserName',
          JSON.stringify(responseData?.data?.firstName)
        );
      } catch (err) { }
    };
    fetchAdminDetails();
  }, [sendRequest, token]);

  return {
    userDetails
  };
};
