import { useContext } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// components
import Iconify from "../components/iconify";
import {
  APPROVED_LAB_TABLE_HEAD,
  APPROVED_PHARMACY_TABLE_HEAD,
  UNAPPROVED_LAB_TABLE_HEAD,
  UNAPPROVED_PHARMACY_TABLE_HEAD,
  APPROVED_HOSPITAL_TABLE_HEAD,
  UNAPPROVED_HOSPITAL_TABLE_HEAD,
} from "../sections/@dashboard/facility/FacilityHelperFunctions";
import { AuthContext } from "../context/auth-context";
import ErrorModal from "../components/Modal/ErrorModal";
import useFacility from "../sections/@dashboard/facility/facilityHook";
import FacilityPageTable from "../sections/@dashboard/facility/FacilityPageTable";
import TransitionsSnackbar from "../components/snackBar";
import LoadingBackdrop, { NewLoadingBackdrop } from "../components/loader";
import { a11yProps, TabPanel } from "../utils/helper";
import FacilityForm from "../sections/@dashboard/facility/FacilityForm";
import Empty from "../components/commonElements/empty/empty";
import BackButton from "../layouts/backButton";

export default function FacilityPage() {
  const auth = useContext(AuthContext);
  const {
    approvedPharmacies,
    unApprovedPharmacies,
    approvedLabs,
    unApprovedLabs,
    approvedHospitals,
    unApprovedHospitals,
    unApprovedPharmaciesLoading,
    unApprovedPharmaciesError,
    unApprovedPharmaciesIsError,
    approvedPharmaciesLoading,
    approvedPharmaciesError,
    approvedPharmaciesIsError,
    unApprovedLabsLoading,
    unApprovedLabsError,
    unApprovedLabsIsError,
    approvedLabsLoading,
    approvedLabsError,
    approvedLabsIsError,
    unApprovedHospitalsLoading,
    unApprovedHospitalsError,
    unApprovedHospitalsIsError,
    approvedHospitalsLoading,
    approvedHospitalsError,
    approvedHospitalsIsError,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    facilityCreationHandler,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    approvedPharmaciesIsNotFound,
    unApprovedPharmaciesIsNotFound,
    approvedLabsIsNotFound,
    unApprovedLabsIsNotFound,
    approvedHospitalsIsNotFound,
    unApprovedHospitalsIsNotFound,
    handleSelectAllClick,
    emptyRowsApprovedPharmacies,
    emptyRowsUnApprovedPharmacies,
    emptyRowsApprovedLabs,
    emptyRowsUnApprovedLabs,
    emptyRowsApprovedHospitals,
    emptyRowsUnApprovedHospitals,
    filteredApprovedPharmacies,
    filteredUnApprovedPharmacies,
    filteredApprovedLabs,
    filteredUnApprovedLabs,
    filteredApprovedHospitals,
    filteredUnapprovedHospitals,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    switchList,
    switchForm,
    approveFacilityRequestHandler,
    approvedPartners,
    ApproveFacilityLoading,
    createFacilityLoading,
    contactPhone,
    setContactPhone,
  } = useFacility(auth);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading && !openCreatePage}
        styles={{
          marginTop: "12rem",
          mx: "1.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={openCreatePage ? "space-between" : "flex-end"}
            mb={3}
            mt={2}
          >
            {openCreatePage && <BackButton onClick={switchList} />}
            {!openCreatePage && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: "#FFFFFF",
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  switchForm();
                }}
              >
                Create Facility
              </Button>
            )}
          </Stack>

          {!openCreatePage && (
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="Facilities tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Approved Pharmacies" {...a11yProps(0)} />
                  <Tab label=" Unapproved Pharmacies" {...a11yProps(1)} />
                  <Tab label="Approved Labs" {...a11yProps(2)} />
                  <Tab label=" Unapproved Labs" {...a11yProps(3)} />
                  <Tab label="Approved Hospitals" {...a11yProps(4)} />
                  <Tab label=" Unapproved Hospitals" {...a11yProps(5)} />
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {approvedPharmacies?.length < 1 && (
                  <Empty
                    altText="Pharmacies list is empty"
                    title="No approved pharmacy found, please approve a pharmacy from the unapproved pharmacy list"
                  />
                )}
                {approvedPharmacies?.length > 0 && (
                  <FacilityPageTable
                    title="Pharmacies"
                    id="approvedPharmacy"
                    facilityName="pharmacyName"
                    selected={selected.approvedPharmacy}
                    filterName={filterName.approvedPharmacy}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.approvedPharmacy}
                    handleFilterByRole={handleFilterByRole}
                    order={order.approvedPharmacy}
                    orderBy={orderBy.approvedPharmacy}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredApprovedPharmacies}
                    mainList={approvedPharmacies}
                    rowsPerPage={rowsPerPage.approvedPharmacy}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsApprovedPharmacies}
                    isNotFound={approvedPharmaciesIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.approvedPharmacy}
                    open={open.approvedPharmacy}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={APPROVED_PHARMACY_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                  />
                )}
                {approvedPharmaciesIsError ? (
                  <div>
                    An error occurred: {approvedPharmaciesError.message}
                  </div>
                ) : null}
                <NewLoadingBackdrop
                  open={approvedPharmaciesLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={1}>
                {unApprovedPharmacies?.length < 1 && (
                  <Empty
                    altText="Unapproved pharmacy list is empty"
                    title="No unapproved pharmacy found, please create a pharmacy"
                  />
                )}
                {unApprovedPharmacies?.length > 0 && (
                  <FacilityPageTable
                    id="unApprovedPharmacy"
                    title="Pharmacies"
                    facilityName="pharmacyName"
                    selected={selected.unApprovedPharmacy}
                    filterName={filterName.unApprovedPharmacy}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.unApprovedPharmacy}
                    handleFilterByRole={handleFilterByRole}
                    order={order.unApprovedPharmacy}
                    orderBy={orderBy.unApprovedPharmacy}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredUnApprovedPharmacies}
                    mainList={unApprovedPharmacies}
                    rowsPerPage={rowsPerPage.unApprovedPharmacy}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsUnApprovedPharmacies}
                    isNotFound={unApprovedPharmaciesIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.unApprovedPharmacy}
                    open={open.unApprovedPharmacy}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={UNAPPROVED_PHARMACY_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    approvalRequestHandler={approveFacilityRequestHandler}
                    ApproveFacilityLoading={ApproveFacilityLoading}
                  />
                )}
                {unApprovedPharmaciesIsError ? (
                  <div>
                    An error occurred: {unApprovedPharmaciesError.message}
                  </div>
                ) : null}
                <NewLoadingBackdrop
                  open={unApprovedPharmaciesLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={2}>
                {approvedLabs?.length < 1 && (
                  <Empty
                    altText="Laboratrory list is empty"
                    title="No approved laboratrory found, please approve a laboratrory from the unapproved laboratrory list"
                  />
                )}
                {approvedLabs?.length > 0 && (
                  <FacilityPageTable
                    id="approvedLab"
                    title="Labs"
                    facilityName="labName"
                    selected={selected.approvedLab}
                    filterName={filterName.approvedLab}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.approvedLab}
                    handleFilterByRole={handleFilterByRole}
                    order={order.approvedLab}
                    orderBy={orderBy.approvedLab}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredApprovedLabs}
                    mainList={approvedLabs}
                    rowsPerPage={rowsPerPage.approvedLab}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsApprovedLabs}
                    isNotFound={approvedLabsIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.approvedLab}
                    open={open.approvedLab}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={APPROVED_LAB_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                  />
                )}
                {approvedLabsIsError ? (
                  <div>An error occurred: {approvedLabsError.message}</div>
                ) : null}
                <NewLoadingBackdrop
                  open={approvedLabsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={3}>
                {unApprovedLabs?.length < 1 && (
                  <Empty
                    altText="Unapproved laboratrory list is empty"
                    title="No unapproved laboratrory found, please a laboratrory"
                  />
                )}
                {unApprovedLabs?.length > 0 && (
                  <FacilityPageTable
                    id="unApprovedLab"
                    title="Labs"
                    facilityName="labName"
                    selected={selected.unApprovedLab}
                    filterName={filterName.unApprovedLab}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.unApprovedLab}
                    handleFilterByRole={handleFilterByRole}
                    order={order.unApprovedLab}
                    orderBy={orderBy.unApprovedLab}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredUnApprovedLabs}
                    mainList={unApprovedLabs}
                    rowsPerPage={rowsPerPage.unApprovedLab}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsUnApprovedLabs}
                    isNotFound={unApprovedLabsIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.unApprovedLab}
                    open={open.unApprovedLab}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={UNAPPROVED_LAB_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    approvalRequestHandler={approveFacilityRequestHandler}
                    ApproveFacilityLoading={ApproveFacilityLoading}
                  />
                )}
                {unApprovedLabsIsError ? (
                  <div>An error occurred: {unApprovedLabsError.message}</div>
                ) : null}
                <NewLoadingBackdrop
                  open={unApprovedLabsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={4}>
                {approvedHospitals?.length < 1 && (
                  <Empty
                    altText="Hospital list is empty"
                    title="No approved Hospital found, please approve a Hospital from the unapproved Hospital list"
                  />
                )}
                {approvedHospitals?.length > 0 && (
                  <FacilityPageTable
                    id="approvedHospitals"
                    title="Hospitals"
                    facilityName="name"
                    selected={selected.approvedHospitals}
                    filterName={filterName.approvedHospitals}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.approvedHospitals}
                    handleFilterByRole={handleFilterByRole}
                    order={order.approvedHospitals}
                    orderBy={orderBy.approvedHospitals}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredApprovedHospitals}
                    mainList={approvedHospitals}
                    rowsPerPage={rowsPerPage.approvedHospitals}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsApprovedHospitals}
                    isNotFound={approvedHospitalsIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.approvedHospitals}
                    open={open.approvedHospitals}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={APPROVED_HOSPITAL_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                  />
                )}
                {approvedHospitalsIsError ? (
                  <div>An error occurred: {approvedHospitalsError.message}</div>
                ) : null}
                <NewLoadingBackdrop
                  open={approvedHospitalsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
              <TabPanel value={tabsValue} index={5}>
                {unApprovedHospitals?.length < 1 && (
                  <Empty
                    altText="Unapproved Hospital list is empty"
                    title="No unapproved hospital found, please create a hospital"
                  />
                )}
                {unApprovedHospitals?.length > 0 && (
                  <FacilityPageTable
                    id="unApprovedHospitals"
                    title="Hospitals"
                    facilityName="name"
                    selected={selected.unApprovedHospitals}
                    filterName={filterName.unApprovedHospitals}
                    handleFilterByName={handleFilterByName}
                    filterRole={filterRole.unApprovedHospitals}
                    handleFilterByRole={handleFilterByRole}
                    order={order.unApprovedHospitals}
                    orderBy={orderBy.unApprovedHospitals}
                    handleRequestSort={handleRequestSort}
                    handleSelectAllClick={handleSelectAllClick}
                    filteredUsers={filteredUnapprovedHospitals}
                    mainList={unApprovedHospitals}
                    rowsPerPage={rowsPerPage.unApprovedHospitals}
                    handleClick={handleClick}
                    handleOpenMenu={handleOpenMenu}
                    emptyRows={emptyRowsUnApprovedHospitals}
                    isNotFound={unApprovedHospitalsIsNotFound}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page.unApprovedHospitals}
                    open={open.unApprovedHospitals}
                    handleCloseMenu={handleCloseMenu}
                    TABLE_HEAD={UNAPPROVED_HOSPITAL_TABLE_HEAD}
                    tabsValue={tabsValue}
                    isLoading={isLoading}
                    approvalRequestHandler={approveFacilityRequestHandler}
                    ApproveFacilityLoading={ApproveFacilityLoading}
                  />
                )}
                {unApprovedHospitalsIsError ? (
                  <div>
                    An error occurred: {unApprovedHospitalsError.message}
                  </div>
                ) : null}
                <NewLoadingBackdrop
                  open={unApprovedHospitalsLoading}
                  styles={{
                    marginTop: { xs: "11.1rem", md: "11.3rem", lg: "11.3rem" },
                    ml: { xs: "1.5rem", lg: "20.0rem" },
                    mr: { xs: "1.5rem", lg: "2.5rem" },
                    borderRadius: "0.75rem",
                  }}
                />
              </TabPanel>
            </Card>
          )}

          {openCreatePage && (
            <Card>
              <FacilityForm
                facilityCreationHandler={facilityCreationHandler}
                approvedPartners={approvedPartners}
                createFacilityLoading={createFacilityLoading}
                contactPhone={contactPhone}
                setContactPhone={setContactPhone}
              />
            </Card>
          )}
        </Container>
      </LoadingBackdrop>
    </>
  );
}
