import React, { useState, useEffect } from "react";
import useSpecialization from "../hooks/useSpecialization";

const AutoComplete = ({ selectedItems, setSelectedItems }) => {
  const { specializations } = useSpecialization();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(specializations);
  const [showDropdown, setShowDropdown] = useState(false);

  //   useEffect(() => {
  //     const filteredResults = specializations.filter((result) =>
  //       result.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );

  //     setSearchResults(filteredResults);
  //   }, [searchTerm]);

  const inputStyles = {
    width: "100%",
    padding: "8px",
    border: "0.1px solid #c4c1c1",
    borderRadius: "8px",
    fontSize: "14px",
    outline: "none",
    height: "50px",
  };

  const dropdownStyles = {
    display: showDropdown && searchTerm !== "" ? "block" : "none",
    marginTop: "8px",
    padding: "12px",
    borderRadius: "25px",
    backgroundColor: "#FAFAFA",
    border: "1px solid #gray-300",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    maxHeight: "192px",
    overflowY: "auto",
  };

  const listItemStyles = {
    padding: "8px",
    fontSize: "14px",
    color: "#343673",
    fontWeight: "600",
    cursor: "pointer",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    marginBottom: "8px",
  };

  const selectedItemsContainerStyles = {
    marginTop: "1rem",
  };

  const selectedItemStyles = {
    padding: "4px 8px",
    marginRight: "10px",
    fontSize: "14px",
    fontWeight: "600",
    backgroundColor: "#gray-200",
    borderRadius: "4px",
    cursor: "pointer",
    textTransform: "capitalize",
    border: "1px solid #D9D9D9",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const deleteIconStyles = {
    marginLeft: "10px",
    color: "#2CBCEF",
    borderRadius: "50%",
    padding: "0 2px",
    border: "2px solid #2CBCEF",
    height: "10px",
    width: "10px",
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filteredResults = specializations.filter((result) =>
      result.name.toLowerCase().includes(value.toLowerCase())
    );

    if (
      value.trim() !== "" &&
      !filteredResults.some(
        (result) => result.name.toLowerCase() === value.toLowerCase()
      )
    ) {
      const newResult = {
        name: value.trim(),
      };
      filteredResults.unshift(newResult);

      setSearchResults(filteredResults);
    } else {
      setSearchResults(filteredResults);
    }
    setShowDropdown(true);
  };

  const handleSelect = (name) => {
    setSearchTerm("");
    setSelectedItems((prevItems) => [...prevItems, name]);
    setShowDropdown(false);
  };

  const handleDelete = (name) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item !== name));
  };

  return (
    <div style={{ fontFamily: "quicksand" }}>
      <p
        style={{
          color: "#444448",
          fontSize: "14px",
          fontWeight: "600",
          marginBottom: "5px",
        }}
      >
        Area of specialization covered
        <span style={{ color: "red", marginLeft: "0.5px" }}>*</span>
      </p>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Type to search"
        style={inputStyles}
      />

      <ul style={dropdownStyles}>
        {searchResults.map((result) => (
          <li
            key={result.id}
            onClick={() => handleSelect(result.name)}
            style={listItemStyles}
          >
            {result.name}
          </li>
        ))}
      </ul>

      <div style={selectedItemsContainerStyles}>
        {selectedItems.map((item) => (
          <span
            key={item}
            style={selectedItemStyles}
            onClick={() => handleDelete(item)}
          >
            {item}

            <span style={deleteIconStyles}>&#x2716;</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default AutoComplete;
