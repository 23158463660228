import React, { useState } from "react";

const useContract = () => {
  const [tabsValue, setTabsValue] = useState(0);
  const [tab, setTab] = useState(1);

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  return {
    tab,
    setTab,
    tabsValue,
    handleTabsValueChange,
  };
};

export default useContract;
