import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { UserContext } from '../../context/user-context';
import { useUser } from '../../hooks/user-hook';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
// const APP_BAR_MOBILE = -24;
const APP_BAR_DESKTOP = 92;
// const APP_BAR_DESKTOP = 60;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // backgroundColor: 'red'
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // backgroundColor: 'burlywood',
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingTop: 68,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingTop: 65,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { userDetails } = useUser();

  return (
    <UserContext.Provider
      value={userDetails}
    >
      <StyledRoot>
        <Header onOpenNav={() => setOpen(true)} />

        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main className="" >
          <Outlet />
        </Main>
      </StyledRoot>
    </UserContext.Provider>
  );
}
