import { filter } from "lodash";

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: "patientName", label: "Patient", alignRight: false },
  { id: "doctorName", label: "Doctor", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "startDate", label: "Start", alignRight: false },
  { id: "endDate", label: "End", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: 'Action', label: 'Action', alignRight: false },
  { id: "" },
];

export const myPartners = [
  {
    id: "123-345-766",
    businessName: "Abel Main",
    email: "olaba@gh.com",
    phoneNumber: "081019383221",
    address: "12, Daud Afolabi str, lagos",
    logoUrl:
      "http://t1.gstatic.com/licensed-image?q=tbn:ANd9GcQ8QoQ3COhWo5Y6xjUCLvOGa1gT2_FxmvwI5rvzV17N--9sjsV9CjJP6N1IZ220zkaKakyzTnG6F3Rv65Q",
    created: "11/12/2002",
  },
];

// ----------------------------------------------------------------------

export function kklapplySortFilter(
  normalisedArray,
  comparator,
  query,
  category
) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  // if ( query) {
  //   return filter(result, (_user) => {
  //     return _user.patientName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
  //   });
  // }
  if ((category === "All" || !category) && query) {
    return filter(result, (_user) => {
      return _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1;
    });
  }
  if (category && !query) {
    if (category === "All") return result;
    return filter(
      result,
      (_user) =>
        _user?.areaOfSpecialization?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
    );
  }
  if (category !== "All" && query) {
    return filter(result, (_user) => {
      return (
        _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
        _user?.areaOfSpecialization?.toLowerCase()
          .indexOf(category.toLowerCase()) !== -1
      );
    });
  }
  return result;
}

export function applySortFilter(normalisedArray, comparator, query, category) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if ((category === "All" || !category) && query) {
    return filter(result, (_user) => {
      return (
        _user?.patientName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      );
    });
  }
  if (category && !query) {
    if (category === "All") return result;
    return filter(
      result,
      (_user) =>
        _user?.category?.toLowerCase().indexOf(category.toLowerCase()) !== -1
    );
  }
  if (category !== "All" && query) {
    return filter(result, (_user) => {
      return (
        _user?.patientName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
        _user?.category?.toLowerCase().indexOf(category.toLowerCase()) !== -1
      );
    });
  }
  return result;
}
