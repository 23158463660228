import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ContractCard = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          padding: "1.4rem",
          display: "flex",
          flexDirection: "column",
          rowGap: "0.4rem",
          alignItems: "start",
          borderRadius: "12px",
          width: "302px",
          height: "200px",
          backgroundColor: "white",
          boxShadow: "0 2px 12px rgba(192, 192, 192, 0.25)",
        }}
      >
        <Box sx={{ color: "#2CBCEF", height: "40px", width: "40px" }}>img</Box>
        <Typography style={{ color: "#444448", fontWeight: "500" }}>
          Contract Name
        </Typography>
        <Typography style={{ color: "#808080", fontSize: "12px" }}>
          Date Created;{" "}
          <span style={{ color: "#444448", fontWeight: "500" }}>6/6/2023</span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#2CBCEF",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate("/editcontract")}
          >
            Edit
          </Typography>
          <Button
            style={{
              backgroundColor: "#2CBCEF",
              color: "white",
              borderRadius: "4px",
              padding: "8px 12px",
              fontSize: "12px",
              border: "none",
              cursor: "pointer",
            }}
            type="button"
            onClick={() => navigate("/contract-detail")}
          >
            View Details
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ContractCard;
