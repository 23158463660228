import React from 'react';
import Button from '@mui/material/Button';
import { useFormikContext } from 'formik';

const ResetButton = ({
  onClick,
  ...otherProps
}) => {
  const { isValid, isSubmitting, values, dirty, errors } = useFormikContext();
  // console.log('isValid', isValid, isSubmitting, values, dirty, errors)

  return (
    <Button
      variant="outlined"
      sx={{
        mt: '2rem'
      }}
      disabled={!dirty || isSubmitting}
      onClick={onClick}
      type="reset"
    >
      Reset
    </Button>
  );
};

export default ResetButton;