export const CONTRACT_TABLE_HEAD = [
  { id: "doctorName", label: "Name", alignRight: false },
  { id: "cclId", label: "CCL ID", alignRight: false },
  {
    id: "areaOfSpecialization",
    label: "Area of Specialization",
    alignRight: false,
  },
  { id: "dateCreated", label: "Application Date" },
];
