import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function TransitionsSnackbar({ open, message, onClose }) {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={(event, reason) => onClose(event, reason)}
        // onPageChange={(event, newPage) => handleChangePage(event, newPage, tableId)}
        TransitionComponent={SlideTransition}
        message={message}
        key={Slide}
        sx={{
          bgcolor: 'success',
        }}
      >
        <Alert
          //  onClose={handleClose} 
          severity="success"
          sx={{ width: '100%', color: '#fff' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}