import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import Scrollbar from "../../../components/scrollbar/Scrollbar";
import Iconify from "../../../components/iconify/Iconify";
import CannedCoverageListToolbar from "./CannedCoverageListToolbar";
import CannedCoverageListHead from "./CannedCoverageListHead";
import { BsPlusLg } from "react-icons/bs";
import { Button } from "@mui/material";
import MyInput from "../../../components/commonElements/input/MyInput";
import TransitionssModal from "./CannedModal";

const CannedCoverageTable = ({
  id: tableId,
  selected,
  filterName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  tabsValue,
  isLoading,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const [coverageId, setCoverageId] = useState(null);
  const [selectedCoverage, setSelectedCoverage] = useState(null);

  const handleModalOpen = (coverage) => {
    setSelectedCoverage(coverage);
    setOpenModal(true);
  };

  const handleModalClose = () => setOpenModal(false);

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function formatTime(dateInput) {
    const date = new Date(dateInput);
    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours24 < 12 ? "AM" : "PM";

    return `${hours12}:${minutes}:${seconds} ${ampm}`;
  }

  return (
    <>
      <Box sx={{}}>
        <CannedCoverageListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          filterRole={filterRole}
          onFilterRole={(event) => handleFilterByRole(event, tableId)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <CannedCoverageListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) =>
                  handleRequestSort(event, property, tableId)
                }
                onSelectAllClick={(event) =>
                  handleSelectAllClick(event, tableId)
                }
              />
              <TableBody>
                {/* Replace with the canned coverage list that contains{
                  coverage Name,
                Date created,
              Number of users
                } */}
                {!!filteredUsers.length &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { coverageName, dateCreated, noOfUsers, id } = row;

                      const selectedUser =
                        !!selected && selected.indexOf(id) !== -1;
                      const dateFormatter = (input) => {
                        const date = new Date(input);
                        const formattedDate = `${date.getFullYear()}/${
                          date.getMonth() + 1
                        }/${date.getDate()}`;
                        const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                        return [formattedTime, formattedDate];
                      };

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) =>
                                handleClick(event, id, tableId)
                              }
                            />
                          </TableCell> */}

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              sx={{
                                paddingLeft: "1rem",
                              }}
                            >
                              {/* <Avatar alt={patientName} src={logoUrl} /> */}
                              <Typography noWrap>{coverageName}</Typography>
                            </Stack>
                          </TableCell>

                          <TableCell onClick={(event) => handleModalOpen(row)}>
                            <span>{formatDate(dateCreated)} </span>
                          </TableCell>

                          <TableCell
                            sx={{ paddingLeft: "4rem" }}
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {noOfUsers}
                          </TableCell>

                          <TableCell>
                            <Button
                              size="large"
                              sx={{
                                color: "#2CBCEF",
                                fontWeight: "400",
                                padding: "8px",
                                borderRadius: "8px",
                                border: "1px solid #2CBCEF",
                                backgroundColor: "rgba(44, 188, 239, 0.05)",
                              }}
                              // color="inherit"
                              onClick={(event) => {
                                // setCoverageId(id);
                                // handleOpenMenu(event, tableId);
                                handleModalOpen(row);
                              }}
                            >
                              Convert to a canned coverage
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //IMPORTANT // count={mainList.length} //DO NOT REMOVE
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) =>
            handleChangePage(event, newPage, tableId)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, tableId)
          }
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            p: 2,
            // width: 140,
            "& .MuiMenuItem-root": {
              px: 2,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{ color: "#343673", fontSize: "18PX", fontWeight: "bold" }}
          >
            Add canned coverage
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Typography sx={{ color: "#808080", fontSize: "14px" }}>
              Title of canned coverage
            </Typography>
            <input
              id="name"
              name="cannedCoverName"
              type="text"
              style={{
                width: "100%",
                padding: "16px 8px",
                borderRadius: "8px",
              }}
              // label="License Number"
            />
          </Box>
          <Box
            sx={{
              height: "100px",
              width: "100px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <BsPlusLg
              style={{ color: "#2CBCEF", fontWeight: "bold" }}
              size="50px"
            />
          </Box>
          <Typography sx={{ color: "#4A4A4A", fontWeight: "500" }}>
            Add Image
          </Typography>

          <Button
            sx={{
              color: "white",
              width: "100%",
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "#2CBCEF",
              margin: "10px 0",
            }}
          >
            Save
          </Button>
        </MenuItem>
      </Popover>

      <TransitionssModal
        selectedCoverage={selectedCoverage}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default CannedCoverageTable;
