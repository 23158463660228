"use client"

import * as React from "react"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"

import { cn } from "../../../shadcn/utils"
import { cva } from "class-variance-authority";

const scrollAreaVariants = cva("relative, overflow-hidden", {
  variants: {
     variant: {
        default: "h-56",
        free: "h-auto",
     },
  },
});

const ScrollArea = React.forwardRef(
  ({ variant, className, children, scrollbarClassName, ...props }, ref) => (
     <ScrollAreaPrimitive.Root
        ref={ref}
        className={scrollAreaVariants({ variant, className })}
        {...props}
     >
        <ScrollAreaPrimitive.Viewport
           id="scroll-area"
           className="h-full w-full rounded-[inherit]"
        >
           {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar className={scrollbarClassName} />
        <ScrollAreaPrimitive.Corner />
     </ScrollAreaPrimitive.Root>
  )
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" &&
        "h-full w-2.5 border-l border-l-transparent p-[1px] mr-0.5",
      orientation === "horizontal" &&
        "h-2.5 flex-col border-t border-t-transparent p-[1px]",
      className
    )}
    {...props}>
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
))
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

export { ScrollArea, ScrollBar }
