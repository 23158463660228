import { Box, Button } from "@mui/material";
import React from "react";

const DateRangePicker = ({
  startDate,
  endDate,
  onDateChange,
  handleExportToCSV,
}) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div>
          <label
            htmlFor="startDate"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            Start Date:{" "}
          </label>
          <input
            style={{
              height: "40px",
              padding: "10px",
              border: "1px solid grey",
              borderRadius: "4px",
            }}
            type="date"
            id="startDate"
            value={startDate}
            onChange={(event) => onDateChange(event, "start")}
          />
        </div>
        <div>
          <label
            htmlFor="endDate"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            End Date:{" "}
          </label>
          <input
            style={{
              height: "40px",
              padding: "10px",
              border: "1px solid grey",
              borderRadius: "4px",
            }}
            type="date"
            id="endDate"
            value={endDate}
            onChange={(event) => onDateChange(event, "end")}
          />
        </div>
        <Button
          sx={{
            color: "white",
            fontWeight: "600",
            backgroundColor: "#2CBCEF",
            padding: "0.5rem",
            borderRadius: "4px",
            "&:hover": {
              boxShadow: "3px 4px #2CBCEF",
              backgroundColor: "#2CBCEF",
            },
            whiteSpace: "nowrap",
          }}
          onClick={handleExportToCSV}
        >
          Export as csv
        </Button>
      </Box>
    </>
  );
};

export default DateRangePicker;
