import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setHeader } from "../../redux/headerSlice";
// import { useQuery } from "react-query";
// import apiClient from "../../http/http-common";
import { useDebounce } from "use-debounce";
// import { useQuery } from "react-query";
import apiClient from "../../http/common";
// import useDashboard from "../dashboard/useDashboard";

function usePlans() {
  // const { businessRules } = useDashboard();
  // const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [transactions, setTransaction] = useState([]);
  const [showPlanSummary, setShowPlanSummary] = useState(false);
  const [businessRulesString, setbusinessRulesString] = useState("");
  const [debouncedSearchQuery] = useDebounce(filter, 300);
  const [editableIndices, setEditableIndices] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [editPlanObj, setEditPlanObj] = useState({});
  const [planMode, setPlanMode] = useState("add");
  const [sponsorId, setsponsorId] = useState("");
  // const { sponsorId } = useSelector(
  //   (state) => state?.user?.user?.sponsorForAdmin
  // );
  // Business Rules Implementations
  const [arrayAsString, setArrayAsString] = useState("");
  const [mode, setmode] = useState(false);
  const [editedBusinessRules, setEditedBusinessRules] = useState("");
  const [reverted, setReverted] = useState(false);
  const [dataSponsor, setDataSponsor] = useState(false);
  // const [data, setData] = useState();
  const [tariff, setTariff] = useState()
  
  useEffect(() => {
    apiClient.get(`/sponsor/GetASponsor/${sponsorId}`)
    .then((response) => {
      setDataSponsor(response)
    })
    .catch((error) => {
      console.log(error);
    });
    
  }, [sponsorId]);
  
  const [items, setItems] = useState(dataSponsor?.data?.businessRules);
  
  const handleEditAllClick = () => {
    setmode(true);
    const stringFormated = arrayAsString?.split("\n")?.map((item) => item).join("\n");
    // console.log(stringFormated);
    setArrayAsString(stringFormated);
  };
  
  
  const openModalSummary = () => {
    setShowPlanSummary(true);
  };
  
  const closeModalSummary = () => {
    setShowPlanSummary(false);
  };

  
  useEffect(() => {
    console.log(sponsorId);
    apiClient.post(`/sponsor/GetSponsorTarrifs_V2`, { sponsorId })
    .then((response) => {
      setTariff(response)
    })
    .catch((error) => {
      console.log(error);
    });
    
  }, []);
  

  const options = tariff?.data?.data?.map((value) => {
    return { tarrifId: value.tarrifId, label: value.tarrifName };
  });

  function formatDate(date) {
    date = new Date(date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleSearch = (e) => {
    const { value } = e.target;
    setFilter(value);
    console.log(value);
  };

  const handlePageNumber = (num) => {
    setPageNumber(parseInt(num));
  };

  const incrementPageNumber = () => {
    setPageNumber((prev) => prev + 1);
  };

  const decrementPageNumber = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };

  const handlePageSize = (num) => {
    setPageSize((prev) => prev + parseInt(num));
    // refetch();
  };

  useEffect(() => {
    // refetch();
  }, [pageNumber]);


  useEffect(() => {
    if (debouncedSearchQuery || pageNumber) {
      // refetch();
    }
    if (!debouncedSearchQuery || pageNumber) {
      setFilter("");
      // refetch();
    }
  }, [debouncedSearchQuery, pageNumber]);

  const tableHeading = [
    "",
    "Plan Name",
    "Coverage(s)",
    "Max. Amount (₦)",
    // "Max. Amount Individual (Month)",
    "Start Date",
    "Expiry Date",
  ];

  const tableKeys = [
    "isChecked",
    "planName",
    "firstCoverage",
    "maxAmountPerIndividual",
    // "maxAmountPerIndividualPerMonth",
    "startDate",
    "expiryDate",
  ];

  const activeId = "id";
  const activeIdHeader = "CCL ID";
  const actionable = true;

  const uniqueId = "planId";

  return {
    transactions,
    tableHeading,
    tableKeys,
    activeId,
    activeIdHeader,
    filter,
    uniqueId,
    showPlanSummary,
    actionable,
    summaryData,
    editPlanObj,
    planMode,
    // isLoadingPlans,
    options,
    pageSize,
    pageNumber,
    // data,
    items,
    reverted,
    editableIndices,
    mode,
    businessRulesString,
    arrayAsString,
    editedBusinessRules,
    debouncedSearchQuery,
    setFilter,
    sponsorId,
    // refetch,
    setsponsorId,
    openModalSummary,
    closeModalSummary,
    setSummaryData,
    setShowPlanSummary,
    setPlanMode,
    setEditPlanObj,
    formatDate,
    // pagination,
    setReverted,
    handleSearch,
    handlePageNumber,
    handlePageSize,
    incrementPageNumber,
    decrementPageNumber,
    // tariffRefetch,
    setItems,
    setEditableIndices,
    setbusinessRulesString,
    setmode,
    setArrayAsString,
    handleEditAllClick,
    setEditedBusinessRules,
  };
}

export default usePlans;
