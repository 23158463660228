// import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
// components
// import Label from '../../../components/label';
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// mock
import FacilityModal from "./FacilityModal";
import FacilityListToolbar from "./FacilityListToolbar";
import FacilityListHead from "./FacilityListHead";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function FacilityPageTable({
  id: tableId,
  title,
  facilityName,
  selected,
  filterName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  tabsValue,
  isLoading,
  ApproveFacilityLoading,
}) {
  const [facilityInput, setFacilityInput] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const handleModalOpen = (doc) => {
    setSelectedFacility(doc);
    // setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);

  return (
    <>
      <Box sx={{}}>
        <FacilityListToolbar
          numSelected={selected.length}
          // userSpecializations={userSpecializations}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          // filterRole={filterRole}
          // onFilterRole={(event) => handleFilterByRole(event, tableId)}
          title={title}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <FacilityListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) =>
                  handleRequestSort(event, property, tableId)
                }
                onSelectAllClick={(event) =>
                  handleSelectAllClick(event, tableId, facilityName)
                }
              />
              <TableBody>
                {!!filteredUsers.length &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        pharmacyID,
                        pharmacyName,
                        email,
                        contactPhone,
                        pharmacyAddress,
                        partnerLogo,
                        licenseNo,
                        labId,
                        labName,
                        labEmail,
                        labContactPhone,
                        labAddress,
                        labLicenseNo,
                        name,
                        address,
                        imageTwo,
                        imageone,
                        imageThree,
                        imageFour,
                        id,
                      } = row;

                      const selectedUser =
                        !!selected &&
                        selected.indexOf(pharmacyName || labName || name) !==
                          -1;

                      return (
                        <TableRow
                          hover
                          key={pharmacyID || labId || id || IDBDatabase}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          onClick={(event) => setSelectedFacility(row)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) =>
                                handleClick(
                                  event,
                                  pharmacyName || labName || name,
                                  tableId
                                )
                              }
                            />
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={pharmacyName || labName || name}
                                src={
                                  partnerLogo ||
                                  imageone ||
                                  imageTwo ||
                                  imageThree ||
                                  imageFour
                                }
                              />
                              <Typography variant="subtitle2" noWrap>
                                {pharmacyName || labName || name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          {(licenseNo || labLicenseNo) && (
                            <TableCell
                              align="left"
                              onClick={(event) => handleModalOpen(row)}
                            >
                              {licenseNo || labLicenseNo}
                            </TableCell>
                          )}

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {email || labEmail}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {contactPhone || labContactPhone}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {pharmacyAddress || labAddress || address}
                          </TableCell>

                          {/* <TableCell
                        align="left"
                        onClick={(event) => handleModalOpen(row)}
                      >
                        {formattedDate} @ {formattedTime}
                      </TableCell> */}

                          {/* <TableCell
                        align="left"
                        onClick={(event) => handleModalOpen(row)}
                      >
                        <Label
                          color={
                            maritalStatus === 'divorce' ? 'error' : maritalStatus === 'single' ? 'warning' : 'success'
                          }
                        >
                          {maritalStatus}
                        </Label>
                      </TableCell> */}

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                // setFacilityInput(pharmacyID || labId);
                                let input;
                                if (!!pharmacyID) {
                                  input = {
                                    entityId: pharmacyID,
                                    facilityType: 0,
                                  };
                                } else if (!!labId) {
                                  input = {
                                    entityId: labId,
                                    facilityType: 1,
                                  };
                                } else {
                                  input = {
                                    entityId: id,
                                    facilityType: 2,
                                  };
                                }
                                setFacilityInput(input);
                                handleOpenMenu(event, tableId);
                              }}
                            >
                              <Iconify
                                icon={"eva:more-vertical-fill"}
                                sx={{
                                  color: "primary.main",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //IMPORTANT // count={mainList.length} //DO NOT REMOVE
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) =>
            handleChangePage(event, newPage, tableId)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, tableId)
          }
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {(tabsValue + 1) % 2 === 0 && (
          <MenuItem
            onClick={(event) => {
              approvalRequestHandler(facilityInput, tableId);
              // handleCloseMenu(tableId)
            }}
            disabled={ApproveFacilityLoading}
          >
            {ApproveFacilityLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  mr: 2,
                  color: "primary.main",
                }}
              />
            ) : (
              <Iconify
                icon={"eva:compass-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Approve
          </MenuItem>
        )}

        <MenuItem
          onClick={(event) => {
            navigate(`/facility-detail/${selectedFacility.pharmacyID}`, {
              state: selectedFacility,
            });
          }}
          // sx={{ color: 'error.main' }}
        >
          <Iconify icon={"material-symbols:grid-view-rounded"} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <FacilityModal
        selectedFacility={selectedFacility}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        approvalRequestHandler={approvalRequestHandler}
        isLoading={isLoading}
      />
    </>
  );
}
