import { axiosClient, baseUrl } from "../utils/helper";

export const fetchUnApprovedPharmacies = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/pharmacy/api/AdminReport/GetPendingPharmacies`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log('UnApprovedPartners', responseData?.data.data.data)
  return responseData?.data.data.data;
};

export const fetchApprovedPharmacies = async (token, signal) => {
  const responseData = await axiosClient.get(`${baseUrl}/pharmacy/Pharmacy`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  });
  // console.log('approvedPartners', responseData?.data.data)
  return responseData?.data.data;
};

export const fetchUnApprovedLabs = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/lab/api/AdminReport/GetPendingLabs`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log('UnApprovedLabs', responseData?.data.data.data)
  return responseData?.data.data.data;
};

export const fetchApprovedLabs = async (token, signal) => {
  const responseData = await axiosClient.get(`${baseUrl}/lab/api/Lab/AllLabs`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  });
  // console.log('approvedLabs', responseData?.data.data)
  return responseData?.data.data;
};

export const fetchApprovedHospitals = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/hospital/ApprovedFacilities`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log("approvedHospitals", responseData);
  return responseData?.data.data.data;
};

export const fetchUnapprovedHospitals = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/hospital/UnApprovedFacilities`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log("UnapprovedHospitals", responseData);
  return responseData?.data.data.data;
};

export const updateFacilityApprovalRequest = (data) => {
  return axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/ApproveFacility`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
};

// https://cloudclinic-apim.azure-api.net/admin/api/AdminAPI/CreateFacility_v1

export async function CreateFacility({ formData, onSubmitProps, token }) {
  const responseData = await axiosClient.post(
    // `${baseUrl}/admin/api/AdminAPI/CreateFacility`,
    `${baseUrl}/admin/api/AdminAPI/CreateFacility_v1`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    }
  );
  // console.log('responseData', responseData)
  return responseData;
}

// formData.append('Email', facilityType === 'pharmacy' ? email : '');
// formData.append('PharmacyName', facilityType === 'pharmacy' ? pharmacyName : '');
// formData.append('ContactPhone', facilityType === 'pharmacy' ? contactPhone.toString() : '');
// formData.append('PharmacyAddress', facilityType === 'pharmacy' ? pharmacyAddress : '');
// formData.append('LicenseNo', facilityType === 'pharmacy' ? licenseNo : '');
// formData.append('LicenceCertificate', facilityType === 'pharmacy' ? licenseUrl : '');
// formData.append('Rebate', facilityType === 'pharmacy' ? pharmacyRebate : '');
// formData.append('State', facilityType === 'pharmacy' ? state : '');
// formData.append('City', facilityType === 'pharmacy' ? city : '');
// formData.append('Lga', facilityType === 'pharmacy' ? lga : '');
// formData.append('Country', facilityType === 'pharmacy' ? country : '');
// formData.append('LabName', facilityType === 'lab' ? labName : '');
// formData.append('LabContactPhone', facilityType === 'lab' ? labContactPhone.toString() : '');
// formData.append('LabEmail', facilityType === 'lab' ? labEmail : '');
// formData.append('LabAddress', facilityType === 'lab' ? labAddress : '');
// formData.append('LabLicenseNo', facilityType === 'lab' ? labLicenseNo : '');
// formData.append('LicenceCertificate', facilityType === 'lab' ? labLicenseUrl : '');
// formData.append('Rebate', facilityType === 'lab' ? labRebate : '');
// formData.append('State', facilityType === 'lab' ? labState : '');
// formData.append('City', facilityType === 'lab' ? labCity : '');
// formData.append('Lga', facilityType === 'lab' ? labLga : '');
// formData.append('Country', facilityType === 'lab' ? labCountry : '');
