import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

import { useField, useFormikContext } from 'formik';

const MyAutocomplete = ({
  id,
  name,
  label,
  options,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  // console.log('values', values, 'to', values[name]);

  const configSelect = {
    ...field,
    ...otherProps,
    variant: 'outlined',
    fullWidth: true,
    sx: {
      width: '100%',
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `#dcdcdc !important`,
      },
    }
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true.toString();
    configSelect.helpertext = meta.error;
  }

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <Autocomplete
          // value={value}
          {...configSelect}
          // isOptionEqualToValue={true}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue);
          }}
          disablePortal
          id={id}
          options={!!options && options}
          renderInput={(params) => <TextField
            {...configSelect}
            {...params}
            label={label}
            error={configSelect.error}
            helperText={configSelect.helpertext}
          />
          }
        />
      </FormControl>
    </>
  );
};

export default MyAutocomplete;
