import { filter } from 'lodash';

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export function applySortFilter(normalisedArray, comparator, query, category) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if ( query) {
    return filter(result, (_user) => {
      return _user?.firstName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    });
  }
  return result
}
