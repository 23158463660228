import { useContext } from "react";
// @mui
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// component
import { TABLE_HEAD } from "../sections/@dashboard/transaction/TransactionsHelperFunctions";
import { AuthContext } from "../context/auth-context";
import ErrorModal from "../components/Modal/ErrorModal";
import useTransaction from "../sections/@dashboard/transaction/transactionHook";
import TransactionPageTable from "../sections/@dashboard/transaction/TransactionPageTable";
import TransitionsSnackbar from "../components/snackBar";
import LoadingBackdrop from "../components/loader";
import { a11yProps, TabPanel } from "../utils/helper";
import Empty from "../components/commonElements/empty/empty";

export default function TransactionPage() {
  const auth = useContext(AuthContext);
  const {
    transactionHistory,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredTransactions,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
  } = useTransaction(auth);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      <LoadingBackdrop
        open={isLoading}
        // open={true}
        styles={{
          marginTop: "12rem",
          mx: "1.5rem",
          borderRadius: "0.75rem",
        }}
      >
        <Container
          sx={{
            mt: 2,
          }}
        >
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Transactions
            </Typography>
          </Stack> */}

          {
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleTabsValueChange}
                  aria-label="Transaction tabs"
                >
                  <Tab label="Transaction table" {...a11yProps(0)} />
                  {/* <Tab label=" Unapproved" {...a11yProps(1)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={tabsValue} index={0}>
                {transactionHistory?.length < 1 && (
                  <Empty
                    altText="Transactions list is empty"
                    title="No transactions yet."
                  />
                )}
                {
                  // filteredTransactions && filteredTransactions.length > 0 && <TransactionPageTable
                  transactionHistory?.length > 0 && (
                    <TransactionPageTable
                      // id='approved'
                      selected={selected}
                      filterName={filterName}
                      handleFilterByName={handleFilterByName}
                      filterRole={filterRole}
                      handleFilterByRole={handleFilterByRole}
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      handleSelectAllClick={handleSelectAllClick}
                      filteredUsers={filteredTransactions}
                      mainList={transactionHistory}
                      rowsPerPage={rowsPerPage}
                      handleClick={handleClick}
                      handleOpenMenu={handleOpenMenu}
                      emptyRows={emptyRows}
                      isNotFound={isNotFound}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      open={open}
                      handleCloseMenu={handleCloseMenu}
                      TABLE_HEAD={TABLE_HEAD}
                      tabsValue={tabsValue}
                      isLoading={isLoading}
                    />
                  )
                }
              </TabPanel>
            </Card>
          }
        </Container>
      </LoadingBackdrop>
    </>
  );
}
