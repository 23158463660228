import React, { useReducer, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { validate } from '../../../utils/validators';
import './MyInput.css';
import { useField } from 'formik';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      // console.log('CHANGE inputReducer', {
      //   ...state,
      //   value: action.val,
      //   isValid: validate(action.val, action.validators)
      // })
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      };
    }
    default:
      return state;
  }
};

const MyInputyu = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isTouched: false,
    isValid: props.initialValid || false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    // console.log('value', event.target.value);
    // console.log('validators', props.validators);
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    // console.log('TOUCH')
    dispatch({
      type: 'TOUCH'
    });
  };

  const element =
    props.element === 'textarea' ? (
      <textarea
        error={props.error}
        id={props.id}
        name={props.name}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : (
      <TextField
        error={props.error}
        id={props.id}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        InputProps={props.InputProps}
        {...props}
      />
    );

  return (
    // <div
    //   className={`form-control ${!inputState.isValid &&
    //     inputState.isTouched &&
    //     'form-control--invalid'}`}
    // >
      <TextField
        error={props.error}
        id={props.id}
        name={props.name}
        type={props.type}
        label={props.label}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        helperText={props.helperText}
        InputProps={props.InputProps}
        {...props}
      />
    //   {/* {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    // </div> */}
  );
};

const MyInput = ({
  name,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <TextField {...configTextfield} />
  );
};

export default MyInput;
