import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./AdminHelperFunctions";
import { baseUrl, getComparator } from "../../../utils/helper";
import { reactAppSubscriptionKey } from "../../../http/common";

function useAdmin(auth) {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [admins, setAdmins] = useState();
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const switchForm = (event) => setOpenCreatePage(true);
  const switchList = (event) => setOpenCreatePage(false);
  const [loadedPermissions, setLoadedPermissions] = useState(null);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const AdminPageSwitch = (event) => setOpenCreatePage((page) => !page);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/admin/api/AdminAPI/GetAllPermissions`,
          undefined,
          undefined,
          {
            // 'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        // console.log('first', responseData?.data?.allPermission)
        setLoadedPermissions(
          responseData?.data?.allPermission.map((permission) => ({
            label: permission.permType,
            value: permission.permValue,
          }))
        );
      } catch (err) {}
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/authentication/api/Admin/getAllAdminUsers`,
          undefined,
          undefined,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
          }
        );
        // console.log('fetchAdmins', responseData?.data)
        setAdmins(responseData?.data);
      } catch (err) {}
    };
    fetchAdmins();
  }, [sendRequest, token]);

  const adminCreationHandler = async (values, onSubmitProps) => {
    console.log("values", values);

    try {
      const responseData = await sendRequest(
        `${baseUrl}/admin/api/AdminAPI/CreateAdminUser`,
        "POST",
        JSON.stringify({
          firstName: values.firstName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          permissions: values.permissions,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      console.log("responseData", responseData);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      AdminPageSwitch();
      switchList();
      setTabsValue(0);
    } catch (err) {}
  };

  const handleSnackbarClose = (event, reason) => {
    console.log("event", event, reason);
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("firstName");

  const [filterName, setFilterName] = useState("");

  const [filterRole, setFilterRole] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [tabsValue, setTabsValue] = useState(0);

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (id) => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - admins.length) : 0;

  const handleFilterByRole = (event, id) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const filteredAdmins = useMemo(
    () =>
      !!admins &&
      applySortFilter(
        admins,
        getComparator(order, orderBy),
        filterName,
        filterRole
      ),
    [admins, order, orderBy, filterName, filterRole]
  );

  const isNotFound = !filteredAdmins.length && !!filterName;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = admins.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return {
    admins,
    loadedPermissions,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    filteredAdmins,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    switchList,
    switchForm,
    openCreatePage,
    AdminPageSwitch,
    adminCreationHandler,
  };
}

export default useAdmin;
