import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledViewButton = styled((props) => <Button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '1px solid #000',
  borderRadius: '0.4rem',
  boxShadow: 24,
  p: 4,
};

export default function FacilityModal({
  selectedPartner,
  openModal,
  handleModalOpen,
  handleModalClose,
  approvalRequestHandler,
}) {
  // console.log('selectedPartner', selectedPartner)

  const { id, businessName, email, phoneNumber, address, logoUrl, created } = !!selectedPartner && selectedPartner;;

  const date = new Date(created);
  const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  // console.log('avatarUrl', avatarUrl)

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Card>
              <Box sx={{ pt: '100%', position: 'relative' }}>
                {/* {gender && (
                  <Label
                    variant="filled"
                    color={(gender === 'female' && 'error') || 'info'}
                    sx={{
                      zIndex: 9,
                      top: 16,
                      right: 16,
                      position: 'absolute',
                      textTransform: 'uppercase',
                    }}
                  >
                    {gender}
                  </Label>
                )} */}
                <StyledProductImg alt={businessName} src={logoUrl} />
              </Box>

              <Stack spacing={2} sx={{ p: 3 }}>
                <Typography
                  variant="h6"
                  noWrap
                  textTransform={'capitalize'}
                >
                  {businessName}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    // color: 'text.disabled',
                  }}
                >
                  Email - {email}
                </Typography>

                <Link color="inherit" underline="hover">
                  <Typography variant="subtitle2" noWrap>
                    {phoneNumber}
                  </Typography>
                </Link>
                {/* <Box>
                  <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />
                  <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                  <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />
                  <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />
                </Box> */}

                <Typography variant="body1" noWrap textTransform={'capitalize'}>
                  {address}.
                </Typography>

                <Typography variant="body1" noWrap textTransform={'capitalize'}>
                  Created - {formattedDate} @ {formattedTime}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    mt: '2rem'
                  }}
                >
                  View Profile
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mt: '2rem'
                  }}
                  onClick={(event) => approvalRequestHandler(id)}
                >
                  Approve Doctor
                </Button>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box>jh</Box>
                  <Typography variant="subtitle1">
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        color: 'text.disabled',
                        textDecoration: 'line-through',
                      }}
                    >
                      {consultationPrice && fCurrency(consultationPrice)}
                    </Typography>
                    &nbsp;
                    {fCurrency(consultationPrice)}
                  </Typography>
                </Stack> */}
              </Stack>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}