import React, { useEffect, useState } from "react";
import AddNewHealthPlan from "./AddNewHealthPlan";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ArrowLeft, Search } from "lucide-react";
import { Input } from "src/components/shadch/ui/ui/input";
import PlanCard from "./PlanCard";
import apiClient from "../../../http/common";
import { logDOM } from "@testing-library/react";
import { TablePagination } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
// import { useDebounce } from "use-debounce";

const searchSchema = z.object({
  searchQuery: z.string(1, { message: "this search is optional" }).optional(),
});

const HealthInsuranceDetailsPage = () => {
  const [pageSize, setPageSize] = useState(6);
  const [pageNumber, setPageNumber] = useState(0);
  const [heirsPlans, setHeirsPlans] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { companyId } = useParams();

  const form = useForm({
    resolver: zodResolver(searchSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    control: { defaultField: "input" },
    defaultValues: {
      searchQuery: "",
    },
  });

  // .get(
  //   `/sponsor/GetCustomPlans?sponsorId=${companyId}&pageNumber=${
  //     pageNumber + 1
  //   }&pageSize=${pageSize}`

  useEffect(() => {
    const searchQuery = searchParams.get('searchQuery');
    apiClient
      .get(`sponsor/GetCustomPlans?sponsorId=${companyId}&searchWords=${searchQuery}&pageNumber=${pageNumber + 1}&pageSize=${pageSize}`)
      .then((response) => {
        setHeirsPlans(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    if (searchQuery) {
      form.setValue('searchQuery', searchQuery);
    }
  }, [searchParams, form.setValue, form, pageNumber, pageSize, companyId]);


  const handleChange = async (event) => {
    const value = event.target.value;
    form.setValue('searchQuery', value);
    const isValid = await form.trigger('searchQuery');
    if (isValid) {
      setSearchParams({ searchQuery: value });
    }
  };



  const dateFormatter = (date) => {
    date = new Date(date);
    const day = String(date.getDay()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageNumber(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <span
        onClick={() => navigate(-1)}
        className=" w-36 text-xs my-5 cursor-pointer flex gap-2 justify-start items-center"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        <span className="">GO BACK</span>
      </span>
      <div className="bg-white p-5">
        <div className="text grid grid-cols-3  flex-wrap justify-between">
          <div className="text">
            <div className="text my-4">
              <h3 className="text text-slate-600 font-semibold">
                Date of Registration
              </h3>
              <span className="text-slate-500">
                {dateFormatter(state?.createdAt)}
              </span>
            </div>
          </div>
          <div className="text">
            <div className="text my-4">
              <h3 className="text text-slate-600 font-semibold">
                Phone number
              </h3>
              <span className="text-slate-500">{state?.phoneNumber}</span>
            </div>
          </div>
          <div className="text">
            <div className="text my-4">
              <h3 className="text text-slate-600 font-semibold">Email</h3>
              <span className="text-slate-500">{state?.email}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center mt-10">
        <div className={"w-full relative"}>
        <form >
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <Input
              type="text"
              {...form.register("searchQuery")}
              placeholder="Search..."
              onChange={handleChange}
              className="w-2/5 pl-12 py-6"
            />
          </form>
        </div>
        <AddNewHealthPlan />
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        {heirsPlans?.data?.map((plans, index) => (
          <div className="my-2" key={index}>
            <PlanCard
              content={plans?.planName}
              key={index}
              footer={state?.sponsorName}
            />
          </div>
        ))}
      </div>
      {heirsPlans?.data?.length > 0 && (
        <div className={"w-full relative"}>
          <TablePagination
            rowsPerPageOptions={[6, 9, 30]}
            component="div"
            count={heirsPlans?.metaData?.total}
            rowsPerPage={heirsPlans?.metaData?.perPage}
            page={pageNumber}
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
          />
        </div>
      )}
    </div>
  );
};

export default HealthInsuranceDetailsPage;
