import { useState, useCallback, useRef, useEffect } from "react";

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        // console.log('response', response.statusText);
        if (!response.ok) {
          // console.log('response', response);
          // console.log('responseData', responseData);
          throw new Error(responseData.message);
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        // console.log('sendRequest err', err)
        const message = error?.response?.data?.message || error?.message;
        setError(message);

        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  // useEffect(() => {
  //   return () => {
  //     activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
  //   };
  // }, []);

  return { isLoading, error, sendRequest, clearError, setError };
};

// export const useHttpClients = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState();

//   const activeHttpRequests = useRef([]);

//   const sendRequest = useCallback(
//     async (url, method = 'GET', body = null, headers = {}) => {
//       setIsLoading(true);
//       const httpAbortCtrl = new AbortController();
//       activeHttpRequests.current.push(httpAbortCtrl);

//       try {
//         const response = await fetch(url, {
//           method,
//           body,
//           headers,
//           signal: httpAbortCtrl.signal
//         });

//         const responseData = await response.json();

//         activeHttpRequests.current = activeHttpRequests.current.filter(
//           reqCtrl => reqCtrl !== httpAbortCtrl
//         );

//         if (!response.ok) {
//           throw new Error(responseData.message);
//         }

//         setIsLoading(false);
//         return responseData;
//       } catch (err) {
//         setError(err.message);
//         setIsLoading(false);
//         throw err;
//       }
//     },
//     []
//   );

//   const clearError = () => {
//     setError(null);
//   };

//   useEffect(() => {
//     return () => {
//       activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
//     };
//   }, []);

//   return { isLoading, error, sendRequest, clearError, setError };
// };
