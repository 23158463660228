import { useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
// components
import Iconify from '../../../components/iconify';
import MyInput from '../../../components/commonElements/input/MyInput';
import FormikSubmitButton from '../../../components/buttons/FormikSubmitButton';

// ----------------------------------------------------------------------

export default function LoginForm({
  isLoading,
  validityState,
  authSubmitHandler,
  inputHandler
}) {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Stack
        spacing={3}
        component='div'
        onSubmit={authSubmitHandler}
        sx={{ pt: 3 }}
      >

        <MyInput
          id="email"
          type="email"
          name="email"
          label="E-mail"
          sx={{ pb: 2 }}
        />

        <MyInput
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormikSubmitButton>
          Login
        </FormikSubmitButton>
      </Stack>
    </>
  );
}
