import { axiosClient, baseUrl } from "../utils/helper";

export const fetchUnApprovedDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(`${baseUrl}/Doctor/OnBoarding`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
    },
    signal,
  });
  // console.log('UnApprovedDoctors', responseData?.data.data.data)
  return responseData?.data.data.data;
  // const docs = normalisedArrayFunction(responseData?.data.data.data)
  // return docs;
};
export const fetchRejectedDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/Doctor/OnBoarding/GetAllRejectedDoctors`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
      },
      signal,
    }
  );
  return responseData?.data.data.data;
};

export const fetchApprovedDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/report/api/Doctor/Doctors?pageNumber=1`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
      },
      signal,
    }
  );
  // console.log('approvedDoctors', responseData?.data.data.data)
  return responseData?.data.data.data;
};

export const fetchActiveDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(
    // `${baseUrl}/report/api/AdminReport/GetAllPendingDeactivatedUsers?pageNumber=1&pageSize=50`,
    `${baseUrl}/report/api/AdminReport/GetAllPendingDeactivatedUsers`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log('activeDoctors', responseData?.data.data)
  return responseData?.data.data.data;
};

export const fetchDeactivedDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(
    // `${baseUrl}/report/api/AdminReport/GetAllPendingDeactivatedUsers?pageNumber=1&pageSize=50`,
    `${baseUrl}/report/api/AdminReport/GetListOfDeactivatedDoctors`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log('activeDoctors', responseData?.data.data)
  return responseData?.data.data.data;
};

export const fetchScoreAppDoctors = async (token, signal) => {
  const responseData = await axiosClient.get(
    // `${baseUrl}/report/api/AdminReport/GetAllPendingDeactivatedUsers?pageNumber=1&pageSize=50`,
    `${baseUrl}/report/api/Doctor/ScoreAppDoctors`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  return responseData?.data?.data;
};

export const addScoreAppDoctor = (data) => {
  return axiosClient.post(
    `${baseUrl}/report/api/Doctor/SeedScoreAppDoctors`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
};

export const removeScoreAppDoctor = (data) => {
  return axiosClient.post(
    `${baseUrl}/report/api/Doctor/RemoveScoreAppDoctor`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
};

export const fetchDoctorSpecializations = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/Doctor/api/Doctors/specs`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
      },
      signal,
    }
  );
  // console.log('responseData 2', responseData?.data.data)
  return responseData?.data.data;
};

export const deactivateLmdRequest = (data) => {
  return axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/DeactivateUser`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
  // return 'dfdf'
};

///New function

export const deactivateLmd = (data) => {
  return axiosClient.post(
    // `${baseUrl}/admin/api/AdminAPI/DeactivateLmd`,
    `${baseUrl}/admin/api/AdminAPI/ConfirmReactivateDeactivateLmd`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
  // return 'dfdf'
};

export const reActivateLmd = (data) => {
  return axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/ReactivateLmd`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
  // return 'dfdf'
};

export const updateApprovalRequest = (data) => {
  return axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/ApproveLmd`,
    data.data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
};
export const updateRejectRequest = (data) => {
  return axiosClient.get(
    `${baseUrl}/Doctor/OnBoarding/RejectDoctor?applicationId=${data?.data?.applicationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.token,
      },
    }
  );
};
