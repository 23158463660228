import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";
import { AuthContext } from "../context/auth-context";
import { useHttpClient } from "../hooks/http-hook";
import ErrorModal from "../components/Modal/ErrorModal";
import { baseUrl } from "../utils/helper";
import { reactAppSubscriptionKey } from "../http/common";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md");
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError, setError } = useHttpClient();
  const firstName = JSON.parse(
    localStorage.getItem("cloudClinicAdminUserName")
  );

  const INITIAL_FORM_STATE = {
    email: "",
    password: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().email("Invalid email.").required("Required"),
    password: Yup.string().required("Required"),
  });

  const authSubmitHandler = async (values, onSubmitProps) => {
    try {
      const responseData = await sendRequest(
        `${baseUrl}/authentication/api/Auth/login`,
        "POST",
        JSON.stringify(values),
        {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": reactAppSubscriptionKey,
        }
      );
      // console.log('responseData', responseData);
      onSubmitProps.setSubmitting(false);
      if (responseData.data.permissions.length < 1) {
        setError("Error login in");
        return;
      }
      onSubmitProps.resetForm();
      auth.login(
        responseData.data.id,
        responseData.data.token,
        undefined,
        responseData.data.permissions
      );
    } catch (err) {
      const message = err?.response?.data?.message || err?.message;
      setError(message);
    }
  };

  return (
    <>
      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography
              variant="h3"
              sx={{
                px: 5,
                mt: { md: "4.7rem", lg: "2rem" },
                mb: { md: "0.2rem", lg: "0.4rem" },
              }}
            >
              Hi {firstName || "admin"}, Welcome back👏
            </Typography>
            <img
              src="/assets/illustrations/login-illustration.svg"
              alt="login illustration"
              style={{
                width: "26rem",
                height: "25rem",
              }}
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to CloudClinic
            </Typography>

            <ErrorModal error={error} onClear={clearError} />
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={authSubmitHandler}
            >
              <Form>
                <LoginForm />
              </Form>
            </Formik>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
