import React, { useState, useEffect } from "react";
import { Input } from "../../../components/shadch/ui/ui/input";
import { Search } from "lucide-react";
import AddNewHealth from "./AddNewHealth";
import { TablePagination } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiClient from "../../../http/common";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
// import { useDebounce } from "use-debounce";

const searchSchema = z.object({
  searchQuery: z.string(1, { message: "this search is optional" }).optional(),
});

const HealthInsuranceCompany = () => {
  const [heirsData, setHeirsData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  
  // const [debounceSearchQuery] = useDebounce(searchParams.get('searchQuery'), 400);
  const form = useForm({
    resolver: zodResolver(searchSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    control: { defaultField: "input" },
    defaultValues: {
      searchQuery: "",
    },
  });

  useEffect(() => {
    const searchQuery = searchParams.get('searchQuery');
    apiClient
      .get(`sponsor/GetCustomSponsors?searchWords=${searchQuery}`)
      .then((response) => {
        setHeirsData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    if (searchQuery) {
      form.setValue('searchQuery', searchQuery);
    }
  }, [searchParams, form.setValue, form]);


  const handleChange = async (event) => {
    const value = event.target.value;
    form.setValue('searchQuery', value);
    const isValid = await form.trigger('searchQuery');
    if (isValid) {
      setSearchParams({ searchQuery: value });
    }
  };

  const empptyPage = [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const dateFromater = (date) => {
    date = new Date(date);
    const day = String(date.getDay()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="mt-10">
      <div className="w-full flex items-center">
        <div className={"w-full relative"}>
          <form >
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <Input
              type="text"
              {...form.register("searchQuery")}
              placeholder="Search..."
              onChange={handleChange}
              className="w-2/5 pl-12 py-6"
            />
          </form>
        </div>
        <AddNewHealth />
      </div>
      <table className="text w-full mt-10">
        <thead className="text w-full bg-[#FAFAFA] h-10 border-b">
          <tr className="text-[#4A4A4A] font-bold">
            <td className="text-start w-1/4 pl-2">Name</td>
            <td className="text-start">Date of Reg.</td>
            <td className="text-start">Phone</td>
            <td className="text-start">Email Address</td>
            <td className="text-start">Action</td>
          </tr>
        </thead>
        <tbody className="text-start text-[13px] text-gray-600 w-full">
          {heirsData?.length === 0 &&
            empptyPage.map((value, index) => (
              <tr
                key={index}
                className={`animate animate-pulse text w-full h-6 ${
                  (index + 1) % 2
                    ? "bg-white text-white"
                    : "bg-[#dddddd] text-[#dddddd]"
                }  `}
              >
                <td className="animate animate-pulse text pl-4 py-3">
                  {value}
                </td>
                <td className="animate animate-pulse text pl-4 py-3">
                  {value}
                </td>
                <td className="animate animate-pulse text ">{value}</td>
                <td className="animate animate-pulse text ">{value}</td>
                <td className="animate animate-pulse text">
                  <span className="animate animate-pulse text pl-1 pr-8 rounded-md py-1">
                    {value}
                  </span>
                </td>
                <td className="animate animate-pulse text flex items-center py-2.5 cursor-pointer">
                  <span className="animate animate-pulse text-red-500 text-xs font-normal hover:text-red-600 hover:scale-105">
                    {}
                  </span>
                </td>
              </tr>
            ))}
          {!!heirsData &&
            heirsData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((tab, index) => (
                <>
                  <tr
                    className={`text ${
                      (index + 1) % 2 ? "bg-white" : "bg-[#FAFAFA]"
                    }`}
                  >
                    <td className="text-[#444448]  pl-5 py-5">
                      {tab?.sponsorName}
                    </td>
                    <td className="text-[#444448]  pl-5 py-3">
                      {tab?.createdAt
                        ? dateFromater(tab?.createdAt)
                        : "--------"}
                    </td>
                    <td className="text-[#444448]">
                      {tab?.phoneNumber ? tab?.phoneNumber : "--------"}
                    </td>
                    <td className="text-[#444448]">{tab?.email}</td>
                    <td className="text-[#444448]">
                      <button
                        onClick={() =>
                          navigate(
                            `/health-insurance-companies-details/${tab?.id}`,
                            {
                              state: tab,
                            }
                          )
                        }
                        className="text-[#2CBCEF]"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </>
              ))}
        </tbody>
      </table>
      <div className="text bg-white w-full border-t">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={heirsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(event, newPage)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
        />
      </div>
    </div>
  );
};

export default HealthInsuranceCompany;
