import { axiosClient, baseUrl } from "../utils/helper";

export const fetchUnsuccessfulConsultations = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/report/api/AdminReport/UnsuccessfulConsultations`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log("UnapprovedHospitals", responseData);
  return responseData?.data.data.data;
};

export const fetchUnsuccessfulAppointments = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/report/api/AdminReport/UnsuccessfulAppointments`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal,
    }
  );
  // console.log("UnapprovedHospitals", responseData);
  return responseData?.data.data.data;
};
