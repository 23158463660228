import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Iconify from "../components/iconify";
import BackButton from "../layouts/backButton";
import apiClient from "../http/common";
import { useQuery } from "@tanstack/react-query";
import { BsImage } from "react-icons/bs";

const account = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: "/assets/images/avatars/avatar_default.jpg",
};

const ItemComponent = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        pb: "1rem",
        // backgroundColor: 'darkolivegreen',
      }}
    >
      <Typography
        sx={{
          width: "9.8rem",
          fontSize: "0.87rem",
          // backgroundColor: 'firebrick',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "auto",
          align: "left",
          fontWeight: "medium",
          fontSize: "0.87rem",
          // backgroundColor: 'cornsilk',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

function FacilityDetailPage() {
  let location = useLocation();
  const params = useParams();
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const id = params.facilityId;
  const details = location?.state;

  // const { data } = useQuery({
  //   queryKey: ["pharmacy"],
  //   queryFn: () => apiClient.get(`/pharmacy/Pharmacy/by-id?id=${id}`),
  // });

  let images = [
    details?.imageOne,
    details?.imageTwo,
    details?.imageThree,
    details?.imageFour,
  ];

  images = images.filter((image) => Boolean(image));

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          // padding: '1.6rem',
          mt: "1.5rem",
          flexWrap: "wrap",
          // width: '50%',
        }}
      >
        <Box
          sx={{
            // width: { xs: '100%', md: '54%' },
            width: { xs: "100%", md: "54%" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#343673",
            }}
          >
            {`${
              details?.pharmacyName
                ? "Pharmacy "
                : details?.labName
                ? "Lab"
                : "Hospital"
            } Information`}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "1.5rem",
              display: "flex",
              flexWrap: { xs: "wrap", sm: "no-wrap" },
              padding: "1.6rem",
              mr: "1.0rem",
              mt: "0.5rem",
              // width: '49%',
              // backgroundColor: 'aquamarine'
            }}
          >
            {/* <Box
              sx={{
                // backgroundColor: 'bisque'
                width: { xs: '100%', sm: '5.5rem' },
              }}
            > */}
            <Avatar
              src={details?.partnerLogo || account.photoURL}
              alt={details?.firstName || "photoURL"}
              sx={{
                width: { xs: "7.5rem", sm: "5.5rem" },
                height: { xs: "7.5rem", sm: "5.5rem" },
                mr: { xs: "auto", sm: "1.0rem" },
                ml: { xs: "auto", sm: "0rem" },
                mb: { xs: "auto", lg: "0.5rem" },
              }}
            />
            {/* </Box> */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                mt: { xs: "1.5rem", sm: "0rem" },
              }}
            >
              <ItemComponent
                title={`${
                  details?.pharmacyName
                    ? "Pharmacy "
                    : details?.labName
                    ? "Lab"
                    : "Hospital"
                } Name:`}
                value={
                  details?.pharmacyName
                    ? details?.pharmacyName
                    : details?.labName
                    ? details?.labName
                    : details?.name
                }
              />
              <ItemComponent
                title={"Email:"}
                value={details?.email ? details?.email : details?.labEmail}
              />
              <ItemComponent
                title={"Contact:"}
                value={
                  details?.contactPhone
                    ? details?.contactPhone
                    : details?.labContactPhone
                }
              />
              <ItemComponent
                title={"Address:"}
                value={
                  details?.pharmacyAddress
                    ? details?.pharmacyAddress
                    : details?.labAddress
                    ? details.labAddress
                    : details.address
                }
              />
              <ItemComponent
                title={"Facility ID:"}
                value={details?.facilityId}
              />{" "}
              <ItemComponent
                title={"Facility Owner ID:"}
                value={details?.facilityOwnerId}
              />
              <ItemComponent
                title={"Rebate:"}
                value={`${details?.rebate * 100}%`}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "43%" },
          }}
        >
          <Box
            sx={{
              mt: { xs: "1.5rem", md: "0rem" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#343673",
              }}
            >
              Professional Details
            </Typography>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "1.5rem",
                padding: "1.6rem",
                mt: "0.5rem",
                // width: '49%',
              }}
            >
              <ItemComponent
                title={"License No:"}
                value={
                  details?.licenseNo
                    ? details?.licenseNo
                    : details?.labLicenseNo
                }
              />

              <Box
                sx={{
                  display: "flex",
                  pb: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "9.8rem",
                    fontSize: "0.87rem",
                  }}
                >
                  "License Cert File"
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    // backgroundColor: 'bisque'
                  }}
                >
                  {details?.licenseUrl &&
                  details?.licenseUrl?.includes("pdf") ? (
                    <a
                      href={details?.licenseUrl}
                      download
                      style={{
                        fontSize: "0.87rem",
                        display: "flex",
                        gap: "1rem",
                        textDecoration: "none",
                      }}
                    >
                      <img src="/assets/pdf.svg" alt={"License Cert File"} />
                      <span>View PDF</span>
                    </a>
                  ) : details?.licenseUrl ? (
                    <a
                      href={details?.licenseUrl}
                      download
                      style={{
                        fontSize: "0.87rem",
                        display: "flex",
                        gap: "1rem",
                        textDecoration: "none",
                      }}
                    >
                      {/* <img src="/assets/pdf.svg" alt={"License Cert File"} /> */}
                      <BsImage size={20} />
                      <span>View Image</span>
                    </a>
                  ) : (
                    ""
                  )}
                  {details?.labLicenseUrl &&
                  details?.labLicenseUrl?.includes("pdf") ? (
                    <a
                      href={details?.labLicenseUrl}
                      download
                      style={{
                        fontSize: "0.87rem",
                        display: "flex",
                        gap: "1rem",
                        textDecoration: "none",
                      }}
                    >
                      <img src="/assets/pdf.svg" alt={"License Cert File"} />
                      <span>View PDF</span>
                    </a>
                  ) : details?.labLicenseUrl ? (
                    <a
                      href={details?.labLicenseUrl}
                      download
                      style={{
                        fontSize: "0.87rem",
                        display: "flex",
                        gap: "1rem",
                        textDecoration: "none",
                      }}
                    >
                      {/* <img src="/assets/pdf.svg" alt={"License Cert File"} /> */}
                      <BsImage size={20} />
                      <span>View Image</span>
                    </a>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
            <Box
              sx={{
                mt: { xs: "1.5rem", md: "1.5rem" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#343673",
                }}
              >
                {`${
                  details?.pharmacyName
                    ? "Pharmacy "
                    : details?.labName
                    ? "Lab"
                    : "Hospital"
                } Status:`}
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "1.5rem",
                  padding: "1.6rem",
                  mt: "0.5rem",
                  // width: '49%',
                }}
              >
                <ItemComponent
                  title={"Status:"}
                  value={details?.active ? "Active" : "Inactive"}
                />

                <ItemComponent
                  title={"Level:"}
                  value={details?.approved ? "Approved" : "Unapproved"}
                />

                {/* </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "54%" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#343673",
              mt: "1.5rem",
            }}
          >
            Address Information
          </Typography>

          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "1.5rem",
              // display: 'flex',
              padding: "1.6rem",
              mt: "0.5rem",
              // width: '49%',
            }}
          >
            <ItemComponent
              title={"Address:"}
              value={
                details?.pharmacyAddress
                  ? details?.pharmacyAddress
                  : details?.labAddress
                  ? details?.labAddress
                  : details?.address
              }
            />
            <ItemComponent
              title={"City:"}
              value={details?.city ? details?.city : details?.city}
            />
            <ItemComponent title={"State:"} value={details?.state} />
            <ItemComponent title={"LGA:"} value={details?.lga} />
            <ItemComponent
              title={"Country:"}
              value={details?.country || "Nigeria"}
            />
          </Box>
          {/* </Box> */}
        </Box>
        {images?.length > 0 && (
          <Box
            sx={{
              mt: { xs: "1.5rem", md: "1.5rem" },
              width: "55%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#343673",
              }}
            >
              {`${
                details?.pharmacyName
                  ? "Pharmacy"
                  : details?.labName
                  ? "Laboratory"
                  : "Hospital"
              } Images`}
            </Typography>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "1.5rem",
                padding: "1.6rem",
                mt: "0.5rem",
                width: "100%",
              }}
            >
              {/* <Grid container spacing={2}>
                {images.map((image) => (
                  <Grid item key={image} xs={12} sm={6} md={6} lg={4}>
                    <img src={image} alt="" style={{ maxWidth: "100%" }} />
                  </Grid>
                ))}
              </Grid> */}

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {images.map((image, index) => (
                  <Box
                    key={image}
                    sx={{
                      flex: "1 1 50%",
                      maxWidth: "50%",
                      "@media (min-width: 960px)": {
                        flex: "1 1 33.33%",
                        maxWidth: "33.33%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        paddingTop: "100%",
                        width: "100%",
                        height: 0,
                      }}
                    >
                      <img
                        src={image}
                        alt=""
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        onLoad={(e) =>
                          (e.target.parentNode.style.height = `${e.target.clientHeight}px`)
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default FacilityDetailPage;
