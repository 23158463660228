import React from 'react';
import Box from '@mui/material/Box';

function Empty({
  title,
  altText,
  linkUrl
}) {
  return (
    <>
      <Box
        component="img"
        alt={altText}
        src={'/assets/illustrations/empty.svg'}
        sx={{
          width: '15rem',
          height: '15rem',
          borderRadius: 1.5,
          flexShrink: 0
        }}
      />
      <Box
        sx={{
          // textAlign: 'justify'
        }}
      >
        {title}
      </Box>
      {
        linkUrl && <a
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
        >
          Doctors onboarding
        </a>
      }
    </>
  )
}

export default Empty