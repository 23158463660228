import React, { useRef, useState, useContext, useEffect } from "react";
import { Input } from "../../../components/shadch/ui/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/shadch/ui/ui/dialog";
import { Button } from "../../../components/shadch/ui/ui/button";
import { Plus } from "lucide-react";
import { ScrollArea } from "../../../components/shadch/ui/ui/scroll-area";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/shadch/ui/ui/form";
import { useForm } from "react-hook-form";
import uploadLogo from "../../../components/icons/uploadLogo.svg";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import apiClient from "../../../http/common";
import { toast } from "react-toastify";
import { ReloadIcon } from "@radix-ui/react-icons";

const formSchema = z.object({
  SponsorName: z.string().min(1, { message: "Company Name is required" }),
  Email: z.string().email("Invalid email address "),
  dateOfRegistration: z.string().min(1, "Date of Registration is required"),
  PhoneNumber: z
    .string()
    .min(8, { message: "Phone number should at least be 8 digits" })
    .max(12, {
      message: "Normal phone number shouldn't be more than 12 digits",
    }),
});

const AddNewHealth = () => {
  const fileInputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setFileName] = useState(
    fileInputRef?.current?.files[0]?.name
  );

  const form = useForm({
    formSchema: zodResolver(formSchema),
    defaultValues: {
      SponsorName: "",
      Email: "",
      dateOfRegistration: "",
      PhoneNumber: "",
      SponsorLogo: "",
    },
  });

  // useEffect(() => {
  //  apiClient.get(`sponsor/GetCustomSponsors`)
  //  .then((response) => {console.log(response.data.data);})
  //  .catch((error) => {console.log(error);})
  // }, [])

  const onSubmit = async (data) => {
    setOpen(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("SponsorName", data?.SponsorName);
    formData.append("Email", data?.Email);
    formData.append("PhoneNumber", data?.PhoneNumber);
    formData.append("SponsorLogo", fileInputRef.current.files[0]);

    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    apiClient
      .post(`/sponsor/AddCustomSponsor`, formData)
      .then((response) => {
        setIsLoading(false);
        console.log(response.data.message);
        if (response) {
          toast.success(response?.data?.message, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 2000,
          });
          window.location.reload();
        } else {
          toast.error(response?.response.statusText, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(false);
  };

  const handleFileChanges = (e) => {
    setFileName(fileInputRef?.current?.files[0]?.name);
  };

  const handleParentClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="bg-white">
      <div className="w-full">
        <Dialog
          className="bg-white"
          isOpen={open}
          onDismiss={() => setOpen(false)}
        >
          <DialogTrigger asChild>
            <Button className="bg-sky-500 text-white h-12 px-10">
              {" "}
              <Plus className="mr-2" /> Create New
            </Button>
          </DialogTrigger>

          <DialogContent className="max-w-2xl">
            <Form {...form}>
              <form
                className="[&_label]:font-semibold"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <DialogHeader>
                  <DialogTitle className="text-left px-7 text-2xl text-blue-900">
                    Create New Insurance Company
                  </DialogTitle>
                </DialogHeader>
                <ScrollArea className="h-auto">
                  <div id="form-fields" className="grid gap-8 px-3 mt-5">
                    <FormField
                      control={form.control}
                      name="SponsorName"
                      render={({ field }) => (
                        <FormItem variant="customOutlined">
                          <FormLabel>Health Insurance Company Name</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="health insurance company name"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="Email"
                      render={({ field }) => (
                        <FormItem variant="customOutlined">
                          <FormLabel>Email Address</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Enter Email Address"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {/* <FormField
                      control={form.control}
                      name="dateOfRegistration"
                      render={({ field }) => (
                        <FormItem variant="customOutlined">
                          <FormLabel>Date of registeration</FormLabel>
                          <FormControl className="">
                            <DatePicker
                              placeholder="Select"
                              defaultValue={field.value}
                              btnClassName="hover:text-foreground hover:bg-transparent"
                              onValueChange={field.onChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    /> */}
                    <FormField
                      control={form.control}
                      name="PhoneNumber"
                      render={({ field }) => (
                        <FormItem variant="customOutlined">
                          <FormLabel>Phone Number</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Enter Phone Number"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div
                      className="border-dotted relative w-full border pt-2 bg-sky-50 border-sky-600 rounded-lg h-36 px-10 flex flex-col items-center"
                      onClick={() => handleParentClick()}
                    >
                      <input
                        onChange={handleFileChanges}
                        type="file"
                        className="w-full h-full absolute hidden"
                        ref={fileInputRef}
                        required={true}
                      />
                      <img src={uploadLogo} alt="" className="text mb-1" />
                      {fileInputRef?.current?.files[0] && (
                        <h1 className="text-green-600 text-sm">
                          A Sponsor logo is attached
                        </h1>
                      )}
                      {!fileInputRef?.current?.files[0] && (
                        <h1 className="text-red-600 text-sm">
                          Click to upload A sponsor logo
                        </h1>
                      )}
                      <div className="h-2"></div>
                      <button
                        className={
                          "px-8 py-1 bg-white border border-sky-200 rounded-md text-sky-500 font-bold"
                        }
                      >
                        Select a file
                      </button>
                      <div className="h-1"></div>
                      <div className="text-sky-500 text-sm">
                        ( Max. file size: 20MB )
                      </div>
                    </div>
                  </div>
                </ScrollArea>
                <div className="text mt-2 px-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`text-white ${
                      isLoading ? "bg-[#76d7fa]" : "bg-[#2CBCEF]"
                    } w-full py-3 rounded`}
                  >
                    {isLoading ? (
                      <div className=" flex items-center justify-center">
                        {" "}
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        Submitting...
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default AddNewHealth;
