import { useState } from "react";
// @mui
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// mock
import TransitionsModal from "./PartnerModal";
import PartnerListToolbar from "./PartnerListToolbar";
import PartnerListHead from "./PartnerListHead";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function PartnerPageTable({
  id: tableId,
  selected,
  filterName,
  handleFilterByName,
  filterRole,
  handleFilterByRole,
  order,
  orderBy,
  handleRequestSort,
  handleSelectAllClick,
  filteredUsers,
  mainList,
  rowsPerPage,
  handleClick,
  handleOpenMenu,
  emptyRows,
  isNotFound,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  open,
  handleCloseMenu,
  TABLE_HEAD,
  approvalRequestHandler,
  tabsValue,
  isLoading,
  ApprovePartnerLoading,
}) {
  const [partnerId, setPartnerId] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = (doc) => {
    setSelectedPartner(doc);
    // setOpenModal(true);
  };
  const handleModalClose = () => setOpenModal(false);
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{}}>
        <PartnerListToolbar
          numSelected={selected.length}
          // userSpecializations={userSpecializations}
          filterName={filterName}
          onFilterName={(event) => handleFilterByName(event, tableId)}
          // filterRole={filterRole}
          // onFilterRole={(event) => handleFilterByRole(event, tableId)}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <PartnerListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mainList.length}
                numSelected={selected.length}
                onRequestSort={(event, property) =>
                  handleRequestSort(event, property, tableId)
                }
                onSelectAllClick={(event) =>
                  handleSelectAllClick(event, tableId)
                }
              />
              <TableBody>
                {!!filteredUsers.length &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        businessName,
                        email,
                        phoneNumber,
                        address,
                        logoUrl,
                        created,
                      } = row;

                      const selectedUser =
                        !!selected && selected.indexOf(businessName) !== -1;
                      const date = new Date(created);
                      const formattedDate = `${date.getFullYear()}/${
                        date.getMonth() + 1
                      }/${date.getDate()}`;
                      const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          onClick={(event) => setSelectedPartner(row)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUser}
                              onChange={(event) =>
                                handleClick(event, businessName, tableId)
                              }
                            />
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar alt={businessName} src={logoUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {businessName}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {email}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {phoneNumber}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {address}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleModalOpen(row)}
                          >
                            {formattedDate}
                            <br /> {formattedTime}
                          </TableCell>

                          {/* {
                        !tabsValue && <TableCell
                          align="left"
                          onClick={(event) => navigate(
                            "/facility", {
                            state: {
                              id: row.id,
                              businessName: row.businessName
                            }
                          })}
                        >
                          <Label color='primary'>
                            Add Facility
                          </Label>
                        </TableCell>
                      } */}

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                setPartnerId(id);
                                setSelectedPartner(row);
                                handleOpenMenu(event, tableId);
                              }}
                            >
                              <Iconify
                                icon={"eva:more-vertical-fill"}
                                sx={{
                                  color: "primary.main",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //IMPORTANT // count={mainList.length} //DO NOT REMOVE
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) =>
            handleChangePage(event, newPage, tableId)
          }
          onRowsPerPageChange={(event) =>
            handleChangeRowsPerPage(event, tableId)
          }
        />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu(tableId)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {!!tabsValue && (
          <MenuItem
            onClick={(event) => {
              approvalRequestHandler(partnerId, tableId);
              // handleCloseMenu(tableId)
            }}
            disabled={ApprovePartnerLoading}
          >
            {ApprovePartnerLoading ? (
              <Iconify
                icon={"line-md:loading-twotone-loop"}
                sx={{
                  mr: 2,
                  color: "primary.main",
                }}
              />
            ) : (
              <Iconify
                icon={"eva:compass-fill"}
                sx={{
                  mr: 2,
                }}
              />
            )}
            Approve
          </MenuItem>
        )}
        {!tabsValue && (
          <MenuItem
            onClick={(event) =>
              navigate("/facility", {
                state: {
                  id: selectedPartner.id,
                  businessName: selectedPartner.businessName,
                },
              })
            }
          >
            <Iconify icon={"material-symbols:add-home-sharp"} sx={{ mr: 2 }} />
            Add Facility
          </MenuItem>
        )}

        <MenuItem
          onClick={(event) => {
            navigate(`/partner-detail/${selectedPartner?.id}`, {
              state: selectedPartner,
            });
          }}
          // sx={{ color: 'error.main' }}
        >
          <Iconify icon={"material-symbols:grid-view-rounded"} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <TransitionsModal
        selectedPartner={selectedPartner}
        openModal={openModal}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        approvalRequestHandler={approvalRequestHandler}
        isLoading={isLoading}
      />
    </>
  );
}
