import React from "react";
import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import MyInput from "../../../components/commonElements/input/MyInput";
import ImageUpload from "../../../components/commonElements/image/ImageUpload";
import FormikSubmitButton from "../../../components/buttons/FormikSubmitButton";
import ResetButton from "../../../components/buttons/ResetButton";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

// const SUPPORTED_FORMATS = [
//   "pdf",
//   "png",
//   "jpg",
//   "jpeg",
//   "docs",
//   "docx",
//   "PNG",
//   "JPEG",
//   "HEIF",
//   "HEIC",
//   "heic",
//   "GIF",
//   "TIFF",
//   "PDF",
// ];

function PartnerForm({ partnerCreationHandler, partnerCreationMutation }) {
  const [previewUrl, setPreviewUrl] = useState();
  // const { values, dirty, isSubmitting } = useFormikContext();

  const INITIAL_FORM_STATE = {
    Email: "",
    BusinessName: "",
    PhoneNumber: "",
    Address: "",
    Logo: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    Email: Yup.string().email("Invalid email.").required("Required"),
    BusinessName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    PhoneNumber: Yup.number()
      .integer()
      .typeError("Please enter a valid phone number")
      .required("Required"),
    Address: Yup.string()
      .min(5, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    Logo: Yup.mixed().required("A file is required"),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // )
  });

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 3 }}>
        <Container maxWidth="sm">
          {/* <StyledContent> */}
          <Typography variant="h4" gutterBottom>
            Create Partner
          </Typography>

          {/* <ErrorModal error={error} onClear={clearError} /> */}
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={partnerCreationHandler}
          >
            <Form>
              <Stack spacing={3} sx={{ pt: 3, pb: 8 }}>
                <MyInput
                  id="BusinessName"
                  name="BusinessName"
                  type="text"
                  label="Business Name"
                />

                <MyInput id="Email" type="email" name="Email" label="E-mail" />

                <MyInput
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="text"
                  label="Phone Number"
                />
                <MyInput
                  id="Address"
                  name="Address"
                  type="text"
                  label="Address"
                />
                <ImageUpload
                  id="Logo"
                  name="Logo"
                  label="UPLOAD YOUR LOGO"
                  previewUrl={previewUrl}
                  setPreviewUrl={setPreviewUrl}
                  supportedFormat={SUPPORTED_FORMATS}
                />
                <ResetButton onClick={(e) => setPreviewUrl("")} />
                <FormikSubmitButton
                  queryisloading={partnerCreationMutation.isLoading.toString()}
                  sx={{ mt: 8 }}
                >
                  Create Partner
                </FormikSubmitButton>
              </Stack>
            </Form>
          </Formik>
          {/* </StyledContent> */}
        </Container>
      </Box>
    </div>
  );
}

export default PartnerForm;
