import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Iconify from '../../components/iconify';

function BackButton({
  onClick
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <IconButton>
        <Iconify icon="mdi:arrow-back" />
      </IconButton>
      <Typography>
        Go back
      </Typography>
    </Box>
  )
}

export default BackButton
