import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Iconify from "../components/iconify";
import BackButton from "../layouts/backButton";
import apiClient from "../http/common";
import { useQuery } from "@tanstack/react-query";
import { BsImage } from "react-icons/bs";

const account = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: "/assets/images/avatars/avatar_default.jpg",
};

const ItemComponent = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        pb: "1rem",
        // backgroundColor: 'darkolivegreen',
      }}
    >
      <Typography
        sx={{
          width: "9.8rem",
          fontSize: "0.87rem",
          // backgroundColor: 'firebrick',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "auto",
          align: "left",
          fontWeight: "medium",
          fontSize: "0.87rem",
          // backgroundColor: 'cornsilk',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

function PatientDetailPage() {
  let location = useLocation();
  const params = useParams();
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const id = params.id;
  const details = location?.state;

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          // padding: '1.6rem',
          mt: "1.5rem",
          flexWrap: "wrap",
          // width: '50%',
        }}
      >
        <Box
          sx={{
            // width: { xs: '100%', md: '54%' },
            width: { xs: "100%", md: "54%" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#343673",
            }}
          >
            Name
          </Typography>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "1.5rem",
              display: "flex",
              flexWrap: { xs: "wrap", sm: "no-wrap" },
              padding: "1.6rem",
              mr: "1.0rem",
              mt: "0.5rem",
              // width: '49%',
              // backgroundColor: 'aquamarine'
            }}
          >
            {/* <Box
              sx={{
                // backgroundColor: 'bisque'
                width: { xs: '100%', sm: '5.5rem' },
              }}
            > */}
            <Avatar
              src={details?.imageUrl || account.photoURL}
              alt={details?.firstName || "photoURL"}
              sx={{
                width: { xs: "7.5rem", sm: "5.5rem" },
                height: { xs: "7.5rem", sm: "5.5rem" },
                mr: { xs: "auto", sm: "1.0rem" },
                ml: { xs: "auto", sm: "0rem" },
                mb: { xs: "auto", lg: "0.5rem" },
              }}
            />
            {/* </Box> */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                mt: { xs: "1.5rem", sm: "0rem" },
              }}
            >
              <ItemComponent title="Name" value={details?.patientName} />
              <ItemComponent
                title="Marital Status"
                value={details?.maritalStatus}
              />
              <ItemComponent title={"Email:"} value={details?.email} />
              <ItemComponent title={"Contact:"} value={details?.phoneNumber} />
              <ItemComponent
                title={"Occupation:"}
                value={details?.occupation}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default PatientDetailPage;
