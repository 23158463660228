import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingBackdrop({
  open,
  handleLoadingClose,
  styles,
  children
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  // return <div> LoadingBackdrop</div>

  return (
    <div style={{ "position": "relative" }}>
      <Backdrop
        sx={{
          // color: '#fff',
          // background: '#DFE3E8',
          background: 'rgba(145, 158, 171, 0.12)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          height: '100%',
          ...styles
          // display: 'block',
        }}
        open={open}
        onClick={handleLoadingClose}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      {children}
    </div>
  );
}

export function NewLoadingBackdrop({
  open,
  handleLoadingClose,
  styles,
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  // return <>
  //   {open ? <div> dfdfLoadingBackdrop</div> : <></>}
  //   </>
  

  return (
    <Backdrop
      sx={{
        // color: '#fff',
        // background: '#DFE3E8',
        backgroundColor: 'rgba(145, 158, 171, 0.12)',
        // zIndex: (theme) => theme.zIndex.drawer + 1,
        zIndex: 5,
        // position: 'absolute',
        // height: '100%',
        ...styles
        // display: 'block',
      }}
      open={open}
      // onClick={handleLoadingClose}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}