import { Box } from "@mui/material";
import React from "react";
import useContract from "../hooks/useContract";
import CustomTab from "../components/Tab/Tab";
import ContractCard from "../components/ContractCard/ContractCard";
import { useNavigate } from "react-router-dom";

const Contract = () => {
  const { tab, setTab } = useContract();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: "1rem",
        padding: "1.5rem",
      }}
    >
      <CustomTab
        setTab={setTab}
        tabNumber={1}
        isSelected={tab === 1}
        text="CONTRACT TYPES"
      />
      <div style={{ display: "flex", columnGap: "4rem", marginTop: "3rem" }}>
        <div
          style={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px dashed #808080 ",
            borderRadius: "12px",
            width: "302px",
            height: "200px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/addcontract")}
        >
          <p style={{ fontSize: "30px", color: "#2CBCEF" }}>+</p>
          <p style={{ marginTop: "-10px" }}>Add new</p>
        </div>
        <ContractCard />
      </div>
    </Box>
  );
};

export default Contract;
