import React from "react";

const EmptyComponent = ({ className, svgPicture,title,caption }) => {
    return (
        <div>
            <img
                src={svgPicture}
                alt=""
                className={` ${className ? className : ""} mx-auto text-[#2CBCEF]`}
            />
            <p className="text font-bold text-black text-center">{title}</p>
            <p className="text-center text-sm ">
                {caption}
            </p>
        </div>
    );
};

export default EmptyComponent;
