import React, { useState, useContext } from "react";
import { Box, Card, Container, Tab, Tabs } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CANNED_TABLE_HEAD } from "../sections/@dashboard/settings/CannedHelperFunction";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AuthContext } from "../context/auth-context";
import CustomTab from "../components/Tab/Tab";
import Iconify from "../components/iconify/Iconify";
import CannedCoverageTable from "../sections/@dashboard/settings/CannedCoverageTable";
import useCannedCoverage from "../sections/@dashboard/settings/CannedCoverageHook";
import { a11yProps, TabPanel } from "../utils/helper";
import Empty from "../components/commonElements/empty/empty";

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  backgroundColor: "white",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  [theme.breakpoints.up("sm")]: {
    // width: 240,
    width: 280,
    marginTop: 0,
    // marginLeft: "1rem",
  },
}));

const Settings = () => {
  const auth = useContext(AuthContext);
  const {
    CannedCoverages,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    isNotFound,
    handleSelectAllClick,
    emptyRows,
    FilteredCoverages,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    success,
    handleSnackbarClose,
    openCreatePage,
    CannedCoveragePageSwitch,
    approvalTransactionRequestHandler,
  } = useCannedCoverage(auth);

  // console.log(list.length, "list");

  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          padding: "1.5rem",
        }}
      >
        <Container
          sx={{
            mt: 2,
          }}
        >
          <Card>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabsValue}
                onChange={handleTabsValueChange}
                aria-label="Transaction tabs"
              >
                <Tab label="Canned Coverages" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <TabPanel value={tabsValue} index={0}>
              {CannedCoverages?.length < 1 && (
                <Empty
                  altText="Canned Coverages list is empty"
                  title="No Canned coverages yet."
                />
              )}
              {CannedCoverages.length > 0 && (
                <CannedCoverageTable
                  TABLE_HEAD={CANNED_TABLE_HEAD}
                  selected={selected}
                  filterName={filterName}
                  handleFilterByName={handleFilterByName}
                  filterRole={filterRole}
                  handleFilterByRole={handleFilterByRole}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                  handleSelectAllClick={handleSelectAllClick}
                  filteredUsers={FilteredCoverages}
                  mainList={CannedCoverages}
                  rowsPerPage={rowsPerPage}
                  handleClick={handleClick}
                  handleOpenMenu={handleOpenMenu}
                  emptyRows={emptyRows}
                  isNotFound={isNotFound}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  open={open}
                  handleCloseMenu={handleCloseMenu}
                  tabsValue={tabsValue}
                  isLoading={isLoading}
                />
              )}
            </TabPanel>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Settings;
