const CustomCheckbox = ({
    isChecked,
    label,
    onChange,
    className,
    colorLabel,
    required,
    reverse,
  }) => {
    const handleCheckboxChange = (event) => {
      onChange(event.target.checked);
    };
  
    return (
      <label
        className={`inline-flex text-xs items-center ${className}`}>
        <input
          type="checkbox"
          className="hidden"
          checked={isChecked}
          onChange={handleCheckboxChange}
          required={required}
        />
        {reverse && (
          <span
            className={`select-none ${
              colorLabel ? "text-primary" : "text-[#444448]"
            } `}>
            {label}
          </span>
        )}
        <span
          className={`w-5 h-5 border-2 rounded ${
            isChecked ? "border-sky-500" : "border-gray-500"
          } inline-block mr-2 relative`}>
          {isChecked && (
            <svg
              className="fill-current text-sky-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="12"
              height="12">
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            </svg>
          )}
        </span>
        {!reverse && (
          <span
            className={`select-none ${
              colorLabel ? "text-primary" : "text-[#444448]"
            } `}>
            {label}
          </span>
        )}
      </label>
    );
  };
  
  export default CustomCheckbox;
  