import React, { useState } from "react";
const WeekdaysCheckbox = () => {
  const [days, setDays] = useState([]);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleCheckboxChange = (day) => {
    if (days.includes(day)) {
      setDays(days.filter((item) => item !== day));
    } else {
      setDays([...days, day]);
    }
  };

  return (
    <div>
      <p
        style={{
          display: "block",
          color: "#444448",
          fontSize: "14px",
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        Availability period (in a week)
        <span style={{ color: "red" }}>*</span>
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0 2rem",
        }}
      >
        {weekdays.map((day) => (
          <label key={day} className="container">
            <input
              type="checkbox"
              checked={days.includes(day)}
              onChange={() => handleCheckboxChange(day)}
            />
            {day}
            <span className="checkmark" />
          </label>
        ))}
      </div>
    </div>
  );
};

export default WeekdaysCheckbox;
