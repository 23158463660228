import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const StyledViewButton = styled((props) => <Button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const style = {
  position: 'absolute',
  // top: '62%',
  top: '49%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // bgcolor: '#000',
  // border: '1px solid #000',
  borderRadius: '0.4rem',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({
  selectedDoctor,
  openModal,
  handleModalOpen,
  handleModalClose,
  approvalRequestHandler,
  isLoading,
  tabsValue,
  tableId
}) {
  // console.log('tabsValue', tabsValue)
  // const { id, name, role, status, company, address, avatarUrl, isVerified, isApproved } = !!selectedDoctor && selectedDoctor;
  const {
    id,
    name,
    mdcnFolioNo,
    avatarUrl,
    imageUrl,
    consultationPrice,
    yearsOfMedicalExperience,
    areaOfSpecialization,
    gender,
    dateOfBirth,
    isApproved,
    maritalStatus,
    languages,
    status,
  } = !!selectedDoctor && selectedDoctor;

  const date = new Date(dateOfBirth);
  const birthdate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  function diff_years(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.round(diff / 365.25));
  }
  const age = diff_years(date, new Date());
  let navigate = useNavigate();
  // console.log('avatarUrl', avatarUrl)

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          // backgroundColor: 'red'
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Card>
              <Box
                sx={{
                  pt: '75%',
                  bgcolor: '#f5f5f5',
                  // pt: '7%',
                  position: 'relative'
                }}
              >
                {gender && (
                  <Label
                    variant="filled"
                    // color={(gender === 'female' && 'error') || 'info'}
                    sx={{
                      zIndex: 9,
                      top: 16,
                      right: 16,
                      position: 'absolute',
                      textTransform: 'uppercase',
                      color: gender === 'female' ? '#ff090' : '#00A2FF'
                    }}
                  >
                    {/* {gender} */}
                    {
                      gender === 'female' ? <Iconify icon="fontisto:famale" /> : <Iconify icon="fontisto:male" />
                    }
                  </Label>
                )}
                <StyledProductImg alt={name} src={imageUrl} />
              </Box>

              <Stack
                spacing={0.5}
                sx={{
                  p: 1
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  textTransform={'capitalize'}
                >
                  {name}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    // color: 'text.disabled',
                    // bgcolor: 'yellow',
                    // py: '0rem',
                    // mt: '0rem',
                    // mb: '3rem',
                  }}
                >
                  {mdcnFolioNo}
                </Typography>

                <Link color="inherit" underline="hover">
                  <Typography variant="subtitle2" noWrap>
                    {areaOfSpecialization}
                  </Typography>
                </Link>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  {
                    !!tabsValue && <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        // color: 'text.disabled',
                      }}
                    >
                      {/* {new Date(dateOfBirth).toDateString()} */}
                      {`${age}yrs (${birthdate})`}
                    </Typography>
                  }
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      // color: 'text.disabled',
                    }}
                  >
                    {consultationPrice}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      // color: 'text.disabled',
                    }}
                  >
                    Experience: {yearsOfMedicalExperience}yrs
                  </Typography>
                  {
                    !!tabsValue && <Label
                      color={
                        maritalStatus === 'divorce' ? 'error' : maritalStatus === 'single' ? 'warning' : 'success'
                      }
                    >
                      {maritalStatus}
                    </Label>
                  }
                </Stack>

                {/* <Box>
                  <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />
                  <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                  <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />
                  <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />
                </Box> */}

                <Typography variant="body1" noWrap
                  textTransform={'capitalize'}
                  sx={{
                    // color: 'red',
                  }}
                >
                  {/* dfjkl */}
                  {languages?.join(', ')}.
                </Typography>
                <Stack
                  spacing={1.5}
                  sx={{
                    // p: 1
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      mt: !!tabsValue ? '1rem' : '0rem',
                    }}
                    onClick={(event) => navigate(`/doctors/${id}`, { state: selectedDoctor })}
                  >
                    View Profile
                  </Button>
                  {
                    !!tabsValue && <Button
                      variant="contained"
                      sx={{

                      }}
                      disabled={isLoading}
                      onClick={(event) => {
                        approvalRequestHandler(id, tableId);
                      }}
                    >
                      {
                        isLoading ? <Iconify
                          icon={'line-md:loading-twotone-loop'}
                          sx={{
                            mr: 2,
                            color: 'primary.main'
                          }}
                        /> : null
                      }
                      Approve Doctors
                    </Button>
                  }
                </Stack>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box>jh</Box>
                  <Typography variant="subtitle1">
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        color: 'text.disabled',
                        textDecoration: 'line-through',
                      }}
                    >
                      {consultationPrice && fCurrency(consultationPrice)}
                    </Typography>
                    &nbsp;
                    {fCurrency(consultationPrice)}
                  </Typography>
                </Stack> */}
              </Stack>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}