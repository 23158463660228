import { useState } from "react";
import EyeIconClose from "../icons/EyeIconClose.svg";
import { AiOutlineEye } from "react-icons/ai";

const InputField = ({
  label,
  type,
  options,
  id,
  name,
  value,
  handleChange,
  placeholder,
  boldLabel,
  register,
  required,
  accessor,
  disabled,
  symbol,
  max,
  min
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handleFocus = () => setFocused(true);
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(document.activeElement)) {
      setFocused(false);
    }
  };

  function validateEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  }


  const renderInput = () => {
    switch (type) {
      case "select":
        return (
          <select
            id={id}
            name={name}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            {...((register &&
              register(name, {
                required: {
                  value: required,
                  message: `${label} is required`,
                },
              })) ||
              {})}
            className="block w-full py-1 text-secondaryLight bg-white  focus:outline-none"
          >
            <option className="" value={""}>
              Select
            </option>
            {options?.map((option, index) => (
              <option className="" key={option[id]} value={option[id]}>
                {option[accessor]}
              </option>
            ))}
          </select>
        );
      case "radio":
      case "checkbox":
        return options.map((option) => (
          <label key={option.value}>
            <input
              type={type}
              name={name}
              disabled={disabled}
              value={option.value}
              onChange={handleChange}
            />
            {option.label}
          </label>
        ));
      case "password":
        return (
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id={id}
              name={name}
              value={value}
              disabled={disabled}
              onChange={handleChange}
              {...((register &&
                register(name, {
                  // disabled: disabled,
                  required: {
                    value: required,
                    message: `${label} is required`,
                  },
                })) ||
                {})}
              placeholder={placeholder}
              className="block w-full py-0.5   bg-white rounded-md focus:outline-none  sm:text-sm"
            />
            <div
              className="absolute inset-y-0 -translate-y-2 right-0 pr-1 flex items-center cursor-pointer"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <img src={EyeIconClose} alt="password icon locked" />
              ) : (
                <AiOutlineEye color="#808080" size="24px" />
              )}
            </div>
          </div>
        );
      default:
        return (
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            max={max}
            disabled={disabled}
            min={min}
            onChange={handleChange}
            {...(register &&
              register(name, {
                disabled: disabled,
                required: {
                  value: required,
                  message: `${label} is required`,
                },
                validate: (fieldValue) => {
                  if (name === "email") {
                    return (
                      validateEmail(fieldValue) || "Enter a valid email address"
                    );
                  }
                },
              }))}
            placeholder={placeholder}
            className="block w-full   text-secondaryLight bg-white focus:outline-none text-[12px] "
          />
        );
    }
  };

  return (
    <div
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex="0"
      className={`mb-3 w-full h-[55px] border-[0.1px] bg-white ${
        isFocused ? "border-primary" : "border-[#c4c1c1]"
      } rounded-md py-1 px-4`}
    >
      {label && (
        <label
          htmlFor={id}
          className={`block text-start ${
            boldLabel ? "text-[14px] font-[600]" : "text-[12px] font-[600]"
          }  text-[#444448] `}
        >
          {label}
        </label>
      )}
      {renderInput()}
    </div>
  );
};

export default InputField;
