import { useEffect, useState } from 'react';

import { useHttpClient } from '../../../hooks/http-hook';
import { baseUrl } from '../../../utils/helper';
import { reactAppSubscriptionKey } from '../../../http/common';

function useApp(auth) {
  const { token } = auth;

  const [success] = useState({});

  const [doctorTotalNo, setDoctorTotalNo] = useState();
  const [patientTotalNo, setPatientTotalNo] = useState();
  const [pharmaciesTotalNo, setPharmaciesTotalNo] = useState();
  const [labsTotalNo, setLabsTotalNo] = useState();
  const [consultationsTotalNo, setConsultationsTotalNo] = useState();

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchDoctorTotalNo = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetDoctorTotalNo`,
          undefined,
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('unapproved facilities', responseData)
        setDoctorTotalNo(responseData?.data);
      } catch (err) { }
    };
    fetchDoctorTotalNo();
  }, [sendRequest, token, success.value]);

  useEffect(() => {
    const fetchPatientTotalNo = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetPatientTotalNo`,
          undefined,
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('patientTotalNo', responseData)
        setPatientTotalNo(responseData?.data);
      } catch (err) { }
    };
    fetchPatientTotalNo();
  }, [sendRequest, token, success.value]);

  useEffect(() => {
    const fetchPharmaciesTotalNo = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetPharmaciesTotalNo`,
          undefined,
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('fetchPharmaciesTotalNo', responseData)
        setPharmaciesTotalNo(responseData?.data);
      } catch (err) { }
    };
    fetchPharmaciesTotalNo();
  }, [sendRequest, token, success.value]);

  useEffect(() => {
    const fetchLabsTotalNo = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetLabsTotalNo`,
          undefined,
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('LabsTotalNo', responseData)
        setLabsTotalNo(responseData?.data);
      } catch (err) { }
    };
    fetchLabsTotalNo();
  }, [sendRequest, token, success.value]);
  
  useEffect(() => {
    const fetchConsultationTotalNo = async () => {
      try {
        const responseData = await sendRequest(
          `${baseUrl}/report/api/AdminReport/GetConsultationsTotalNo`,
          undefined,
          undefined,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
          }
        );
        // console.log('ConsultationTotalNo', responseData)
        setConsultationsTotalNo(responseData?.data);
      } catch (err) { }
    };
    fetchConsultationTotalNo();
  }, [sendRequest, token, success.value]);

  return ({
    doctorTotalNo: doctorTotalNo,
    patientTotalNo: patientTotalNo,
    pharmaciesTotalNo: pharmaciesTotalNo,
    labsTotalNo: labsTotalNo,
    consultationsTotalNo
  })
}

export default useApp
