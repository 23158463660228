import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
// component
import Iconify from "../../../components/iconify";
import DateRangePicker from "./DateRangePicker ";
import { useState } from "react";
import { Button, Modal, Popover } from "@mui/material";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 46,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  [theme.breakpoints.up("sm")]: {
    width: 240,
    marginTop: 0,
    marginLeft: "1rem",
  },
}));

// ----------------------------------------------------------------------

ConsultationListToolbar.propTypes = {
  numSelected: PropTypes.number,
  userSpecializations: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
};

export default function ConsultationListToolbar({
  numSelected,
  userSpecializations,
  filterName,
  onFilterName,
  filterRole,
  onFilterRole,
  //csv
  startDate,
  endDate,
  handleDateChange,
  handleExportToCSV,
}) {
  const [open, setOpen] = useState(null);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextField
          id="consultation-select-role"
          select
          // component='div'
          label="Category"
          defaultValue="All"
          // helperText="Please select your currency"
          sx={{
            width: { xs: "100%", sm: "15rem" },
            "& fieldset": {
              borderWidth: `1px !important`,
              borderColor: `#dcdcdc !important`,
            },
          }}
          value={filterRole}
          onChange={onFilterRole}
        >
          <MenuItem key={0} value="All">
            All
          </MenuItem>
          {!!userSpecializations &&
            userSpecializations.map((option, index) => (
              <MenuItem
                key={option.id + (index + 1) + option.name}
                value={option.name}
              >
                {option.name}
              </MenuItem>
            ))}
        </TextField>
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: "text.disabled",
                  width: 20,
                  height: 20,
                }}
              />
            </InputAdornment>
          }
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <Button
            sx={{
              border: "1px solid grey",
              color: "black",
              fontWeight: "600",
              backgroundColor: "#FAFAFA",
              borderRadius: "4px",
              boxShadow: "2px 2px #FAFAFA",
              "&:hover": {
                boxShadow: "3px 4px #FAFAFA",
                backgroundColor: "#FAFAFA",
              },
              marginTop: {
                md: 0,
                sm: 4,
                xs: 4,
              },
            }}
            onClick={handleOpenMenu}
          >
            Export as csv
          </Button>
        </div>
      </Box>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            color: "primary.main",
            bgcolor: "primary.lighter",
          }),
        }}
      >
        {/* {numSelected > 0 && (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )} */}

        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        ) : null} */}
      </StyledRoot>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleCloseMenu()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 3,
            mt: {
              md: "200px",
              xs: "340px",
            },
            mb: 10,
            ml: {
              lg: "-100px",
              md: "-100px",
            },
            width: 200,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          handleExportToCSV={handleExportToCSV}
        />
      </Popover>
    </>
  );
}
