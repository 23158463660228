import { useContext } from 'react';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// component
import Iconify from '../components/iconify';
import { TABLE_HEAD } from '../sections/@dashboard/partners/PartnersHelperFunctions';
import { AuthContext } from '../context/auth-context';
import ErrorModal from '../components/Modal/ErrorModal';
import usePartner from '../sections/@dashboard/partners/partnersHook';
import PartnerPageTable from '../sections/@dashboard/partners/PartnerPageTable';
import TransitionsSnackbar from '../components/snackBar';
import LoadingBackdrop, { NewLoadingBackdrop } from '../components/loader';
import { a11yProps, TabPanel } from '../utils/helper';
import PartnerForm from '../sections/@dashboard/partners/PartnerForm';
import Empty from '../components/commonElements/empty/empty';
import BackButton from '../layouts/backButton';

export default function PartnerPage() {
  const auth = useContext(AuthContext);
  const {
    approvedPartners,
    unApprovedPartners,
    unApprovedLoading, unApprovedError, unApprovedIsError,
    approvedLoading, approvedError, approvedIsError,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    partnerCreationHandler,
    isLoading, error, clearError, open,
    page, order, selected, orderBy, filterName, filterRole, rowsPerPage,
    approvedIsNotFound, unApprovedIsNotFound, handleSelectAllClick,
    emptyRowsApproved, emptyRowsUnApproved, filteredApproved,
    filteredUnApproved, handleFilterByRole, handleFilterByName, handleChangeRowsPerPage,
    handleChangePage, handleClick, handleRequestSort,
    success, handleSnackbarClose, openCreatePage, PartnerPageSwitch,
    switchList, switchForm,
    approvalPartnerRequestHandler, partnerCreationMutation,
    ApprovePartnerLoading
  } = usePartner(auth)

  // console.log('error', error)

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <TransitionsSnackbar
        open={success.value}
        message={success.message}
        onClose={handleSnackbarClose}
      />
      {/* <LoadingBackdrop
        open={isLoading && !openCreatePage}
        // open={true}
        styles={{
          marginTop: '12rem',
          mx: '1.5rem',
          borderRadius: '0.75rem',
        }}
      > */}
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={openCreatePage ? "space-between" : "flex-end"}
          mb={3}
          mt={2}
        >
          {/* {
            !openCreatePage && <Typography variant="h4" gutterBottom>
              Partner
            </Typography>
          } */}
          {
            openCreatePage && <BackButton onClick={switchList} />
          }
          {
            !openCreatePage && <Button
              variant="contained"
              // color={
              //   openCreatePage ? 'warning' : 'success'
              // }
              color='primary'
              sx={{
                color: '#FFFFFF'
              }}
              startIcon={
                <Iconify
                  // icon={openCreatePage ? "eva:list-outline" : "eva:plus-fill"}
                  icon="eva:plus-fill"
                />
              }
              onClick={switchForm}
            >
              {/* {openCreatePage ? 'Partner List' : 'Create Partner'} */}
              Create Partner
            </Button>
          }
        </Stack>

        {
          !openCreatePage && <Card>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabsValue}
                onChange={handleTabsValueChange}
                aria-label="Partner tabs"
              >
                <Tab label="Approved" {...a11yProps(0)} />
                <Tab label=" Unapproved" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabsValue} index={0}>
              {
                approvedPartners?.length < 1 && <Empty
                  altText='Partner list is empty'
                  title='No approved partner found, please approve a partner from the unapproved partners list'
                />
              }
              {
                approvedPartners?.length > 0 && <PartnerPageTable
                  id='approved'
                  selected={selected.approved}
                  filterName={filterName.approved}
                  handleFilterByName={handleFilterByName}
                  filterRole={filterRole.approved}
                  handleFilterByRole={handleFilterByRole}
                  order={order.approved}
                  orderBy={orderBy.approved}
                  handleRequestSort={handleRequestSort}
                  handleSelectAllClick={handleSelectAllClick}
                  filteredUsers={filteredApproved}
                  mainList={approvedPartners}
                  rowsPerPage={rowsPerPage.approved}
                  handleClick={handleClick}
                  handleOpenMenu={handleOpenMenu}
                  emptyRows={emptyRowsApproved}
                  isNotFound={approvedIsNotFound}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page.approved}
                  open={open.approved}
                  handleCloseMenu={handleCloseMenu}
                  TABLE_HEAD={TABLE_HEAD}
                  tabsValue={tabsValue}
                  isLoading={isLoading}
                />
              }
              {
                approvedIsError ? (
                  <div>An error occurred: {approvedError.message}</div>
                ) : null
              }
              <NewLoadingBackdrop
                open={approvedLoading}
                // open={true}
                styles={{
                  marginTop: { xs: '11.1rem', md: '11.3rem', lg: '11.3rem' },
                  ml: { xs: '1.5rem', lg: '20.0rem' },
                  mr: { xs: '1.5rem', lg: '2.5rem' },
                  borderRadius: '0.75rem',
                }}
              />
            </TabPanel>
            <TabPanel value={tabsValue} index={1}>
              {
                unApprovedPartners?.length < 1 && <Empty
                  altText='Unapproved partners list is empty'
                  title='No unapproved partner found, please sign up a partner'
                />
              }
              {
                unApprovedPartners?.length > 0 && <PartnerPageTable
                  id='unApproved'
                  selected={selected.unApproved}
                  filterName={filterName.unApproved}
                  handleFilterByName={handleFilterByName}
                  filterRole={filterRole.unApproved}
                  handleFilterByRole={handleFilterByRole}
                  order={order.unApproved}
                  orderBy={orderBy.unApproved}
                  handleRequestSort={handleRequestSort}
                  handleSelectAllClick={handleSelectAllClick}
                  filteredUsers={filteredUnApproved}
                  mainList={unApprovedPartners}
                  rowsPerPage={rowsPerPage.unApproved}
                  handleClick={handleClick}
                  handleOpenMenu={handleOpenMenu}
                  emptyRows={emptyRowsUnApproved}
                  isNotFound={unApprovedIsNotFound}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page.unApproved}
                  open={open.unApproved}
                  handleCloseMenu={handleCloseMenu}
                  TABLE_HEAD={TABLE_HEAD}
                  tabsValue={tabsValue}
                  isLoading={isLoading}
                  approvalRequestHandler={approvalPartnerRequestHandler}
                  ApprovePartnerLoading={ApprovePartnerLoading}
                />
              }
              {
                unApprovedIsError ? (
                  <div>An error occurred: {unApprovedError.message}</div>
                ) : null
              }
              <NewLoadingBackdrop
                open={unApprovedLoading}
                styles={{
                  marginTop: { xs: '11.1rem', md: '11.3rem', lg: '11.3rem' },
                  ml: { xs: '1.5rem', lg: '20.0rem' },
                  mr: { xs: '1.5rem', lg: '2.5rem' },
                  borderRadius: '0.75rem',
                }}
              />
            </TabPanel>
          </Card>
        }

        {
          openCreatePage && <Card>
            <PartnerForm
              partnerCreationHandler={partnerCreationHandler}
              partnerCreationMutation={partnerCreationMutation}
            />
          </Card>
        }
      </Container>
      {/* </LoadingBackdrop> */}
    </>
  );
}
