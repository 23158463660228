import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import BlogPage from "./pages/BlogPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/ProductsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import { createUnique } from "./utils/helper";

// ----------------------------------------------------------------------
// const DoctorPage = React.lazy(()=>import('./pages/DoctorPage'))
// const PartnerPage = React.lazy(()=>import('./pages/PartnerPage'))
// const AdminUserPage = React.lazy(()=>import('./pages/AdminUserPage'))
// const FacilityPage = React.lazy(()=>import('./pages/FacilityPage'))
// const ConsultationPage = React.lazy(()=>import('./pages/ConsultationPage'))
// const TransactionPage = React.lazy(()=>import('./pages/TransactionPage'))
// const PatientPage = React.lazy(()=>import('./pages/PatientPage'))

import DoctorPage from "./pages/DoctorPage";
import PartnerPage from "./pages/PartnerPage";
import PartnerDetailPage from "./pages/PartnerDetailPage";
import AdminUserPage from "./pages/AdminUserPage";
import FacilityPage from "./pages/FacilityPage";
import FacilityDetailPage from "./pages/FacilityDetailPage";
import ConsultationPage from "./pages/ConsultationPage";
import TransactionPage from "./pages/TransactionPage";
import PatientPage from "./pages/PatientPage";
import PatientDetailPage from "./pages/PatientDetailPage";
import DetailsPage from "./pages/DoctorPage/DetailsPage";
import Contract from "./pages/Contract";
import AddContract from "./pages/AddContract";
import ContractDetails from "./pages/ContractDetails";
import EditContract from "./pages/EditContract";
import Settings from "./pages/Settings";
import Appointment from "./pages/Appointment";
import SponsorPage from "./pages/SponsorPage";
import SponsorDetailPage from "./pages/SponsorPage/DetailsPage";
import SponsorCustomTable from "./sections/@dashboard/sponsor/SponsorCustomTable";
import PlanDetails from "./components/plans/PlanDetails";
import HealthInsuranceCompany from "./sections/@dashboard/HealthInsurancePage/HealthInsuranceCompany";
import HealthInsuranceDetailsPage from "./sections/@dashboard/HealthInsurancePage/HealthInsuranceDetailsPage";

// ----------------------------------------------------------------------

const availablePermissions = [
  "canAssignPermission",
  "canApproveLmd",
  "canDisapproveLmd",
  "canCreateFacility",
  "canApproveFacility",
  "canDisapproveFacility",
  "canCreatePartner",
  "canApprovePartner",
  "canDisapprovePartner",
];

export default function Router({ token, permissions }) {
  let activeRoutes;

  if (token && permissions && !!permissions.length) {
    const unique = createUnique(permissions);
    let childrenList = [];
    unique.forEach((permission) => {
      if (permission.includes("Lmd")) {
        childrenList.push(
          {
            path: `doctors`,
            element: <DoctorPage />,
          },
          {
            path: `doctors/:doctorId`,
            element: <DetailsPage />,
          }
        );
      }
      if (permission.includes("Facility")) {
        childrenList.push(
          {
            path: "facility",
            element: <FacilityPage />,
          },
          {
            path: `facility-detail/:facilityId`,
            element: <FacilityDetailPage />,
          }
        );
      }

      if (permission.includes("Partner")) {
        childrenList.push(
          {
            path: `partners`,
            element: <PartnerPage />,
          },
          {
            path: `partner-detail/:partnerId`,
            element: <PartnerDetailPage />,
          }
        );
      }
      if (permission.includes("Permission")) {
        childrenList.push({
          path: `admins`,
          element: <AdminUserPage />,
        });
      }
    });
console.log(permissions);

    activeRoutes = [
      {
        path: "/",
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="dashboard" />, index: true },
          { path: "dashboard", element: <DashboardAppPage /> },
          { path: "consultations", element: <ConsultationPage /> },
          { path: "transactions", element: <TransactionPage /> },
          { path: "patients", element: <PatientPage /> },
          { path: "patient-detail/:patientId", element: <PatientDetailPage /> },
          { path: "contract", element: <Contract /> },
          { path: "appointments", element: <Appointment /> },
          { path: "contract-detail", element: <ContractDetails /> },
          { path: "settings", element: <Settings /> },
          // { path: "sponsors", element: <SponsorPage /> },
          { path: "sponsors", element: <SponsorCustomTable /> },
          { path: "plan-detail/:planId", element: <PlanDetails /> },
          { path: "sponsor-detail/:sponsorId", element: <SponsorDetailPage /> },

          {
            path: "health-insurance-companies",
            element: <HealthInsuranceCompany />,
          },
          {
            path: "health-insurance-companies-details/:companyId",
            element: <HealthInsuranceDetailsPage />,
          },
          { path: "products", element: <ProductsPage /> },
          { path: "blog", element: <BlogPage /> },
          ...childrenList,
        ],
        errorElement: <div>Oops! There was an error.</div>,
      },
      {
        element: <SimpleLayout />,
        children: [{ path: "404", element: <Page404 /> }],
        errorElement: <div>Oops! There was an error. Refresh your page!</div>,
      },
      {
        path: "*",
        element: <Navigate to="/404" replace />,
      },
      { path: "/addcontract", element: <AddContract /> },
      { path: "/editcontract", element: <EditContract /> },
    ];
  } else {
    activeRoutes = [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "*",
        element: <LoginPage />,
      },
    ];
  }

  const routes = useRoutes(activeRoutes);
  return routes;
}
