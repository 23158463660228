import React from 'react';

import CustomisableModal from './customisableModal';
import Button from '@mui/material/Button';

// import './ErrorModal.css'


const ErrorModal = props => {
  return (
    <CustomisableModal
      handleClose={props.onClear}
      open={!!props.error}
    >
      <header className={`modal__header ${props.headerClass}`}>
        <h2
          // IMPORTANT:
          style={{ color: 'red' }}
        >
          An Error Occurred!
        </h2>
      </header>
      <p>{props.error}</p>
      <footer className={`modal__footer ${props.footerClass}`}>
        <Button
          variant="outlined"
          onClick={props.onClear}
          sx={{
            mt: '0.65rem',
            // IMPORTANT
            borderColor: '#d32f2f',
            color: '#d32f2f',
            '&:hover': {
              borderColor: 'inherit',
              color: 'inherit',
             },
          }}
        >Okay</Button>
      </footer>
    </CustomisableModal>
  );
};

export default ErrorModal;
