import { axiosClient, baseUrl } from "../utils/helper";

export const fetchUnApprovedPartners = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/admin/api/AdminAPI/GetPendingPartners`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
      },
      signal
    });
  // console.log('UnApprovedPartners', responseData?.data.data)
  return responseData?.data.data;
}

export const fetchApprovedPartners = async (token, signal) => {
  const responseData = await axiosClient.get(
    `${baseUrl}/admin/api/AdminAPI/GetApprovedPartners`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        // 'Ocp-Apim-Subscription-Key': 'ad907e9b3ab74347aafb8a12f8ac5d28'
      },
      signal
    }
  );
  // console.log('approvedPartners', responseData?.data.data)
  return responseData?.data.data;
}

export const updateApprovalRequest = (data) => {
  return axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/ApprovePartnersPending`,
    data.data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + data.token,
      },
    }
  )
}

export async function CreatePartner({
  formData,
  onSubmitProps,
  token
}) {
  const responseData = await axiosClient.post(
    `${baseUrl}/admin/api/AdminAPI/CreatePartner`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    }
  )
  // console.log('responseData', responseData)
  return responseData
}
