import { useCallback, useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./FacilityHelperFunctions";
import { getComparator } from "../../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApprovedPartners } from "../../../fetch/partner";
import {
  CreateFacility,
  fetchApprovedLabs,
  fetchApprovedPharmacies,
  fetchUnApprovedLabs,
  fetchUnApprovedPharmacies,
  updateFacilityApprovalRequest,
  fetchApprovedHospitals,
  fetchUnapprovedHospitals,
} from "../../../fetch/facility";
import { errorMessageConfig } from "../../../utils/errorMessageConfiguration";
import { toast } from "react-toastify";

function useFacility(auth) {
  const { token } = auth;
  const navigate = useNavigate();
  let location = useLocation();
  // console.log('location?.state?.id', location?.state?.id)
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const [success, setSuccess] = useState({});
  const [tabsValue, setTabsValue] = useState(0);
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const switchForm = (event) => setOpenCreatePage(true);
  const switchList = (event) => {
    setOpenCreatePage(false);
    if (!!location?.state?.id) {
      navigate(".", {
        state: {},
      });
    }
  };

  const [contactPhone, setContactPhone] = useState("");


  // const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoading } = useHttpClient();

  const pharmacylist = ["approvedPharmacies", "unApprovedPharmacies"];
  const lablist = ["approvedLabs", "unApprovedLabs"];
  const hospitalList = ["approvedHospitals", "unApprovedHospitals"];

  const clearError = () => {
    setError(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const {
    data: unApprovedPharmacies,
    isLoading: unApprovedPharmaciesLoading,
    error: unApprovedPharmaciesError,
    isError: unApprovedPharmaciesIsError,
  } = useQuery({
    queryKey: ["unApprovedPharmacies"],
    queryFn: ({ signal }) => fetchUnApprovedPharmacies(token, signal),
  });

  const {
    data: approvedPharmacies,
    isLoading: approvedPharmaciesLoading,
    error: approvedPharmaciesError,
    isError: approvedPharmaciesIsError,
  } = useQuery({
    queryKey: ["approvedPharmacies"],
    queryFn: ({ signal }) => fetchApprovedPharmacies(token, signal),
  });

  const {
    data: unApprovedLabs,
    isLoading: unApprovedLabsLoading,
    error: unApprovedLabsError,
    isError: unApprovedLabsIsError,
  } = useQuery({
    queryKey: ["unApprovedLabs"],
    queryFn: ({ signal }) => fetchUnApprovedLabs(token, signal),
  });

  const {
    data: approvedLabs,
    isLoading: approvedLabsLoading,
    error: approvedLabsError,
    isError: approvedLabsIsError,
  } = useQuery({
    queryKey: ["approvedLabs"],
    queryFn: ({ signal }) => fetchApprovedLabs(token, signal),
  });

  const {
    data: approvedHospitals,
    isLoading: approvedHospitalsLoading,
    error: approvedHospitalsError,
    isError: approvedHospitalsIsError,
  } = useQuery({
    queryKey: ["approvedHospitals"],
    queryFn: ({ signal }) => fetchApprovedHospitals(token, signal),
  });

  const {
    data: unApprovedHospitals,
    isLoading: unApprovedHospitalsLoading,
    error: unApprovedHospitalsError,
    isError: unApprovedHospitalsIsError,
  } = useQuery({
    queryKey: ["unApprovedHospitals"],
    queryFn: ({ signal }) => fetchUnapprovedHospitals(token, signal),
  });

  useEffect(() => {
    if (!!location?.state?.id) {
      setOpenCreatePage(true);
    }
  }, []);

  const facilityCreationMutation = useMutation(CreateFacility, {
    onSuccess: (data, inputs) => {
      inputs.onSubmitProps.setSubmitting(false);
      toast.success(data?.data?.message, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 2000,
      });
      setSuccess({
        value: data?.isSuccessful,
        message: data?.message,
      });
      inputs.onSubmitProps.resetForm();
      switchList();
      setTabsValue(inputs.facilityType === "pharmacy" ? 1 : 3);
      return queryClient.invalidateQueries([
        inputs.facilityType === "pharmacy"
          ? "unApprovedPharmacies"
          : "unApprovedLabs",
      ]);
    },
    onError: (error) => {
      console.log("error", error);
      // const message = errorMessageConfig(error);
      // setError(message);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const facilityCreationHandler = async (values, onSubmitProps) => {
    const {
      partnerId,
      facilityType,
      email,
      pharmacyName,
      pharmacyAddress,
      licenseNo,
      licenseUrl,
      pharmacyRebate,
      labName,
      labContactPhone,
      labEmail,
      labAddress,
      labLicenseNo,
      labLicenseUrl,
      labRebate,
      state,
      city,
      lga,
      country,
      labState,
      labCity,
      labLga,
      labCountry,
      facilityName,
      facilityAddress,
      rebate,
    } = values;
    values.contactPhone = contactPhone;

    // const data = {
    //   partnerId,
    //   facilityType,
    //   email: facilityType === 'pharmacy' ? email : '',
    //   pharmacyName: facilityType === 'pharmacy' ? pharmacyName : '',
    //   contactPhone: facilityType === 'pharmacy' ? contactPhone.toString() : '',
    //   pharmacyAddress: facilityType === 'pharmacy' ? pharmacyAddress : '',
    //   licenseNo: facilityType === 'pharmacy' ? licenseNo : '',
    //   licenseUrl: facilityType === 'pharmacy' ? licenseUrl : '',
    //   labName: facilityType === 'lab' ? labName : '',
    //   labContactPhone: facilityType === 'lab' ? labContactPhone.toString() : '',
    //   labEmail: facilityType === 'lab' ? labEmail : '',
    //   labAddress: facilityType === 'lab' ? labAddress : '',
    //   labLicenseNo: facilityType === 'lab' ? labLicenseNo : '',
    //   labLicenseUrl: facilityType === 'lab' ? labLicenseUrl : ''
    // }

    // console.log('data', data);

    function percentageStringToDecimal(percentStr) {
      const percent = parseFloat(percentStr);
      const decimal = percent / 100;
      return decimal;
    }

    const formData = new FormData();
    formData.append("PartnerId", partnerId.value);
    // formData.append('partnerId', partnerId.value);
    formData.append("FacilityType", facilityType);
    formData.append("Email", email);
    formData.append("FacilityName", facilityName);
    formData.append("ContactPhone", contactPhone.toString());
    formData.append("FacilityAddress", facilityAddress);
    formData.append("LicenseNo", licenseNo);
    formData.append("LicenceCertificate", licenseUrl);
    formData.append("Rebate", percentageStringToDecimal(rebate));
    formData.append("State", state?.value);
    formData.append("City", city);
    formData.append("Lga", lga?.value);
    formData.append("Country", country);
    // if (facilityType === "pharmacy") {
    //   formData.append("Email", email);
    //   formData.append("PharmacyName", pharmacyName);
    //   formData.append("ContactPhone", contactPhone.toString());
    //   formData.append("PharmacyAddress", pharmacyAddress);
    //   formData.append("LicenseNo", licenseNo);
    //   formData.append("LicenceCertificate", licenseUrl);
    //   formData.append("Rebate", percentageStringToDecimal(pharmacyRebate));
    //   formData.append("State", state?.value);
    //   formData.append("City", city);
    //   formData.append("Lga", lga?.value);
    //   formData.append("Country", country);
    // }
    // if (facilityType === "lab") {
    //   formData.append("LabName", labName);
    //   formData.append("LabContactPhone", labContactPhone.toString());
    //   formData.append("LabEmail", labEmail);
    //   formData.append("LabAddress", labAddress);
    //   formData.append("LabLicenseNo", labLicenseNo);
    //   formData.append("LicenceCertificate", labLicenseUrl);
    //   formData.append("Rebate", percentageStringToDecimal(labRebate));
    //   formData.append("State", labState?.value);
    //   formData.append("City", labCity);
    //   formData.append("Lga", labLga?.value);
    //   formData.append("Country", labCountry);
    // }

    facilityCreationMutation.mutate({
      formData,
      onSubmitProps,
      token,
      facilityType,
    });
  };

  const ApproveFacilityMutation = useMutation({
    mutationKey: "approveFacilityMutation",
    mutationFn: updateFacilityApprovalRequest,
    onSuccess: (approvedFacilityData, inputs) => {
      const responseData = approvedFacilityData?.data;
      handleCloseMenu(inputs?.tableId);
      setTabsValue(
        !inputs.data.facilityType ? 0 : inputs.data.facilityType === 1 ? 2 : 4
      );
      setSuccess({
        value: responseData?.isSuccessful,
        message: responseData?.message,
      });
      return queryClient.invalidateQueries({
        // queryKey: ['approvedPharmacies', 'unApprovedPharmacies', 'approvedLabs', 'unApprovedLabs']
        queryKey: !inputs.data.facilityType
          ? pharmacylist
          : inputs.data.facilityType === 1
          ? lablist
          : hospitalList,
      });
    },
    onError: (error) => {
      // console.log('error', error)
      // const message = errorMessageConfig(error);
      // setError(message);
      const message = error?.response?.data?.message || error?.message;
      setError(message);
    },
  });

  const approveFacilityRequestHandler = async (data, tableId) => {
    ApproveFacilityMutation.mutate({
      data,
      token,
      tableId,
    });
  };

  const {
    data: approvedPartners,
    isLoading: approvedLoading,
    error: approvedError,
    isError: approvedIsError,
  } = useQuery({
    queryKey: ["approvedPartners"],
    queryFn: ({ signal }) => fetchApprovedPartners(token, signal),
    select: useCallback((data) => {
      const partners = data.map((partner) => ({
        label: partner.businessName,
        value: partner.id,
      }));
      return partners;
    }, []),
  });

  const [open, setOpen] = useState({
    approvedPharmacy: null,
    unApprovedPharmacy: null,
    approvedLab: null,
    unApprovedLab: null,
    approvedHospitals: null,
    unApprovedHospitals: null,
  });

  const [page, setPage] = useState({
    approvedPharmacy: 0,
    unApprovedPharmacy: 0,
    approvedLab: 0,
    unApprovedLab: 0,
    approvedHospitals: 0,
    unApprovedHospitals: 0,
  });

  const [order, setOrder] = useState({
    approvedPharmacy: "asc",
    unApprovedPharmacy: "asc",
    approvedLab: "asc",
    unApprovedLab: "asc",
    approvedHospitals: "asc",
    unApprovedHospitals: "asc",
  });

  const [selected, setSelected] = useState({
    approvedPharmacy: [],
    unApprovedPharmacy: [],
    approvedLab: [],
    unApprovedLab: [],
    approvedHospitals: [],
    unApprovedHospitals: [],
  });

  const [orderBy, setOrderBy] = useState({
    approvedPharmacy: "pharmacyName",
    unApprovedPharmacy: "pharmacyName",
    approvedLab: "labName",
    unApprovedLab: "labName",
    approvedHospitals: "name",
    unApprovedHospitals: "name",
  });

  const [filterName, setFilterName] = useState({
    approvedPharmacy: "",
    unApprovedPharmacy: "",
    approvedLab: "",
    unApprovedLab: "",
    approvedHospitals: "",
    unApprovedHospitals: "",
  });

  const [filterRole, setFilterRole] = useState({
    approvedPharmacy: "",
    unApprovedPharmacy: "",
    approvedLab: "",
    unApprovedLab: "",
    approvedHospitals: "",
    unApprovedHospitals: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState({
    approvedPharmacy: 5,
    unApprovedPharmacy: 5,
    approvedLab: 5,
    unApprovedLab: 5,
    approvedHospitals: 5,
    unApprovedHospitals: 5,
  });

  const handleOpenMenu = (event, id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleCloseMenu = (id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleRequestSort = (event, property, id) => {
    const isAsc = orderBy[id] === property && order[id] === "asc";
    setOrder((prev) => ({
      ...prev,
      [id]: isAsc ? "desc" : "asc",
    }));
    setOrderBy((prev) => ({
      ...prev,
      [id]: property,
    }));
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected[id].indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected[id], [name]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected[id].slice(1));
    } else if (selectedIndex === selected[id].length - 1) {
      newSelected = newSelected.concat(selected[id].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected[id].slice(0, selectedIndex),
        selected[id].slice(selectedIndex + 1)
      );
    }
    setSelected((prev) => ({
      ...prev,
      [id]: newSelected,
    }));
  };

  const handleChangePage = (event, newPage, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setRowsPerPage((prev) => ({
      ...prev,
      [id]: parseInt(event.target.value, 10),
    }));
  };

  const handleFilterByName = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterName((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const handleFilterByRole = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterRole((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const emptyRowsApprovedPharmacies =
    page.approvedPharmacy > 0
      ? Math.max(
          0,
          (1 + page.approvedPharmacy) * rowsPerPage.approvedPharmacy -
            approvedPharmacies.length
        )
      : 0;
  const emptyRowsUnApprovedPharmacies =
    page.unApprovedPharmacy > 0
      ? Math.max(
          0,
          (1 + page.unApprovedPharmacy) * rowsPerPage.unApprovedPharmacy -
            unApprovedPharmacies.length
        )
      : 0;
  const emptyRowsApprovedLabs =
    page.approvedLab > 0
      ? Math.max(
          0,
          (1 + page.approvedLab) * rowsPerPage.approvedLab - approvedLabs.length
        )
      : 0;
  const emptyRowsUnApprovedLabs =
    page.unApprovedLab > 0
      ? Math.max(
          0,
          (1 + page.unApprovedLab) * rowsPerPage.unApprovedLab -
            unApprovedLabs.length
        )
      : 0;
  const emptyRowsApprovedHospitals =
    page.approvedHospitals > 0
      ? Math.max(
          0,
          (1 + page.approvedHospitals) * rowsPerPage.approvedHospitals -
            approvedHospitals.length
        )
      : 0;

  const emptyRowsUnApprovedHospitals =
    page.unApprovedHospitals > 0
      ? Math.max(
          0,
          (1 + page.unApprovedHospitals) * rowsPerPage.unApprovedHospitals -
            unApprovedHospitals.length
        )
      : 0;

  const filteredApprovedPharmacies = useMemo(
    () =>
      !!approvedPharmacies &&
      applySortFilter(
        approvedPharmacies,
        getComparator(order.approvedPharmacy, orderBy.approvedPharmacy),
        filterName.approvedPharmacy,
        filterRole.approvedPharmacy,
        "pharmacyName"
      ),
    [
      approvedPharmacies,
      order.approvedPharmacy,
      orderBy.approvedPharmacy,
      filterName.approvedPharmacy,
      filterRole.approvedPharmacy,
    ]
  );

  const filteredUnApprovedPharmacies = useMemo(
    () =>
      !!unApprovedPharmacies &&
      applySortFilter(
        unApprovedPharmacies,
        getComparator(order.unApprovedPharmacy, orderBy.unApprovedPharmacy),
        filterName.unApprovedPharmacy,
        filterRole.unApprovedPharmacy,
        "pharmacyName"
      ),
    [
      unApprovedPharmacies,
      order.unApprovedPharmacy,
      orderBy.unApprovedPharmacy,
      filterName.unApprovedPharmacy,
      filterRole.unApprovedPharmacy,
    ]
  );

  const filteredApprovedLabs = useMemo(
    () =>
      !!approvedLabs &&
      applySortFilter(
        approvedLabs,
        getComparator(order.approvedLab, orderBy.approvedLab),
        filterName.approvedLab,
        filterRole.approvedLab,
        "labName"
      ),
    [
      approvedLabs,
      order.approvedLab,
      orderBy.approvedLab,
      filterName.approvedLab,
      filterRole.approvedLab,
    ]
  );

  const filteredUnApprovedLabs = useMemo(
    () =>
      !!unApprovedLabs &&
      applySortFilter(
        unApprovedLabs,
        getComparator(order.unApprovedLab, orderBy.unApprovedLab),
        filterName.unApprovedLab,
        filterRole.unApprovedLab,
        "labName"
      ),
    [
      unApprovedLabs,
      order.unApprovedLab,
      orderBy.unApprovedLab,
      filterName.unApprovedLab,
      filterRole.unApprovedLab,
    ]
  );
  const filteredApprovedHospitals = useMemo(
    () =>
      !!approvedHospitals &&
      applySortFilter(
        approvedHospitals,
        getComparator(order.approvedHospitals, orderBy.approvedHospitals),
        filterName.approvedHospitals,
        filterRole.approvedHospitals,
        "name"
      ),
    [
      approvedHospitals,
      order.approvedHospitals,
      orderBy.approvedHospitals,
      filterName.approvedHospitals,
      filterRole.approvedHospitals,
    ]
  );
  const filteredUnapprovedHospitals = useMemo(
    () =>
      !!unApprovedHospitals &&
      applySortFilter(
        unApprovedHospitals,
        getComparator(order.unApprovedHospitals, orderBy.unApprovedHospitals),
        filterName.unApprovedHospitals,
        filterRole.unApprovedHospitals,
        "name"
      ),
    [
      unApprovedHospitals,
      order.unApprovedHospitals,
      orderBy.unApprovedHospitals,
      filterName.unApprovedHospitals,
      filterRole.unApprovedHospitals,
    ]
  );

  const approvedPharmaciesIsNotFound =
    !filteredApprovedPharmacies.length && !!filterName.approvedPharmacy;
  const unApprovedPharmaciesIsNotFound =
    !filteredUnApprovedPharmacies.length && !!filterName.unApprovedPharmacy;
  const approvedLabsIsNotFound =
    !filteredApprovedLabs.length && !!filterName.approvedLab;
  const unApprovedLabsIsNotFound =
    !filteredUnApprovedLabs.length && !!filterName.unApprovedLab;
  const approvedHospitalsIsNotFound =
    !filteredApprovedHospitals.length && !!filterName.approvedHospitals;
  const unApprovedHospitalsIsNotFound =
    !filteredUnapprovedHospitals.length && !!filterName.unApprovedHospitals;

  const handleSelectAllClick = (event, id, facilityName) => {
    let docList;
    if (id === "approvedPharmacy") {
      docList = approvedPharmacies;
    } else if (id === "unApprovedPharmacy") {
      docList = unApprovedPharmacies;
    } else if (id === "approvedHospitals") {
      docList = approvedHospitals;
    } else if (id === "unApprovedHospitals") {
      docList = unApprovedHospitals;
    } else if (id === "approvedLab") {
      docList = approvedLabs;
    } else {
      docList = unApprovedLabs;
    }
    if (event.target.checked) {
      const newSelecteds = docList.map((n) => n[facilityName]);
      setSelected((prev) => ({
        ...prev,
        [id]: newSelecteds,
      }));
      return;
    }
    setSelected({
      approvedPharmacy: [],
      unApprovedPharmacy: [],
      approvedLab: [],
      unApprovedLab: [],
      approvedHospitals: [],
      unApprovedHospitals: [],
    });
  };

  return {
    approvedPharmacies,
    unApprovedPharmacies,
    approvedLabs,
    unApprovedLabs,
    approvedHospitals,
    unApprovedHospitals,
    approvedHospitalsLoading,
    unApprovedHospitalsLoading,
    approvedHospitalsError,
    unApprovedHospitalsError,
    approvedHospitalsIsError,
    unApprovedHospitalsIsError,
    unApprovedPharmaciesLoading,
    unApprovedPharmaciesError,
    unApprovedPharmaciesIsError,
    approvedPharmaciesLoading,
    approvedPharmaciesError,
    approvedPharmaciesIsError,
    unApprovedLabsLoading,
    unApprovedLabsError,
    unApprovedLabsIsError,
    approvedLabsLoading,
    approvedLabsError,
    approvedLabsIsError,
    isLoading,
    error,
    clearError,
    open,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    approvedPharmaciesIsNotFound,
    unApprovedPharmaciesIsNotFound,
    approvedLabsIsNotFound,
    unApprovedLabsIsNotFound,
    approvedHospitalsIsNotFound,
    unApprovedHospitalsIsNotFound,
    handleSelectAllClick,
    emptyRowsApprovedPharmacies,
    emptyRowsUnApprovedPharmacies,
    emptyRowsApprovedLabs,
    emptyRowsUnApprovedLabs,
    emptyRowsApprovedHospitals,
    emptyRowsUnApprovedHospitals,
    filteredApprovedPharmacies,
    filteredUnApprovedPharmacies,
    filteredApprovedLabs,
    filteredUnApprovedLabs,
    filteredApprovedHospitals,
    filteredUnapprovedHospitals,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    facilityCreationHandler,
    success,
    handleSnackbarClose,
    openCreatePage,
    switchList,
    switchForm,
    approveFacilityRequestHandler,
    approvedPartners,
    ApproveFacilityLoading: ApproveFacilityMutation.isLoading,
    createFacilityLoading: facilityCreationMutation.isLoading,
    contactPhone,
    setContactPhone,
  };
}

export default useFacility;
