import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import App from '../App';

var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    // instrumentationKey: 'e6e53b51-4c6f-4628-99f7-506ca0ac9e57',
    instrumentationKey: '86fb6949-09cf-4112-94b7-bac586f40ea5',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

export default withAITracking(reactPlugin, App);