import axios from "axios";
// import { baseUrl } from "../utils/helper";

const baseUrl = process.env.REACT_APP_BASE_URL
export const reactAppSubscriptionKey = process.env.REACT_APP_Ocp_Apim_Subscription_Key;
const token = JSON.parse(localStorage.getItem("userData"))?.token;
const apiClient = axios.create({
  baseURL: baseUrl,
  headers: {
    // "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_Ocp_Apim_Subscription_Key,
  },
});

apiClient.interceptors.response.use(
  (response) => {
    console.log("intercepted, *****");
    return response;
  },
  (error) => {
    return error;
  }
);

export default apiClient;
