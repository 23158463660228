import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MyInput from '../../../components/commonElements/input/MyInput';
import MySelect from '../../../components/commonElements/select/MySelect';
import ResetButton from '../../../components/buttons/ResetButton';
import FormikSubmitButton from '../../../components/buttons/FormikSubmitButton';
import { Form, Formik } from 'formik';
import * as Yup from "yup";

function AdminForm({
  loadedPermissions,
  adminCreationHandler
}) {
  const INITIAL_FORM_STATE = {
    email: '',
    firstName: '',
    phoneNumber: '',
    permissions: []
  }

  const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email.')
      .required('Required'),
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(70, 'Too Long!')
      .required('Required'),
    phoneNumber: Yup.number()
      .integer()
      .typeError('Please enter a valid phone number')
      .required('Required'),
    permissions: Yup.array()
      .of(
        Yup
          .string("String is Required!")
          .required("Required")
      )
      .min(1, "At least One Permissions is Required!")
      .required("Required"),
  });

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 3 }}>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          Create Admin User
        </Typography>

        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={adminCreationHandler}
        >
          <Form>
            <Stack
              spacing={3}
              sx={{ pt: 3, pb: 8 }}
            >
              <MyInput
                id="firstName"
                name="firstName"
                type="text"
                label="First Name"
              />

              <MyInput
                id="email"
                type="email"
                name="email"
                label="Email address"
              />
              <MyInput
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                label="Phone Number"
              />
              <MySelect
                id="permissions"
                name="permissions"
                label="Permissions"
                options={loadedPermissions}
                multiple={true}
                defaultValue=""
              />
              <ResetButton />
              <FormikSubmitButton sx={{ mt: 8 }}>
                Create Admin
              </FormikSubmitButton>
            </Stack>
          </Form>
        </Formik>
      </Container>
    </Box>
  )
}

export default AdminForm