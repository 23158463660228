import { filter } from "lodash";

// ----------------------------------------------------------------------
export const TABLE_HEAD = [
  { id: "patientName", label: "Name", alignRight: false },
  { id: "cclId", label: "ID", alignRight: false },
  { id: "occupation", label: "Occupation", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phoneNumber", label: "PhoneNumber", alignRight: false },
  { id: "maritalStatus", label: "Marital Status", alignRight: false },
  { id: "" },
];

export const USER_TABLE_HEAD = [
  { id: "firstName", label: "Name", alignRight: false },
  { id: "emailAddress", label: "Email Address", alignRight: false },
  { id: "phoneNumber", label: "Phone No", alignRight: false },
  { id: "transactionId", label: "Transaction ID", alignRight: false },
  { id: "isCreated", label: "Creation Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function startsWithCC(str) {
  const comparisonStr = "cc";
  const lowerCaseStr = str.toLowerCase();
  const lowerCaseComparisonStr = comparisonStr.toLowerCase();
  return lowerCaseStr.startsWith(lowerCaseComparisonStr);
}

export function userApplySortFilter(list, comparator, query, category) {
  const stabilizedThis = list.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);
  if (query) {
    return filter(result, (_user) => {
      return (
        _user?.firstName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1

      );
    });
  }
  return result;
}

export function applySortFilter(normalisedArray, comparator, query, category) {
  const stabilizedThis = normalisedArray.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  const result = stabilizedThis.map((el) => el[0]);

  if ((category === "All" || !category) && startsWithCC(query)) {
    return filter(result, (_user) => {
      return _user?.cclId?.toLowerCase().indexOf(query?.toLowerCase()) !== -1;
    });
  }

  if ((category === "All" || !category) && query) {
    return filter(result, (_user) => {
      return (
        _user?.patientName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
      );
    });
  }

  if (category && !query) {
    if (category === "All") return result;
    return filter(
      result,
      (_user) =>
        _user?.occupation?.toLowerCase().indexOf(category.toLowerCase()) !== -1
    );
  }
  if (category !== "All" && query) {
    return filter(result, (_user) => {
      return (
        _user?.patientName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
        _user?.occupation?.toLowerCase().indexOf(category.toLowerCase()) !== -1
      );
    });
  }
  return result;
}
