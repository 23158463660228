import axios from "axios";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { reactAppSubscriptionKey } from "../http/common";


export const createUnique = (array) => {

  let childrenList = [];
  array.forEach(child => {
    if (child.includes('Lmd')) {
      childrenList.push('Lmd')
    }
    if (child.includes('Facility')) {
      childrenList.push('Facility')
    }
    if (child.includes('Partner')) {
      childrenList.push('Partner')
    }
    if (child.includes('Permission')) {
      childrenList.push('Permission')
    }
  });
  childrenList = [...new Set(childrenList)];
  return childrenList;
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            component='div'
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function descendingComparator(a, b, orderBy) {
  // if (b[orderBy] < a[orderBy]) {
  //   return -1;
  // }
  // if (b[orderBy] > a[orderBy]) {
  //   return 1;
  // }
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const baseUrl = process.env.REACT_APP_BASE_URL

export const axiosClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Ocp-Apim-Subscription-Key': reactAppSubscriptionKey
  }
});
