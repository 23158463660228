import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 46,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  marginTop: '1.0rem',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 240,
    marginTop: 0,
    marginLeft: '1rem',
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  userSpecializations: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  userSpecializations,
  filterName,
  onFilterName,
  filterRole,
  onFilterRole
}) {
  // console.log('userSpecializations', userSpecializations)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          // backgroundColor: 'wheat',
          // paddingBottom: '1.4rem',
        }}
      >
        <TextField
          id="doctor-select-role"
          select
          // component='div'
          label="Specialization"
          defaultValue="All"
          // helperText="Please select your currency"
          sx={{
            width: { xs: '100%', sm: '15rem' },
            '& fieldset': {
              borderWidth: `1px !important`,
              borderColor: `#dcdcdc !important`,
              // borderColor: `orange !important`,
            },
          }}
          value={filterRole}
          onChange={onFilterRole}
        >
          <MenuItem key={0} value='All'>
            All
          </MenuItem>
          {
            !!userSpecializations && userSpecializations.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.name}
              >
                {option.name}
              </MenuItem>
              // <MenuItem key={option + index + 1} value={option}>
              //   {option}
              // </MenuItem>
            ))
          }
        </TextField>
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search doctor..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: 'text.disabled',
                  width: 20, height: 20
                }}
              />
            </InputAdornment>
          }
        />
      </Box>
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
          // display: numSelected > 0 ? 'flex' : 'none'
        }}
      >

        {numSelected > 0 && (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        ) : (
          // <Tooltip title="Filter list">
          //   <IconButton>
          //     <Iconify icon="ic:round-filter-list" />
          //   </IconButton>
          // </Tooltip>
          null
        )}
      </StyledRoot>
    </>
  );
}
