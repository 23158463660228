import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
// import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { useLocation } from "react-router-dom";
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;
// const HEADER_MOBILE = 4;

// const HEADER_DESKTOP = 92;
const HEADER_DESKTOP = 62;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  // backgroundColor: 'green',
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  backgroundColor: "white",
  boxShadow: "0 2px 12px rgba(192, 192, 192, 0.25)",
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(1, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

const headersName = {
  dashboard: "",
  patients: "Patients",
  transactions: "Transactions",
  consultations: "Consultations",
  partners: "Partners",
  facility: "Facilities",
  doctors: "Doctor(LMD)",
  admins: "Admin Users",
  contract: "Contract",
  settings: "Settings",
  appointments: "Appointments",
  "contract-detail": "Contract details",
  "facility-detail": "Facility Details",
  "patient-detail": "Patient Details",
  "partner-detail": "Partner Details",
  sponsors: "Sponsors",
  "sponsor-detail":"Sponsor Details",
  "health-insurance-companies": "Health Insurance Companies",
  "health-insurance-companies-details": "Health Insurance Company Details",
};

function getFirstPart(str) {
  const parts = str.split("/");
  return parts[0];
}

export default function Header({ onOpenNav }) {
  let location = useLocation();

  let str = location?.pathname;
  str = str.substring(1).includes("/")
    ? getFirstPart(str.substring(1))
    : str.substring(1);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {/* <Searchbar /> */}
        {str !== "dashboard" && (
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "text.primary",
              textTransform: "Capitalize",
            }}
          >
            {headersName[str] || "Doctor’s Profile"}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
