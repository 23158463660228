import React, { useContext } from "react";
import useSponsor from "./SponsorHook";
import { AuthContext } from "../../../context/auth-context";
import {
  TablePagination,
} from "@mui/material";
import SponsorListToolbar from "./SponsorListToolBar";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

SponsorListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

const SponsorCustomTable = ({ numSelected, filterName, onFilterName }) => {
  const auth = useContext(AuthContext);
  const {
    id: tableId,
    sponsorList,
    page,
    selected,
    rowsPerPage,
    filteredSponsors,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
  } = useSponsor(auth);
  const empptyPage = [];
  const navigate = useNavigate();



  return (
    <div>
      <>
        <div className="text mt-10 mb-5 flex items-center">
          <SponsorListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(event) => handleFilterByName(event, tableId)}
          />
        </div>
        <table className="text w-full mt-3">
          <thead className="text w-full bg-[#FAFAFA] h-10 border-b">
            <tr className="text-[#4A4A4A] font-bold">
              <td className="text-start w-1/4 pl-2">Sponsor's Name</td>
              <td className="text-start">Date of Reg.</td>
              <td className="text-start">Phone</td>
              <td className="text-start">Email Address</td>
              <td className="text-start">Action</td>
            </tr>
          </thead>
          <tbody className="text-start text-[13px] text-gray-600 w-full">
            {sponsorList?.length === 0 &&
              empptyPage.map((value, index) => (
                <tr
                  className={`animate animate-pulse text w-full h-6 ${
                    (index + 1) % 2
                      ? "bg-white text-white"
                      : "bg-[#dddddd] text-[#dddddd]"
                  }  `}>
                  <td className="animate animate-pulse text pl-4 py-3">
                    {value}
                  </td>
                  <td className="animate animate-pulse text pl-4 py-3">
                    {value}
                  </td>
                  <td className="animate animate-pulse text ">{value}</td>
                  <td className="animate animate-pulse text ">{value}</td>
                  <td className="animate animate-pulse text">
                    <span className="animate animate-pulse text pl-1 pr-8 rounded-md py-1">
                      {value}
                    </span>
                  </td>
                  <td className="animate animate-pulse text flex items-center py-2.5 cursor-pointer">
                    <span className="animate animate-pulse text-red-500 text-xs font-normal hover:text-red-600 hover:scale-105">
                      {}
                    </span>
                  </td>
                </tr>
              ))}
            {!!filteredSponsors &&
              filteredSponsors
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((tab, index) => (
                  <>
                    <tr
                      className={`text ${
                        (index + 1) % 2 ? "bg-white" : "bg-[#FAFAFA]"
                      }`}>
                      <td className="text-[#444448]  pl-5 py-3">
                        {tab?.sponsorName}
                      </td>
                      <td className="text-[#444448]  pl-5 py-3">
                        {tab?.createdAt ? tab?.createdAt : "--------"}
                      </td>
                      <td className="text-[#444448]">
                        {tab?.phoneNumber ? tab?.phoneNumber : "--------"}
                      </td>
                      <td className="text-[#444448]">{tab?.email}</td>
                      <td className="text-[#444448]">
                        <button
                          onClick={() =>
                            navigate(`/sponsor-detail/${tab?.sponsorId}`, {
                              state: tab,
                            })
                          }
                          className="text-[#2CBCEF]">
                          View
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
          </tbody>
        </table>
        <div className="text bg-white w-full border-t">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSponsors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) =>
              handleChangePage(event, newPage, tableId)
            }
            onRowsPerPageChange={(event) =>
              handleChangeRowsPerPage(event, tableId)
            }
          />
        </div>
      </>
    </div>
  );
};

export default SponsorCustomTable;
