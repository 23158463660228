import { useEffect, useMemo, useState } from "react";

import { useHttpClient } from "../../../hooks/http-hook";
import { applySortFilter } from "./AppointmentHelperFunction";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { baseUrl, getComparator } from "../../../utils/helper";
import {
  fetchUnsuccessfulAppointments,
  fetchUnsuccessfulConsultations,
} from "../../../fetch/appointment";
import { toast } from "react-toastify";

const useAppointment = (auth) => {
  const { token } = auth;
  const [success, setSuccess] = useState({});
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const AppointmentPageSwitch = (event) => setOpenCreatePage((page) => !page);
  const [tabsValue, setTabsValue] = useState(0);

  const [appointments, setAppointments] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  //manual csv handling
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const consultationList = [
    "unsuccessfulConsultations",
    "unsuccessfulAppointments",
  ];

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess((prev) => ({
      value: false,
      message: "",
    }));
    if (event) {
      event.preventDefault();
    }
  };

  const handleTabsValueChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const {
    data: unsuccessfulConsultations,
    isLoading: unsuccessfulConsultationsLoading,
    error: unsuccessfulConsultationsError,
    isError: unsuccessfulConsultationsIsError,
  } = useQuery({
    queryKey: ["unsuccessfulConsultations"],
    queryFn: ({ signal }) => fetchUnsuccessfulConsultations(token, signal),
  });
  const {
    data: unsuccessfulAppointments,
    isLoading: unsuccessfulAppointmentsLoading,
    error: unsuccessfulAppointmentsError,
    isError: unsuccessfulAppointmentsIsError,
  } = useQuery({
    queryKey: ["unsuccessfulAppointments"],
    queryFn: ({ signal }) => fetchUnsuccessfulAppointments(token, signal),
  });

  const [open, setOpen] = useState({
    unsuccessfulAppointments: null,
    unsuccessfulConsultations: null,
  });

  const [openCsv, setOpenCsv] = useState(false);

  const [page, setPage] = useState({
    unsuccessfulAppointments: 0,
    unsuccessfulConsultations: 0,
  });

  const [order, setOrder] = useState({
    unsuccessfulAppointments: "asc",
    unsuccessfulConsultations: "asc",
  });

  const [selected, setSelected] = useState({
    unsuccessfulAppointments: [],
    unsuccessfulConsultations: [],
  });

  const [orderBy, setOrderBy] = useState({
    unsuccessfulAppointments: "patientName",
    unsuccessfulConsultations: "patientName",
  });

  const [filterName, setFilterName] = useState({
    unsuccessfulAppointments: "",
    unsuccessfulConsultations: "",
  });

  const [filterRole, setFilterRole] = useState({
    unsuccessfulAppointments: "",
    unsuccessfulConsultations: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState({
    unsuccessfulAppointments: 5,
    unsuccessfulConsultations: 5,
  });

  const handleOpenMenu = (event, id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleCloseMenu = (id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleRequestSort = (event, property, id) => {
    const isAsc = orderBy[id] === property && order[id] === "asc";
    setOrder((prev) => ({
      ...prev,
      [id]: isAsc ? "desc" : "asc",
    }));
    setOrderBy((prev) => ({
      ...prev,
      [id]: property,
    }));
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected[id].indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected[id], [name]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected[id].slice(1));
    } else if (selectedIndex === selected[id].length - 1) {
      newSelected = newSelected.concat(selected[id].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected[id].slice(0, selectedIndex),
        selected[id].slice(selectedIndex + 1)
      );
    }
    setSelected((prev) => ({
      ...prev,
      [id]: newSelected,
    }));
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     // const newSelecteds = consultationHistory.map((n) => n.patientName);
  //     const newSelecteds = appointments.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };
  const handleSelectAllClick = (event, id, facilityName) => {
    let docList;
    if (id === "unsuccessfulConsultations") {
      docList = unsuccessfulConsultations;
    } else if (id === "unsuccessfulAppointments") {
      docList = unsuccessfulAppointments;
    }
    if (event.target.checked) {
      const newSelecteds = docList.map((n) => n[facilityName]);
      setSelected((prev) => ({
        ...prev,
        [id]: newSelecteds,
      }));
      return;
    }
    setSelected({
      unsuccessfulAppointments: [],
      unsuccessfulConsultations: [],
    });
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  const handleChangePage = (event, newPage, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: newPage,
    }));
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setPage(0);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  // };
  const handleChangeRowsPerPage = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setRowsPerPage((prev) => ({
      ...prev,
      [id]: parseInt(event.target.value, 10),
    }));
  };

  // const handleFilterByName = (event) => {
  //   setPage(0);
  //   setFilterName(event.target.value);
  // };

  const handleFilterByName = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterName((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const emptyRowsUnsuccessfulAppointment =
    page > 0
      ? Math.max(
          0,
          (1 + page.unsuccessfulAppointments) *
            rowsPerPage.unsuccessfulAppointments -
            unsuccessfulAppointments.length
        )
      : 0;

  const emptyRowsUnsuccessfulConsultations =
    page > 0
      ? Math.max(
          0,
          (1 + page.unsuccessfulConsultations) *
            rowsPerPage.unsuccessfulConsultations -
            unsuccessfulConsultations.length
        )
      : 0;

  // const handleFilterByRole = (event, id) => {
  //   setPage(0);
  //   setFilterRole(event.target.value);
  // };

  const handleFilterByRole = (event, id) => {
    setPage((prev) => ({
      ...prev,
      [id]: 0,
    }));
    setFilterRole((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const filteredAppointments = useMemo(
    () =>
      !!unsuccessfulAppointments &&
      applySortFilter(
        unsuccessfulAppointments,
        getComparator(
          order.unsuccessfulAppointments,
          orderBy.unsuccessfulAppointments
        ),
        filterName.unsuccessfulAppointments,
        filterRole.unsuccessfulAppointments
      ),
    [
      unsuccessfulAppointments,
      order.unsuccessfulAppointments,
      orderBy.unsuccessfulAppointments,
      filterName.unsuccessfulAppointments,
      filterRole.unsuccessfulAppointments,
    ]
  );

  const filteredConsultations = useMemo(
    () =>
      !!unsuccessfulConsultations &&
      applySortFilter(
        unsuccessfulConsultations,
        getComparator(
          order.unsuccessfulConsultations,
          orderBy.unsuccessfulConsultations
        ),
        filterName.unsuccessfulConsultations,
        filterRole.unsuccessfulConsultations
      ),
    [
      unsuccessfulConsultations,
      order.unsuccessfulConsultations,
      orderBy.unsuccessfulConsultations,
      filterName.unsuccessfulConsultations,
      filterRole.unsuccessfulConsultations,
    ]
  );

  const appointmentsisNotFound =
    !filteredAppointments.length && !!filterName.unsuccessfulAppointments;

  const consultationsisNotFound =
    !filteredConsultations.length && !!filterName.unsuccessfulConsultations;

  // CSV
  const headers = [
    {
      label: "Patient",
      key: "patientName",
    },
    {
      label: "Doctor",
      key: "doctorName",
    },
    {
      label: "Patient Phone",
      key: "patientPhone",
    },
    {
      label: "Consultation Price",
      key: "price",
    },
    {
      label: "Appointment Date",
      key: "appointmentDate",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const csvLink = {
    filename: "appointments.csv",
    headers: headers,
    data: unsuccessfulAppointments,
  };

  const csvInfo =
    tabsValue === 0 ? unsuccessfulAppointments : unsuccessfulConsultations;

  const handleExportToCSV = () => {
    const arr = filterDataByDateRange(csvInfo, startDate, endDate);

    if (arr.length === 0) {
      toast.info("No data available for selected dates", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      ///show toast
      setStartDate("");
      setEndDate("");
      setOpenCsv(false);
      return;
    }

    const csvRows = [];
    // Add header row
    const headers = Object.keys(arr?.[0]);
    csvRows.push(headers.join(","));
    /////
    arr.forEach((row) => {
      const values = headers.map((header) => {
        let value = row[header];

        return value;
      });

      csvRows.push(values.join(","));
    });

    // Create a Blob containing the CSV data
    const csvData = csvRows.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    // Create a URL for the Blob and trigger a download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Appointment_Data.csv";
    a.click();
    window.URL.revokeObjectURL(url);

    setStartDate("");
    setEndDate("");
    setOpenCsv(false);
  };

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;

    if (type === "start") {
      setStartDate(selectedDate);
    } else if (type === "end") {
      setEndDate(selectedDate);
    }
  };

  const parseDate = (dateString) => {
    // Parse dates in the "dd/mm/yyyy" or "yyyy-mm-dd" format
    const parts = dateString.split(/[\/-]/);
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  const filterDataByDateRange = (data, startDate, endDate) => {
    // if (startDate && endDate) {
    //   const start = parseDate(startDate);
    //   const end = parseDate(endDate);

    //   const filtered = data.filter((item) => {
    //     const itemDate = parseDate(
    //       tabsValue === 0 ? item.appointmentDate : item.date
    //     );
    //     return itemDate >= start && itemDate <= end;
    //   });
    //   return filtered;
    // } else {
    //   return data;
    // }
    if (startDate && endDate) {
      const filtered = data.filter((item) => {
        const itemDate = new Date(
          tabsValue === 0 ? item.appointmentDate : item.date
        ); // Use the correct property name
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      });
      return filtered;
    } else {
      return data;
    }
  };

  return {
    unsuccessfulAppointments,
    unsuccessfulConsultations,
    unsuccessfulAppointmentsError,
    unsuccessfulAppointmentsIsError,
    unsuccessfulAppointmentsLoading,
    unsuccessfulConsultationsError,
    unsuccessfulConsultationsIsError,
    unsuccessfulConsultationsLoading,
    appointmentsisNotFound,
    consultationsisNotFound,
    emptyRowsUnsuccessfulAppointment,
    emptyRowsUnsuccessfulConsultations,
    filteredAppointments,
    filteredConsultations,

    isLoading,
    error,
    clearError,
    open,
    setOpen,
    page,
    order,
    selected,
    orderBy,
    filterName,
    filterRole,
    rowsPerPage,
    tabsValue,
    handleTabsValueChange,
    handleOpenMenu,
    handleCloseMenu,
    // isNotFound,
    handleSelectAllClick,
    // emptyRows,
    filteredAppointments,
    handleFilterByRole,
    handleFilterByName,
    handleChangeRowsPerPage,
    handleChangePage,
    handleClick,
    handleRequestSort,
    // consultationCreationHandler,
    success,
    handleSnackbarClose,
    openCreatePage,
    AppointmentPageSwitch,
    // approvalConsultationRequestHandler,
    // csv
    csvLink,

    //custom CSV
    startDate,
    endDate,
    handleDateChange,
    handleExportToCSV,
    openCsv,
    setOpenCsv,
  };
};

export default useAppointment;
