import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Iconify from "../../components/iconify";
import BackButton from "../../layouts/backButton";
import apiClient from "../../http/common";
import { useQuery } from "@tanstack/react-query";
import { BsImageFill } from "react-icons/bs";
import { Button } from "@mui/material";

const account = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: "/assets/images/avatars/avatar_default.jpg",
};

const checkFileTypeFromUrl = (url) => {
  try {
    // Extract the file extension from the URL
    const fileExtension = url.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      return "pdf";
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return "image";
    } else {
      return "others";
    }

    // Make a HEAD request to the URL
    // const response = await fetch(url, { method: "HEAD" });
    // const contentType = response.headers.get("Content-Type");

    // if (contentType === "application/pdf") {
    //   return "pdf";
    // } else if (contentType.startsWith("image/")) {
    //   return "image";
    // } else {
    //   return "others";
    // }
  } catch (error) {
    console.error(error);
  }
};

const ItemComponent = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        pb: "1rem",
        // backgroundColor: 'darkolivegreen',
      }}
    >
      <Typography
        sx={{
          width: "9.8rem",
          fontSize: "0.87rem",
          // backgroundColor: 'firebrick',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "auto",
          align: "left",
          fontWeight: "medium",
          fontSize: "0.87rem",
          // backgroundColor: 'cornsilk',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

function DetailsPage() {
  let location = useLocation();
  const params = useParams();
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const doctorId = params.doctorId;
  const details = location?.state;

  const { data } = useQuery({
    queryKey: ["doctor"],
    queryFn: () =>
      apiClient.get(`/Doctor/api/Doctors/?userId=${details?.userId}`),
  });

  // Doctor/api/Doctors/userId[?userId]

  useEffect(() => {
    if (!details) {
      navigate("/404");
    }
  }, [navigate, details]);

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          // padding: '1.6rem',
          mt: "1.5rem",
          flexWrap: "wrap",
          // width: '50%',
        }}
      >
        <Box
          sx={{
            // width: { xs: '100%', md: '54%' },
            width: { xs: "100%", md: "54%" },
            // backgroundColor: 'aqua'
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#343673",
            }}
          >
            Doctor’s Information
          </Typography>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "1.5rem",
              display: "flex",
              flexWrap: { xs: "wrap", sm: "no-wrap" },
              padding: "1.6rem",
              mr: "1.0rem",
              mt: "0.5rem",
              // width: '49%',
              // backgroundColor: 'aquamarine'
            }}
          >
            {/* <Box
              sx={{
                // backgroundColor: 'bisque'
                width: { xs: '100%', sm: '5.5rem' },
              }}
            > */}
            <Avatar
              src={details?.imageUrl || account.photoURL}
              alt={details?.firstName || "photoURL"}
              sx={{
                width: { xs: "7.5rem", sm: "5.5rem" },
                height: { xs: "7.5rem", sm: "5.5rem" },
                mr: { xs: "auto", sm: "1.0rem" },
                ml: { xs: "auto", sm: "0rem" },
                mb: { xs: "auto", lg: "0.5rem" },
              }}
            />
            {/* </Box> */}
            <Box
              sx={{
                // backgroundColor: 'bisque',
                mt: { xs: "1.5rem", sm: "0rem" },
              }}
            >
              <ItemComponent title={"ID:"} value={details?.id} />
              <ItemComponent title={"First Name:"} value={details?.firstName} />
              <ItemComponent title={"Last Name:"} value={details?.lastName} />
              <ItemComponent
                title={"Date of Birth:"}
                value={details?.dateOfBirth}
              />
              <ItemComponent title={"Sex:"} value={details?.gender} />
              <ItemComponent title={"Email:"} value={details?.email} />
              <ItemComponent
                title={"Phone Number:"}
                value={details?.phoneNumber}
              />
              <ItemComponent
                title={"Languages:"}
                value={details?.languages?.join(", ")}
              />
              <ItemComponent
                title={"Consultation Price:"}
                value={details?.consultationPrice}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "43%" },
          }}
        >
          <Box
            sx={{
              mt: { xs: "1.5rem", md: "0rem" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#343673",
              }}
            >
              Professional Details
            </Typography>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "1.5rem",
                padding: "1.6rem",
                mt: "0.5rem",
                // width: '49%',
              }}
            >
              <ItemComponent
                title={"MDCN Folio No:"}
                value={details?.mdcnFolioNo}
              />
              <ItemComponent
                title={"Specialization:"}
                value={details?.areaOfSpecialization}
              />
              <ItemComponent
                title={"Years of Experience:"}
                value={details?.yearsOfMedicalExperience}
              />
              <Box
                sx={{
                  display: "flex",
                  pb: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "9.8rem",
                    fontSize: "0.87rem",
                  }}
                >
                  MDCN Cert File
                </Typography>
                {checkFileTypeFromUrl(details?.annualCertificate) === "pdf" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        // backgroundColor: 'bisque'
                      }}
                    >
                      <img src="/assets/pdf.svg" alt={"MDCN Cert File"} />
                      <a
                        href={details?.annualCertificate}
                        download
                        style={{
                          fontSize: "0.87rem",
                          marginLeft: "1rem",
                        }}
                      >
                        MDCN Certificate
                      </a>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        // backgroundColor: 'bisque'
                      }}
                    >
                      <BsImageFill />
                      <a
                        href={details?.annualCertificate}
                        download
                        style={{
                          fontSize: "0.87rem",
                          marginLeft: "0.5rem",
                        }}
                      >
                        MDCN Certificate
                      </a>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  pb: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "9.8rem",
                    fontSize: "0.87rem",
                  }}
                >
                  Annual Certificate File
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    // backgroundColor: 'bisque'
                  }}
                >
                  {checkFileTypeFromUrl(details?.mdcnCert) ===
                  "pdf" ? (
                    <>
                      <img
                        src="/assets/pdf.svg"
                        alt={"Annual Certificate File"}
                      />
                      <a
                        href={details?.mdcnCert}
                        download
                        style={{
                          fontSize: "0.87rem",
                          marginLeft: "1rem",
                        }}
                      >
                        Annual Certificate
                      </a>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          // backgroundColor: 'bisque'
                        }}
                      >
                        <BsImageFill />
                        <a
                          href={details?.mdcnCert}
                          download
                          style={{
                            fontSize: "0.87rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Annual Certificate
                        </a>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>
          <Box
            sx={
              {
                // width: { xs: '100%', sm: '46%' },
                // backgroundColor: 'burlywood'
              }
            }
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#343673",
                mt: "1.5rem",
              }}
            >
              Address Information
            </Typography>
            {/* <Box
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '1.5rem',
                display: 'flex',
                padding: '1.6rem',
                mt: '0.5rem',
                // width: '49%',
              }}
            > */}
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "1.5rem",
                // display: 'flex',
                padding: "1.6rem",
                mt: "0.5rem",
                // width: '49%',
              }}
            >
              <ItemComponent title={"Address:"} value={details?.address} />
              <ItemComponent title={"City:"} value={details?.city} />
              <ItemComponent title={"State:"} value={details?.state} />
              <ItemComponent
                title={"LGA:"}
                value={details?.localGovernmentArea}
              />
              <ItemComponent
                title={"Country:"}
                value={details?.country || "Nigeria"}
              />
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // width: '54%',
          width: { xs: "100%", md: "52%" },
          mt: "1.5rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#343673",
          }}
        >
          Doctor’s Schedule
        </Typography>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "1.5rem",
            display: "flex",
            flexDirection: "column",
            padding: "1.6rem",
            mt: "0.5rem",
            // width: '49%',
          }}
        >
          <Box
            sx={{
              display: "flex",
              my: "0.3rem",
            }}
          >
            <Typography
              sx={{
                width: "7.0rem",
                fontSize: "0.87rem",
              }}
            >
              S/N
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              Start Date
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              End Date
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              Start Time
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              End Time
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              Interval
            </Typography>
            <Typography
              sx={{
                width: "15rem",
                fontSize: "0.87rem",
              }}
            >
              Schedule Type
            </Typography>
          </Box>
          {details?.doctorSchedule &&
            details?.doctorSchedule.map((row, index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    mt: "0.3rem",
                    mb: "0.6rem",
                  }}
                >
                  <Typography
                    sx={{
                      width: "7.0rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row.date}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row.endDate}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row.startTime.split("T").slice(1)}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row.endTime.split("T").slice(1)}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row.interval === 0
                      ? "Daily"
                      : row.interval === 1
                      ? "Weekdays"
                      : row.interval === 2
                      ? "Weekends"
                      : row.interval === 3
                      ? "Weekly"
                      : "Monthly"}
                  </Typography>
                  <Typography
                    sx={{
                      width: "15rem",
                      fontWeight: "medium",
                      fontSize: "0.87rem",
                    }}
                  >
                    {row._ScheduleType === 0 ? "Reoccuring" : "Fixed"}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          mt: { xs: "1.5rem", md: "1.5rem" },
          width: { xs: "100%", md: "52%" },
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#343673",
          }}
        >
          Career Information
        </Typography>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "1.5rem",
            padding: "1.6rem",
            mt: "0.5rem",
            // width: '49%',
          }}
        >
          <Typography
            sx={{
              width: "9.8rem",
              fontSize: "0.87rem",
              // backgroundColor: 'firebrick',
            }}
          >
            {details?.careerProfile}
          </Typography>
        </Box>
      </Box>
      {/* //// */}
      {/* <Box
        sx={{
          mt: { xs: "1.5rem", md: "1.5rem" },
          width: { xs: "100%" },
          display: "flex",
          justifyContent: "right",
          gap: "3rem",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: "red",
            color: "red",
            backgroundColor: "white",
            width: "170px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#fbd5d9",
              borderColor: "red",
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "all",
            },
          }}
        >
          Deactivate
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: "#2CBCEF",
            width: "170px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#80d6f5",
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
              transitionProperty: "all",
            },
          }}
        >
          Activate
        </Button>
      </Box> */}
    </Container>
  );
}

export default DetailsPage;
