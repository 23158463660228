import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

const InputField = ({
  label,
  type,
  options,
  id,
  name,
  value,
  handleChange,
  placeholder,
  boldLabel,
  register,
  required,
  accessor,
  title,
  asteriks,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handleFocus = () => setFocused(true);
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(document.activeElement)) {
      setFocused(false);
    }
  };

  function validateEmail(email) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  }

  const renderInput = () => {
    switch (type) {
      case "select":
        return (
          <select
            id={id}
            name={name}
            {...(register &&
              register(name, {
                required: {
                  value: required,
                  message: `${label} is required`,
                },
              }))}
            style={{
              width: "100%",
              padding: "8px 0",
              //   fontFamily: "sans-serif",
              fontSize: "14px",
              color: "#333",
              backgroundColor: "#fff",
              outline: "none",
              // border: "1px solid #c4c1c1",
              borderRadius: "8px",
            }}
          >
            <option value={""}>Select {title}</option>
            {options?.map((option, id) => (
              <option key={option[id]} value={option[id]}>
                {option[accessor]}
              </option>
            ))}
          </select>
        );
      case "radio":
      case "checkbox":
        return options?.map((option) => (
          <label key={option.value}>
            <input
              type={type}
              name={name}
              value={option.value}
              onChange={handleChange}
            />
            {option.label}
          </label>
        ));
      case "password":
        return (
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              id={id}
              name={name}
              value={value}
              onChange={handleChange}
              {...(register &&
                register(name, {
                  required: {
                    value: required,
                    message: `${label} is required`,
                  },
                }))}
              placeholder={placeholder}
              style={{
                width: "100%",
                padding: "8px 0",
                // fontFamily: "sans-serif",
                fontSize: "14px",
                backgroundColor: "#fff",
                borderRadius: "8px",
                outline: "none",
                // border: "1px solid #c4c1c1",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "8px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <img
                  src="../../public/assets/icons/Eyeclose.svg"
                  alt="password icon locked"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <AiOutlineEye color="#808080" size="24px" />
              )}
            </div>
          </div>
        );
      default:
        return (
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            onChange={handleChange}
            {...(register &&
              register(name, {
                required: {
                  value: required,
                  message: `${label} is required`,
                },
                validate: (fieldValue) => {
                  if (name === "email") {
                    return (
                      validateEmail(fieldValue) || "Enter a valid email address"
                    );
                  }
                },
              }))}
            placeholder={placeholder}
            style={{
              width: "100%",
              padding: "8px 4px",
              //   fontFamily: "sans-serif",
              fontSize: "14px",
              color: "#333",
              backgroundColor: "#fff",
              outline: "none",
              border: "none",
            }}
          />
        );
    }
  };

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 4px",
            margin: "5px 0",
            fontWeight: boldLabel ? "600" : "normal",
            fontSize: boldLabel ? "14px" : "12px",
            color: "#444448",
          }}
        >
          {label}
          {asteriks && (
            <span style={{ color: "red", marginLeft: "0.5px" }}>*</span>
          )}
        </label>
      )}
      <div
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={0}
        style={{
          marginBottom: "16px",
          width: "100%",
          height: "50px",
          border: "1px solid",
          backgroundColor: "#fff",
          borderRadius: "8px",
          borderColor: isFocused ? "#2CBCEF" : "#c4c1c1",
          padding: "4px",
          outline: "none",
        }}
      >
        {renderInput()}
      </div>
    </>
  );
};

export default InputField;
